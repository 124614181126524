import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { gettingOrderlist } from "../Actions/OrderListAction/OrderListAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ShowDetailModal = ({open,handleClose,order}) => {
  
  // const dispatch = useDispatch();
  // const [page/* , setPage */] = useState("1");
  // const [limit/* , setLimit */] = useState("10");
  // const productData = useSelector((state) => state.customerRedcuer);

  // let { moreDetail: { orderItem = [] } = {} } = productData;
  // console.log({ orderItem });
  // const orderItem = order.orderItem;
  const [orderDetail, setOrderDetail] = useState([]);

  console.log(orderDetail);

  useEffect(() => {
    if(order){
      setOrderDetail(order.orderItem);
    }
    
  }, [order])
  

  // useEffect(async () => {
  //   await dispatch(gettingOrderlist(page, limit));
  // }, []);
  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {/* <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
          </Box> */}
          <Box sx={style}>
            <table className="table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Detail</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {orderDetail && orderDetail.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <span className="ch-table-img">
                        <img
                          src={item.infoProduct.image}
                          alt="whatch-large"
                        ></img>
                      </span>
                    </td>
                    <td>{item.infoProduct.name}</td>
                    <td>{item.infoProduct.description}</td>
                    <td>
                      {item.infoProduct.amount}
                      {item.infoProduct.currency}
                    </td>

                  </tr>
                ))}
                {/* <tr>
                  <td>
                    <span className="ch-tables-img">
                      <img
                        src="images/img_2.png"
                        alt="whatch-large"
                        // style={{ width: "70px", height: "70px" }}
                      ></img>
                    </span>
                  </td>
                  <td>Awesome Headphone</td>
                  <td>randomised words even slightly believable</td>
                  <td>$16.00</td>
                  <td className="completed-text">Completed</td>
                  <td>
                    <button className="edit-history">
                      <i className="fa fa-edit"></i>
                    </button>
                    <button className="edit-history ml-3">
                      <i className="fa fa-trash"></i>
                    </button>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ShowDetailModal;
