export const customerRedcuer = (state = {}, action) => {
  switch (action.type) {
    //------------ Upload Celebrity Id --------------//
    case "UPLOAD_CELEBRITY_ID_INIT":
      return { loading: true };
    case "UPLOAD_CELEBRITY_ID_SUCCESS":
      return { loading: false, celebrity: action.payload };
    case "UPLOAD_CELEBRITY_ID_FAIL":
      return { loading: false, error: action.payload };

    //-------------- Get Customer Setting Celebrity --------------//
    case "GET_CUSTOMER_SETTING_INIT":
      return { loading: true };
    case "GET_CUSTOMER_SETTING_SUCCESS":
      return { loading: false, getCustomer: action.payload };
    case "GET_CUSTOMER_SETTING_FAIL":
      return { loading: false, error: action.payload };

    //-------------- Update Customer Setting Celebrity --------------//
    case "UPDATE_CUSTOMER_SETTING_INIT":
      return { loading: true };
    case "UPDATE_CUSTOMER_SETTING_SUCCESS":
      return { loading: false, updateCustomer: action.payload };
    case "UPDATE_CUSTOMER_SETTING_FAIL":
      return { loading: false, error: action.payload };

    //------------ Update Customer Display Setting --------------//
    case "UPDATE_CUSTOMER_DISPLAY_SETTING_INIT":
      return { loading: true };
    case "UPDATE_CUSTOMER_DISPLAY_SETTING_SUCCESS":
      return { loading: false, uploadCelebrity: action.payload };
    case "UPDATE_CUSTOMER_DISPLAY_SETTING_FAIL":
      return { loading: false, error: action.payload };

    //--------- MOre Detail of product --------------//
    case "GET_MORE_DETAIL_ORDER_LIST_INIT":
      return { loading: true };
    case "GET_MORE_DETAIL_ORDER_LIST_SUCCESS":
      return { loading: false, moreDetail: action.payload };
    case "GET_MORE_DETAIL_ORDER_LIST_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
