import React/* , { useEffect, useState } */ from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutBilling from "./CheckoutBilling";
// import { useDispatch, useSelector } from "react-redux";
// import { ProductDetails } from "../../Actions/Products/ProductsAction";

// const stripePromise = loadStripe('pk_test_51HSsW6EfBIdXK7zjZWtgTBxFdNPEFBGjLuEyDzXOV0ioU2ioEroyct2vkAbTvLjJhiSfgCLrc374gjqp1xEO7yCq00bq3GftdJ');

export default function PaymentElementStripe({ orderId, s }) {
  const stripePromise = loadStripe("pk_test_kpwEkjtOqM51siUmckR1tzLw");

  const options = {
    clientSecret: s ? s : ""
  };
  

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutBilling orderId={orderId} />
      </Elements>
    </>
  );
}
