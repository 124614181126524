import React, { useState, useEffect } from "react";
export default function ProductDetailTabs({ data }) {
  const [dataAttr, setdataAttr] = useState([]);
  // const [startAverage, setStartAverage] = useState("one");

  useEffect(() => {
    if (data && data.attributes) {
      let dataFinalAtt = [];
      for (const [clave, valor] of Object.entries(data.attributes)) {
        dataFinalAtt.push({
          key: clave,
          description: valor,
        });
      }
      setdataAttr(dataFinalAtt);
    }
    if (data && data.review && data.review.analiticReview) {
      switchValueStart(Math.round(data.review.analiticReview.averageScore));
    }
  }, [data]);

  console.log(data);

  const switchValueStart = (value) => {
    switch (value) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      case 4:
        return "four";
      case 5:
        return "five";
    }
  };

  return (
    <div>
      <section className="detail-tabs-main">
        <div>
          {/* <!-- Nav tabs --> */}
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#home">
                description
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#menu1">
                technical specs
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#menu2">
                reviews
              </a>
            </li>
          </ul>
        </div>
        <div className="detail-tabs-content">
          <div className="detail-page-container">
            {/* <!-- Tab panes --> */}
            <div className="tab-content">
              <div id="home" className="tab-pane active">
                <div className="technical-speces-dec">
                  {data && data.longDescription ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: data.longDescription }}
                    ></div>
                  ) : (
                    ""
                  )}

                  {/* <div className='row'>
                                    <div className='col-md-6'>
                                        <h2 className='technical-spec-hed'>Nuqqam Et Massa</h2>
                                        <p className='technical-spec-para'>Sed sodales sed orci molestie tristique. Nunc dictum, erat id molestie vestibulum, ex leo
                                            vestibulum justo, luctus tempor erat sem quis diam. Lorem ipsum dolor sit amet.</p>
                                        <h2 className='technical-spec-hed'>Wireless</h2>
                                        <p className='technical-spec-para'>Contrary to popular belief. Lorem Ipsum is not simply random text. It has roots in a piece
                                            of classical Latin literature from 45 BC. making it over 2000 years old.</p>
                                        <h2 className='technical-spec-hed'>Fresh Design</h2>
                                        <p className='technical-spec-para'>It is a long established fact that a reader will be distracted by the readable content of
                                            a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                                            more-or-less normal distribution of letters, as opposed to using</p>
                                        <h2 className='technical-spec-hed'>Fabolous Sound</h2>
                                        <p className='technical-spec-para'>There are many variations of passages of Lorem Ipsum available, but the
                                            majority have suffered alteration in some form, by injected humour, or
                                            randomised words which don't look even slightly believable.</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <span className="desc-watch">
                                            <img src='images/technical-tabs-img.png' className='img-fluid' alt='techinal'></img>
                                        </span>
                                        <h2 className='technical-spec-hed watch-bottom-text'>Nuqqam Et Massa</h2>
                                        <p className='technical-spec-para text-right-para'>
                                            Sed sodales sed orci molestie tristique. Nunc dictum, erat id molestie<br />
                                            vestibulum, ex leo vestibulum justo, luctus tempor<br />
                                            erat sem quis diam. Lorem ipsum dolor sit amet.
                                        </p>

                                    </div>
                                </div> */}
                  {/* 2nd watch classes start */}
                  {/* <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='difer-color'>
                                            <h5>Different Colors</h5>
                                            <p>Sed sodales sed orci
                                                molestie</p>
                                        </div>
                                        <div className='colorr-watch'>
                                            <span><img src='images/color-watch.png' alt='watch' className='img-fluid'></img></span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='color-watch2'>
                                            <span><img src='images/color-watch2.png' alt='watch' className='img-fluid'></img></span>
                                        </div>
                                        <div className='left-position'>
                                            <h2 className='technical-spec-hed watch-bottom-text'>Nuqqam Et Massa</h2>
                                            <p className='technical-spec-para text-right-para'>
                                                Sed sodales sed orci molestie tristique. Nunc dictum, erat id molestie<br />
                                                vestibulum, ex leo vestibulum justo, luctus tempor<br />
                                                erat sem quis diam. Lorem ipsum dolor sit amet.
                                            </p>
                                        </div>
                                    </div>
                                </div> */}
                </div>
              </div>
              <div id="menu1" className="tab-pane fade">
                <h2 className="technical-spec-hed tech-speces-hed">
                  {data && data.name ? data.name : ""}
                </h2>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      {dataAttr.length && dataAttr ? (
                        <div>
                          {dataAttr.map((row, i) => {
                            return (
                              <>
                                <tr key={row.key + i}>
                                  <td>{row.key}</td>
                                  <td>{row.description}</td>
                                </tr>
                              </>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* <tr>
                        <td>Meterial</td>
                        <td>Stainless Stee</td>
                      </tr>
                      <tr>
                        <td>Case</td>
                        <td>40g</td>
                      </tr>
                      <tr>
                        <td>Color</td>
                        <td>
                          blue, gray, green, light blue, lime, purple, red,
                          yellow
                        </td>
                      </tr>
                      <tr>
                        <td>Depth</td>
                        <td>10.5mm</td>
                      </tr>
                      <tr>
                        <td>Width</td>
                        <td>33.3mm</td>
                      </tr>
                      <tr>
                        <td>Size</td>
                        <td>Large, Medium, Small</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>

              <div id="menu2" className=" tab-pane fade">
                <section className="reviews-section">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="rating">
                        <div className="title">Based on 3 reviews</div>
                        <div className="score">
                          <div className="average-score">
                            <p className="numb">
                              {data && data.review && data.review.analiticReview
                                ? Math.round(
                                    data.review.analiticReview.averageScore
                                  )
                                : "0"}
                            </p>
                            <p className="text">Average score</p>
                          </div>
                          <div className="queue">
                            <ul className="queue-box">
                              <li
                                className={`${switchValueStart(
                                  Math.round(
                                    data &&
                                      data.review &&
                                      data.review.analiticReview &&
                                      data.review.analiticReview.averageScore
                                      ? data.review.analiticReview.averageScore
                                      : 1
                                  )
                                )}-star`}
                              >
                                <span>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </li>
                            </ul>
                          </div>

                          {/* <div className="queue">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                          </div> */}
                        </div>
                        <ul className="queue-box">
                          <li className="five-star">
                            <span>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </span>
                            <span className="numb-star">
                              {data &&
                              data.review &&
                              data.review.analiticReview &&
                              data.review.analiticReview.fiveStart
                                ? data.review.analiticReview.fiveStart
                                : "0"}
                            </span>
                          </li>
                          <li className="four-star">
                            <span>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </span>
                            <span className="numb-star">
                              {data &&
                              data.review &&
                              data.review.analiticReview &&
                              data.review.analiticReview.fourStart
                                ? data.review.analiticReview.fourStart
                                : "0"}
                            </span>
                          </li>
                          <li className="three-star">
                            <span>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </span>
                            <span className="numb-star">
                              {data &&
                              data.review &&
                              data.review.analiticReview &&
                              data.review.analiticReview.threeStart
                                ? data.review.analiticReview.threeStart
                                : "0"}
                            </span>
                          </li>
                          <li className="two-star">
                            <span>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </span>
                            <span className="numb-star">
                              {data &&
                              data.review &&
                              data.review.analiticReview &&
                              data.review.analiticReview.twoStart
                                ? data.review.analiticReview.twoStart
                                : "0"}
                            </span>
                          </li>
                          <li className="one-star">
                            <span>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </span>
                            <span className="numb-star">
                              {data &&
                              data.review &&
                              data.review.analiticReview &&
                              data.review.analiticReview.oneStart
                                ? data.review.analiticReview.oneStart
                                : "0"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <div className="form-review style1">
                        <div className="title">Add a review</div>
                        <div className="your-rating queue">
                          <span>Your Rating</span>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </div>

                        <form>
                          <div className="review-form-email">
                            <input type="text" placeholder="Name"></input>
                          </div>
                          <div className="review-form-email">
                            <input type="email" placeholder="Email" />
                          </div>
                          <div className="review-form-comment">
                            <textarea
                              name="review-text"
                              placeholder="Your Name"
                            ></textarea>
                          </div>
                          <div className="btn-submit">
                            <button type="submit" className="btn">
                              Add Review
                            </button>
                          </div>
                        </form>
                      </div> */}
                    </div>
                    <div className="col-md-12">
                      <ul className="review-list">
                        {data && data.review && data.review.data
                          ? data.review.data.map((review, i) => {
                              return (
                                <li key={review.id}>
                                  <div className="review-metadata">
                                    <div className="name">
                                      {review.name} : <span>April 3, 2016</span>
                                    </div>
                                    <div className="queue">
                                      <div className="rating">
                                        <ul className="queue-box">
                                          <li
                                            className={`${switchValueStart(
                                              review && review.ranking
                                                ? review.ranking
                                                : 1
                                            )}-star`}
                                          >
                                            <span>
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              ></i>
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              ></i>
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              ></i>
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              ></i>
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="review-content">
                                    <p>{review.message}</p>
                                  </div>
                                </li>
                              );
                            })
                          : null}

                        {/* <li>
                          <div className="review-metadata">
                            <div className="name">
                              Peter Tufan : <span>April 3, 2016</span>
                            </div>
                            <div className="queue">
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                          </div>
                          <div className="review-content">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="review-metadata">
                            <div className="name">
                              Jon Tufan : <span>April 3, 2016</span>
                            </div>
                            <div className="queue">
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                          </div>
                          <div className="review-content">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged.
                            </p>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
