import React,{useState, useEffect} from 'react';
import VenderSidebar from '../../components/Dashboards/Elements/VenderSidebar/VenderSidebar';
import VenderDbContent from '../../components/Dashboards/Vender/VenderDbContent/VenderDbContent';

import { verifyTokenUser } from '../../utils/Auth';
import { useNavigate } from 'react-router-dom';

export default function VenderDashboard() {

  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  
  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])

  if(loadingSesion){
    return <div className="se-pre-con"></div>
  }
  

  return (<div>
        {/* {loadingSesion ? <div className="se-pre-con"></div> : ""} */}
       <VenderDbContent/>
       <VenderSidebar/>
  </div>)
}
