import axios from "axios";

export const uploadFile = (file) => async (dispatch) => {
  dispatch({
    type: "UPLOAD_FILE_INIT",
  });
  try {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    var formData = new FormData();
    formData.append("files", file);
    const data = await axios.post(
      "https://fangway.com/users/misc/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    dispatch({
      type: "UPLOAD_FILE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "UPLOAD_FILE_FAIL",
      payload: error,
    });
  }
};
