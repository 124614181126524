import axios from "axios";
import { Auth } from "../../utils/Auth";

const url = true ? 'https://fangway.com' : 'http://localhost:3002';

export const ProductDetails = (slug) => async (dispatch) => {
  dispatch({
    type: "PRODUCT_DATA_FETCHING",
  });
  try {
    // console.log(authHeader);
    const { data } = await axios.get(
      `${url}/products/celebrity/${slug}`,
      { headers: Auth() }
    );
    console.log(data);
    dispatch({
      type: "PRODUCT_DATA_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "PRODUCT_DATA_FAIL",
      payload: error,
    });
  }
};

export const RecentProduct = () => async (dispatch) => {
  dispatch({
    type: "PRODUCT_RECENT",
  });
  try {
    const { data } = await axios.get(
      `${url}/products/celebrity/recent/products`
    );
    console.log(data);
    dispatch({
      type: "PRODUCT_RECENT_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "PRODUCT_RECENT_FAIL",
      payload: error,
    });
  }
}

export const getCart = () => async (dispatch) => {
  
  dispatch({
    type: "CART_GET",
  });
  try {
    const storage = localStorage.getItem('carUser');
    const cart = storage !== null ? JSON.parse(storage) : [];
    dispatch({
      type: "CART_SUCCESS",
      payload: cart,
    });
  } catch (error) {
    dispatch({
      type: "CART_FAIL",
      payload: error,
    });
  }
}

export const ProductDetailsCount = (slug,callback) => async () => {

  try {
    const data = await axios.get(
      `${url}/products/celebrity/${slug}`,
      { headers: Auth() }
    );
    console.log("status code",data.data.statusCode)
    // console.log("datos",data.data.data)
    callback(data.data.data)

  } catch (error) {

  }
};

export const CreateOrder = (type,callback) => async (dispatch) => {
  try {
    const cart = JSON.parse(localStorage.getItem("order"));
    const user = JSON.parse(localStorage.getItem("userInfo"))
    if(cart === null && user === null && !user.profile.celebrityId) return null;

    localStorage.setItem("orderType",type);

    const finalArray = [];

    //Recorrer cart
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      finalArray.push({
        productId: element.product.id,
        promotionId: element.product.promotion ? element.product.promotion.id : "",
        vendorId: element.product.authVendorId,
        amount: element.product.amount,
        discountRate: type === "One" ? element.product.discount : element.product.newDiscount,
        quantity: element.amount
      })
    }

    const order = {
      celebrityId: user.profile.celebrityId,
      orderItem: finalArray
    }
    console.log("order",order)
    dispatch({
      type: "CREATE_ORDER_GET"
    })
    const { data } = await axios.post(
      `${url}/ecommerce/order/create`,
      order,
      { headers: Auth() }
    );
      
    const secretKey = await axios.get(
      `${url}/ecommerce/payment/order/${data.data.idOrder}/secret`,
      { headers: Auth() }
    );    
    console.log("secretKey",secretKey)
    dispatch({
      type: "CREATE_ORDER_SUCCESS",
      payload: secretKey.data.data.client_secret    
    })

    callback(data,secretKey.data.data.client_secret , false);

  } catch (error) {
    console.log(error.message)
    callback(null,false, { error: error.message, err: true })
    dispatch({
      type: "CREATE_ORDER_FAIL",
      payload: error,
    });
  }

}

export const UpdateOrderBulling = (info,orderId) => async (dispatch) => {
  try {
    // dispatch({
    //   type: "CREATE_ORDER_GET"
    // })
    await axios.put(
      `${url}/ecommerce/order/${orderId}`,
      info,
      { headers: Auth() }
    );
    // console.log("Update Info Order", data);


  } catch (error) {
    
  }
}

export const AggregateNewDiscount = async (product,amount) => {
  const car = localStorage.getItem("carUser");
  let discount = 0;
  // console.log(car);
  if (car !== null) {
    const carSto = JSON.parse(car);
    let discountMedia = 0;
    let fonund = false;

    for (let i = 0; i < carSto.length; i++) {
      const element = carSto[i];
      if (element.product.id === product.id) {
        fonund = true;
        element.amount += amount;
      }
      discountMedia += element.product.discount + (carSto.length + 1) * 2;
    }

    if(!fonund){
      discountMedia += product.discount + (carSto.length + 1) * 2;
      carSto.push(
        {
          product: product,
          amount: amount,
        }
      )
    }
    // const carSto2 = JSON.parse(localStorage.getItem("carUser"));
    discount = discountMedia / carSto.length;
    
    for (let i = 0; i < carSto.length; i++) {
      const element = carSto[i];
      element.product.newDiscount = discount > 65 ? 65 : discount;
    }
    localStorage.setItem("carUser", JSON.stringify(carSto));
  } else {
    product.newDiscount = product.discount + 2;
    localStorage.setItem(
        "carUser",
        JSON.stringify([
          {
            product: product,
            amount: amount,
          },
        ])
    );
  }

  return discount;
}

export const AgregateStogareDiscount = (discountFinal) => async (dispatch) => {
  try {
    dispatch({
      type: "AGGREGATE_DISCOUNT_ORDER_SUCCESS",
      payload: discountFinal
    })
  } catch (error) {
    dispatch({
      type: "AGGREGATE_DISCOUNT_ORDER_FAIL",
      payload: error
    })
  }
}