import React, { useEffect/* , useState */ } from "react";
import SignupLink from "../../components/SignupLink/SignupLink";
import SuperSale from "../../components/SuperSale/SuperSale";
import Footer from "../../components/Footer/Footer";
import Deals from "../../components/Deals/Deals";
import Products from "../../components/Products/Products";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { homePage } from "../../Actions/Users/HomePageAction";


// import Pagination from '../../components/Pagination/Pagination';

export default function Home() {
  const data = useSelector((state) => state.homePage);

  // localStorage.setItem(
  //   "userInfo",
  //   JSON.stringify(require('../../utils/user-token.json'))
  // );

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(homePage());
  }, []);


  console.log(data)

  return (
    <div>
      <Header />
      <SuperSale />
      <Deals data={data.state && data.state.deals} />
      <Products
        featured={data.state && data.state.featured}
        desc={data.state && data.state.recommended.description}
        slug={data.state && data.state.recommended.slug}
        title={data.state && data.state.recommended.title}
        data={data.state && data.state.recommended.data}
      />
      <SignupLink />
      <Footer />
    </div>
  );
}
