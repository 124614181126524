import React, { useEffect, useState } from "react";
// import logo from './logo.svg';
// import "./App.css";
import Home from "../pages/home/Home";
import ProductDetail from "../pages/ProductDetail/ProductDetail";
// import Header from "./components/Header/Header";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Navigate,
  // useNavigate,
} from "react-router-dom";
// import PaymentSuccessful from "../pages/payment/PaymentSuccessful";
import Checkout from "../pages/checkout/Checkout";
// import Product from "../pages/product/Product";
import SignIn from "../pages/signIn/SignIn";
// import Payment from "../pages/payment/Payment";
// import Account from "../pages/account/Account";
import Cart from "../pages/Cart/Cart";
import OrderTracking from "../pages/OrderTracking/OrderTracking";
import CelebritySelecting from "../pages/CelebritySelecting/CelebritySelecting";

import ForgetPassowrd from "../pages/signIn/ForgetPassword";
import ResetPassword from "../pages/signIn/ResetPassword";

// import EmailDesing from "../pages/Email/EmailDesing";
import ThankYou from "../pages/ThankYou/ThankYou";
import Dashboard from "../pages/Dashboard/Dashboard";
// import EmailTemplateReset from "../pages/signIn/EmailTemplateReset";
import FaqPage from "../pages/faqPage/FaqPage";

// import SelectCelebrity from "./pages/account/SelectCelebrity";Already comment

import DashboardLayout from "../components/LayoutAdmins/DashboardLayout";
import CelebrityMyEarning from "../pages/Celebrity/CelebrityMyEarning/CelebrityMyEarning";
// import AddCouponVendor from "../pagesVendor/coupon/AddCoupon";
// import TrafficVendor from "../pagesVendor/traffic/Traffic";
// import SettingsVendor from "../pagesVendor/settings/Settings";

// import EarningCelebrity from "../pagesCelebrity/earning/Earning";
import AudiencesCelebrity from "../pagesCelebrity/audiences/Audiences";
import SettingsCelebrity from "../pagesCelebrity/celebritySettings/CelebritySettings";
import VenderDashboard from "../pages/Dashboard/VenderDashboard";
// import Vender from "./pages/Vender/Vender";
import CustomerDashboard from "../pages/Dashboard/Customer/CustomerDashboard";
import CustomerPaymentHistory from "../pages/Dashboard/Customer/CustomerPaymentHistory";
import CustomerOrderHistory from "../pages/Dashboard/Customer/CustomerOrderHistory";
import CustomerSearchHistory from "../pages/Dashboard/Customer/CustomerSearchHistory";
import CustomerFavoriteProduct from "../pages/Dashboard/Customer/CustomerFavoriteProduct";
import CustomerSettings from "../pages/Dashboard/Customer/CustomerSettings";
import WorldwideShipping from "../pages/Payment-Method/WorldwideShipping";
import OrderOnlineService from "../pages/Payment-Method/OrderOnlineService";
import MakePayment from "../pages/Payment-Method/MakePayment";
import ReturnDays from "../pages/Payment-Method/ReturnDays";

import ValidationVendor from "../pages/validationIdenti/Vendors/ValidationVendor";
import ValidationCelebrities from "../pages/validationIdenti/Celebrities/ValidationCelebrities";

import AddProduct from "../pagesVendor/Product/AddProduct";
// import WelcomeEmail from "../pages/Email/WelcomeEmail";
import Signup from "../pages/account/Signup";
// import ProtectedRoutes from "./ProtectedRoute/ProtectedRoute";
import { /* useDispatch, */ useSelector } from "react-redux";
// import { vendorVerification } from "../Actions/VendorActions/vendorActions";
// import { gettingAuthProfile } from "../Actions/GetAuthProfileAction/GetAuthProfileAction";
import SearchPageProducts from "../pages/search/SearchPageProducts";
import Traffic from "../pagesVendor/traffic/Traffic";
import ContentTraffics from "../pagesVendor/traffic/ContentTraffics";
import CustomerOrderTraking from "../pages/OrderTracking/CustomerOrderTraking";

// import { Auth } from "../utils/Auth";

const FangwayRoute = () => {

  // const dispatch = useDispatch();
  // const [token, setToken] = useState(null);
  // const [statusCode, setStatusCode] = useState("");

  const userLoginInfo = useSelector((state) => state.userLoginInfo);
  // const { Authorization = "" } = Auth();
  // console.log({ Authorization });

  // const userInfoData = JSON.parse(localStorage.getItem("userInfo"));

  const { userInfo: { profile: { authId = "" } = {} } = {} } = userLoginInfo;

  // console.log("userLoginInfo::>", Authorization);

  useEffect(() => {
    if (authId) {
      // alert;
      // localStorage.setItem("userInfo", JSON.stringify(userInfo));
      // setToken(authId);
    }
  }, [authId]);

  useEffect(async () => {
    // setIsLoading(true);
    // await dispatch(gettingAuthProfile());
    // setIsLoading(false);
  });


  return (
    <Router>
      <div className="App">
        <Routes>
          <>
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/vendor-dashboard" element={<VenderDashboard />} />
            {/* <Route
              path="/dashboard"
              element={<DashboardLayout user="vendor" />}
            >

            </Route> */}

            <Route path="/product-vendor" element={<AddProduct />} />
            <Route path="/vendor-dashboard-info" element={<DashboardLayout />} />
            <Route path="/product-search" element={<SearchPageProducts />} />
            <Route path="/product-celebrity-traffics" element={<ContentTraffics />} />

            <Route path="/verification-vendor" element={<ValidationVendor />} />

            <Route path="/product-detail" element={<ProductDetail />} />
            {/* <Route path="product-detail" element={<ProductDetail/>} /> */}

            {/* <Route path="/payment-successful" element={<PaymentSuccessful />} /> */}
            {/* <Route path="/payment" element={<Payment />} /> */}
            <Route path="/checkout" element={<Checkout />} />
            {/* <Route path="/product" element={<Product />} /> */}
            <Route path="/cart" element={<Cart />} />
            <Route path="/tracking" element={<OrderTracking />} />
            
            <Route path="/customer-detail-order" element={ <CustomerOrderTraking /> } />

            {/*<Route path="/email-desing" element={<EmailDesing />} />*/}
            <Route path="/thankyou" element={<ThankYou />} />

            {/* Protected Routes */}
            {/* <Route element={<ProtectedRoutes />}> */}
            <Route
              path="/celebrity-selecting"
              element={<CelebritySelecting />}
            />
            {/* </Route> */}
            <Route path="/forget-password" element={<ForgetPassowrd />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            {/*<Route*/}
            {/*  path="/email-reset-template"*/}
            {/*  element={<EmailTemplateReset />}*/}
            {/*/>*/}
            <Route path="/faq-page" element={<FaqPage />} />

            {/* <Route path="/select-celebrity" element={<SelectCelebrity />} />  Already comment*/}

            {/*<Route path="/welcome-email" element={<WelcomeEmail />} />*/}

            <Route
              path="/verification-celebritie"
              element={<ValidationCelebrities />}
            />

            {/* celebrity dashboard routes start */}

            <Route path="/celebrity-dashboard" element={<Dashboard />} />
            {/* <Route
              path="audiences-celebrity"
              element={<AudiencesCelebrity />}
            /> */}
            <Route path="/celebrity-settings" element={<SettingsCelebrity />} />
            <Route path="/celebrity-earning" element={<CelebrityMyEarning />} />

            {/* celebrity dashboard routes end  */}

            {/* Customer dashboard routes start  */}

            <Route path="/customer-dashboard" element={<CustomerDashboard />} />
            <Route
              path="/customer-search-history"
              element={<CustomerPaymentHistory />}
            />
            <Route
              path="/customer-order-history"
              element={<CustomerOrderHistory />}
            />
            <Route
              path="/customer-payment-history"
              element={<CustomerSearchHistory />}
            />
            <Route
              path="/customer-favorite-product"
              element={<CustomerFavoriteProduct />}
            />
            <Route path="/customer-settings" element={<CustomerSettings />} />

            {/* customer dashboard routes end  */}

            {/* PaymentMethod Dummy pages start  */}

            <Route
              path="/world-wide-shipping"
              element={<WorldwideShipping />}
            />
            <Route
              path="/order-online-service"
              element={<OrderOnlineService />}
            />
            <Route path="/make-payment" element={<MakePayment />} />
            <Route path="/return-days" element={<ReturnDays />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />

          </>
        </Routes>
      </div>
    </Router>
  );
};

export default FangwayRoute;
