import React, { useState, useEffect } from "react";
import "./DashboardLayout.css";
import { Link /* Outlet */ } from "react-router-dom";
import UserSesionSection from "../UserSesionSection/UserSesionSection";

// import CartIcon from './assets/cart-shopping-solid.svg';

// import CouponIcon from "./assets/confirmation_number-24px.svg";
// import CouponIcon2 from "./assets/confirmation_number-2-24px.png";

// import AnaliticIcon from "./assets/insights-24px.png";
// import AnaliticIcon2 from "./assets/insights-2-24px.png";

// import SettingsIcon from "./assets/Trazado 5719.png";
// import SettingsIcon2 from "./assets/Trazado-2-5719.png";

// import DollarIcon from "./assets/Icon metro-dollar.svg";
// import AudenceIcon from "./assets/Icon ionic-ios-people.svg";

import AllProducts from "../../pagesVendor/allProducts/AllProducts";
import Settings from "../../pagesVendor/settings/Settings";

import Order from "../../pagesVendor/Orders/Orders";

import { verifyTokenUser } from "../../utils/Auth";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

export default function DashboardLayout() {
  // const [active, setActive] = useState("coupon");

  // return (
  //   <div>
  //     <div>
  //       <div className="dashboard__navegacion">
  //         <div className="dashboard__navegacion__user">
  //           <UserSesionSection />
  //         </div>
  //         <div className="dashboard__navegacion-conten-nav">
  //           <nav className="dashboard__navegacion-conten-nav-ul-nav-content">
  //           <Fragment>
  //                 <Link to="/vendor-all-products">
  //                   <div
  //                     onClick={() => setActive("trafic-vendor")}
  //                     className={`dashboard__navegacion-conten-nav-link-nav ${
  //                       active === "trafic-vendor" ? "active-nav" : ""
  //                     }`}
  //                   >
  //                     {active === "trafic-vendor" ? (
  //                       <img src={AnaliticIcon2} alt="icon-nav" />
  //                     ) : (
  //                       <img src={AnaliticIcon} alt="icon-nav" />
  //                     )}

  //                     <p className="dashboard__navegacion-conten-nav-link-nav-link">
  //                       All Product
  //                     </p>
  //                   </div>
  //                 </Link>
  //                 <Link to="settings-vendor">
  //                   <div
  //                     onClick={() => setActive("setting-vendor")}
  //                     className={`dashboard__navegacion-conten-nav-link-nav ${
  //                       active === "setting-vendor" ? "active-nav" : ""
  //                     }`}
  //                   >
  //                     {active === "setting-vendor" ? (
  //                       <img src={SettingsIcon2} alt="icon-nav" />
  //                     ) : (
  //                       <img src={SettingsIcon} alt="icon-nav" />
  //                     )}

  //                     <p className="dashboard__navegacion-conten-nav-link-nav-link">
  //                       Settings
  //                     </p>
  //                   </div>
  //                 </Link>
  //               </Fragment>
  //           </nav>
  //         </div>
  //       </div>
  //     </div>
  //     <div>
  //       <div>
  //         <Outlet />
  //       </div>
  //     </div>
  //   </div>
  // );

  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  const [content, setContent] = useState("MY-ITEMS");

  const selectorRedux = useSelector((state) => state.dashboardVendor);

  useEffect(() => {
    if (selectorRedux.selectContent) {
      setContent(selectorRedux.selectContent);
    }
  }, [selectorRedux.selectContent]);

  useEffect(() => {
    const very = async () => {
      const data = await verifyTokenUser();
      if (!data.token) return navigate("/");
      if (!data.sesion) return navigate("/signin");
      setLoadingSesion(false);
    };
    setLoadingSesion(true);
    very();
  }, []);

  return (
    <div>
      <div>
        <div className="dashboard__navegacion">
          <div className="dashboard__navegacion__user">
            <UserSesionSection />
          </div>
          <div className="dashboard__navegacion-conten-nav">
            <section className="myearning-tabs-main">
              <div>
                {/* <!-- Nav tabs --> */}
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <Link to="/vendor-dashboard" className="nav-link">
                      <span>
                        <img
                          src="images/icons8-speed-30.png"
                          alt="dollor"
                        ></img>
                      </span>{" "}
                      My Dashboard
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        content === "MY-ITEMS" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#all-products"
                    >
                      <span>
                        <img
                          className="myearning-active"
                          src="images/audience-active.png"
                          alt="dollor"
                        ></img>
                        <img
                          className="myearning-inactive"
                          src="images/audience.png"
                          alt="dollor"
                        ></img>
                      </span>{" "}
                      My items
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        content === "ORDERS" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#orders-vendor"
                    >
                      <span>
                        <img
                          className="myearning-active"
                          src="images/shopping-cart (2).png"
                          alt="dollor"
                        ></img>
                        <img
                          className="myearning-inactive"
                          src="images/shopping-cart.png"
                          alt="dollor"
                        ></img>
                      </span>{" "}
                      Orders
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        content === "SETTINGS" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#setting-vendor"
                    >
                      <span>
                        <img
                          className="myearning-active"
                          src="images/Icon material-local-offer.png"
                          alt="dollor"
                        ></img>
                        <img
                          className="myearning-inactive"
                          src="images/Icon-material-local-offer.png"
                          alt="dollor"
                        ></img>
                      </span>{" "}
                      Settings
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="myearning-tabs-content">
        <div className="">
          {/* <!-- Tab panes --> */}
          <div className="tab-content">
            <div id="home" className="tab-pane active">
              {content === "MY-ITEMS" ? (
                <AllProducts />
              ) : content === "ORDERS" ? (
                <Order />
              ) : (
                <Settings />
              )}
            </div>
            <div id="all-products" className="tab-pane fade">
              <AllProducts />
            </div>
            <div id="orders-vendor" className=" tab-pane fade">
              <Order />
            </div>
            <div id="setting-vendor" className=" tab-pane fade">
              <Settings />
            </div>
          </div>
        </div>

        <span className="db-bottom-logo">
          <img src="images/Group 1145.png" alt="logo" className="img-fluid" />
        </span>
      </div>
    </div>
  );
}
