import React from "react";
import { Link } from "react-router-dom";
// import CartIcon from '../../../../Assets/cart-icon.png';

import { useDispatch } from 'react-redux';
import { selectContentDashboard } from '../../../../Actions/DashboardVendor/DashboarVendor';

export default function tVenderDbContent() {

  const dispatch = useDispatch();

  const handleClickContent = ( content ) => {
      dispatch(selectContentDashboard(content))
  } 

  return (
    <div>
      <div className="main_container dashboard_container2">
        <div className="dashboard_main">
          <div className="row no-gutters w-100">
            <div className="col-md-4 custom-box-padding" > 
              <Link to="/product-vendor" onClick={() => handleClickContent("ADD-PRODUCT")} >
                <div className="db-content-links">
                  <span className="">
                    <img
                      src="images/active-trafic.png"
                      alt="money"
                      className="img-fluid"
                    ></img>
                  </span>
                  <p>Add product</p>
                </div>
              </Link>
              <div className="db-box-shade"></div>
            </div>
            <div className="col-md-4 custom-box-padding">
              <Link to="/vendor-dashboard-info" onClick={() => handleClickContent("MY-ITEMS")} >
                <div className="db-content-links">
                  <span className="">
                    <img
                      src="images/add-coupen.jpg"
                      alt="usres"
                      className="img-fluid"
                    ></img>
                  </span>
                  <p>My items</p>
                </div>
              </Link>
              <div className="db-box-shade active"></div>
            </div>
            <div className="col-md-4 custom-box-padding">
              <Link to="/vendor-dashboard-info" onClick={() => handleClickContent("SETTINGS")}>
                <div className="db-content-links">
                  <span className="">
                    <img
                      src="images/Path 5719.svg"
                      alt="offer"
                      className="img-fluid"
                    ></img>
                  </span>
                  <p>Settings</p>
                </div>
              </Link>
              <div className="db-box-shade"></div>
            </div>
            <div className="col-md-4 custom-box-padding">
              <Link to="/vendor-dashboard-info" onClick={() => handleClickContent("ORDERS")}>
                <div className="db-content-links">
                <span className="">
                    <img src="images/cart-icon.png" alt="usres" className="img-fluid"></img>
                  </span>
                  <p>Orders</p>
                </div>
              </Link>
              <div className="db-box-shade"></div>
            </div>
          </div>
          <span className="db-bottom-logo">
            <img
              src="images/Group 1145.png"
              alt="logo"
              className="img-fluid"
            ></img>
          </span>
        </div>
      </div>
    </div>
  );
}
