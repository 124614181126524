import React,{useState, useEffect} from "react";
import "./ContentTraffic.css";
// import { FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux'

import ImageProduct from "../assets/Enmascarar grupo 2.png";
import MoreCelebritie from "../components/MoreCelebritie";

// import ImageAnalitic from "../assets/Statistics.png";
import {getInfoPromotion} from '../../../Actions/DashboardVendor/DashboarVendor';

import IconCircle from "../assets/Icon awesome-arrow-circle-up.png";
import CreatePromotionModal from "../../../Modals/CreatePromotionModal";

export default function ContentTraffic({dataDealsProduct,productId}) {

  console.log("Data caon >>>> ",dataDealsProduct);

  const selectorRedux = useSelector((state) => state.dashboardVendor);
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [createPromotion, setCreatePromotion] = useState({});
  const [open, setOpen] = useState(false);
  const handleActionModal = (action) => setOpen(action);

  useEffect(() => {
      if(selectorRedux && selectorRedux.idPromotion){
        dispatch(getInfoPromotion(selectorRedux.idPromotion,productId));
      }
  }, [selectorRedux.idPromotion])


  useEffect(() => {
    if(selectorRedux && selectorRedux.infoPromotion){
      console.log("selectorRedux.infoPromotion >>> ",selectorRedux.infoPromotion)
      setData(selectorRedux.infoPromotion);
    }
}, [selectorRedux.infoPromotion])
  

  return (
    <div className="content__traffic">
      {/* section celebrity info */}
      {selectorRedux.loading ? <div className="se-pre-con"></div> : ""}
      <div className="content__traffic-section-analytic">
        
        <div className="content__traffic-d-flex content__traffic-d-block">
          <div className="content__traffic-section-analytic-sale">
            <div className="content__traffic-d-flex">
              <p className="content__traffic-section-analytic-sale-num">200</p>
              <img
                className="content__traffic-icon-circle mt-2"
                src={IconCircle}
                alt="circle"
              />
            </div>
            <p className="content__traffic-section-analytic-sale-sale">
              No. of Sale
            </p>
          </div>
          <div className="content__traffic-section-total">
            <div className="content__traffic-d-flex">
              <p className="content__traffic-section-total-k">$15k</p>
              <img
                className="content__traffic-icon-circle mt-2"
                src={IconCircle}
                alt="circle"
              />
            </div>

            <p className="content__traffic-section-total-earnings">
              Total Earnings
            </p>
          </div>
        </div>

        <div className="content__traffic-section-avatar">
          <div className="content__traffic-section-avatar-content">
            <p className="content__traffic-section-avatar-content-name">
              {`${data && data.infoCelebrity && data.infoCelebrity.firstName} ${data && data.infoCelebrity && data.infoCelebrity.lastName}`}
            </p>
            <p className="content__traffic-section-avatar-content-fans">
              { data && data.infoCelebrity && data.infoCelebrity.supports} Supports
            </p>
          </div>
          <div
            className="select__celebrity-vendor-avatar"
            style={{
              backgroundImage:
                'url("https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.cabroworld.com%2Fwp-content%2Fuploads%2F2017%2F10%2Ffamososquenacieronmismoa%25C3%25B1oquetu5.jpg&f=1&nofb=1")',
              width: "80px",
              height: "80px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderRadius: "50%",
              border: "1px solid rgb(122,116,153)",
              backgroundSize: "150%",
            }}
          ></div>
        </div>
      </div>

      {/* section celebrity info end */}

      {/* section product info */}


      
      <div className="content__traffic-product">
        <div className="content__traffic-product-image ml-2">
          <img src={data && data.infoProduct && data.infoProduct.image} alt="image product" />
        </div>
        <div className="content__traffic-product-info">
          <p className="content__traffic-product-info-name">
            {data && data.infoProduct && data.infoProduct.name}
          </p>
          {/* <p className="content__traffic-product-info-name-subtitle">
            Meet the all-new Echo Dot
          </p> */}
          <p className="content__traffic-product-info-description">
            {data && data.infoProduct && data.infoProduct.description}
          </p>
          <div className="content__traffic-product-info-pricing mt-5">
            <div className="content__traffic-product-info-pricing-discount">
              <p>${data && data.infoProduct && data.infoProduct.amount}</p>
            </div>
            <div className="content__traffic-product-info-pricing-off">
              <p>{data && data.discount}%OFF</p>
            </div>
          </div>
        </div>
      </div>


      {/* section product info end */}

      {/* section analitic celebrities */}
      {/* <div>
        <div className="content__traffic-analitic">
          <div className="content__traffic-analitic-tabs">
            <p>Celebrity Stats</p>
          </div>
          <div className="content__traffic-analitic-content-tab">
            <div className="content__traffic-d-flex content__traffic-block-trafic content__traffic-analitic-content-tab-total">
              <div className="content__traffic-d-flex content__traffic-border-rigth">
                <p className="content__traffic-analitic-content-tab-total-total">
                  Total Earning:
                </p>
                <p className="content__traffic-analitic-content-tab-total-suport">
                  $15k
                </p>
              </div>
              <div className="content__traffic-d-flex content__traffic-border-rigth">
                <p className="content__traffic-analitic-content-tab-total-suport">
                  21323
                </p>
                <p className="content__traffic-analitic-content-tab-total-active">
                  Active Supporters
                </p>
              </div>
            </div>
            <div
              className="content__traffic-analitic-content-tab-1"
            >
              <img src={ImageAnalitic} alt="image analitic" />
            </div>
          </div>
        </div>
      </div> */}
      {/* section analitic celebrities end */}

      {/* More selecrities section */}
      <div className="content__traffic-more-celebrities">
        <p>More Celebrities</p>
        <div className="content__traffic-more-celebrities-content">
          {
            data && data.celebritiesPromotions && data.celebritiesPromotions.map((cele) => {
              return <MoreCelebritie celebrity={cele} setCreatePromotion={setCreatePromotion} handleActionModal={handleActionModal} />
            })
          }
          {/* <MoreCelebritie />
          <MoreCelebritie />
          <MoreCelebritie />
          <MoreCelebritie /> */}
        </div>
      </div>
      <CreatePromotionModal open={open} handleActionModal={handleActionModal} data={data} createPromotion={createPromotion} productId={data && data.infoProduct && data.infoProduct.id}   />
      {/* More selecrities section end */}
    </div>
  );
}
