import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { uploadFile } from "../../../Actions/UploadFileAction/UploadFileAction";
import "./ContentValidation.css";
import axios from "axios";
import { vendorVerification } from "../../../Actions/VendorActions/vendorActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// import { Formik, Form, Field, ErrorMessage } from "formik";

export default function ContentValidation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [license, setLicense] = useState("");
  const [tradeMark, setTradeMark] = useState("");
  const [trackRecord, setTrackRecord] = useState("");
  const [operational, setOperational] = useState("");
  const [bussiness, setBussiness] = useState("");

  console.log("license::>", name);
  console.log("license::>", license);
  // console.log("license::>", tradeMark);
  // console.log("license::>", trackRecord);
  // console.log("license::>", operational);
  // console.log("license::>", bussiness);
  const handleChange = async (e, data) => {
    setIsLoading(true);

    let file = e.target.files[0];
    // alert("if");
    let formData = new FormData();
    formData.append("files", file);

    if (data === "license") {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      await axios
        .post("https://fangway.com/users/misc/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo.token}`,
          },
        })
        .then((res) =>
          // let sellingLicense = res.data.data[0].filepath
          // console.log("response:>>", res)
          setLicense(res.data.data[0].filepath)
        )
        .catch((error) => toast.error(error));
      // await dispatch(uploadFile(file));
      // setIsLoading(false);
    }
    if (data === "tradeMark") {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      await axios
        .post("https://fangway.com/users/misc/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo.token}`,
          },
        })
        .then((res) =>
          // let sellingLicense = res.data.data[0].filepath
          setTradeMark(res.data.data[0].filepath)
        )
        .catch((error) => console.log(error));
    }
    if (data === "trackRecord") {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      await axios
        .post("https://fangway.com/users/misc/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo.token}`,
          },
        })
        .then((res) =>
          // let sellingLicense = res.data.data[0].filepath
          setTrackRecord(res.data.data[0].filepath)
        )
        .catch((error) => console.log(error));
    }
    if (data === "operational") {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      await axios
        .post("https://fangway.com/users/misc/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo.token}`,
          },
        })
        .then((res) =>
          // let sellingLicense = res.data.data[0].filepath
          setOperational(res.data.data[0].filepath)
        )
        .catch((error) => console.log(error));
    }
    if (data === "bussiness") {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      await axios
        .post("https://fangway.com/users/misc/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo.token}`,
          },
        })
        .then((res) =>
          // let sellingLicense = res.data.data[0].filepath
          setBussiness(res.data.data[0].filepath)
        )
        .catch((error) => console.log(error));
    }

    setIsLoading(false);

    // setProfileImage(file);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name) {
      setIsLoading(true);
      let verifyData = {
        name: name,
        sellingLicense: license,
        trademarkRegistration: tradeMark,
        trackRecord: trackRecord,
        operationalHistory: operational,
        businessRegistration: bussiness,
      };
      console.log("verifyData::>", verifyData);
      await dispatch(
        vendorVerification(verifyData, () => {
          navigate("/");
        })
      );
      setIsLoading(false);
    } else {
      toast.error("Please add name First");
    }
  };

  return (
    <div className="container">
      {isLoading ? <div className="se-pre-con"></div> : ""}

      <p className="content__vendor-title">Vendor Account Verification</p>

      <form onSubmit={handleSubmit}>
        <div className="content__vendor-form-content">
          <div>
            <p className="content__vendor-content-form-p-style">
              Business Name
            </p>
            <input
              name="name"
              type="text"
              className="content__vendor-content-form-input-style"
              placeholder="Business name"
              onChange={(e) => handleName(e)}
            />
          </div>

          <div>
            <p className="content__vendor-content-form-p-style">
              Item Selling License
            </p>
            <input
              name="sellingLicense"
              type="file"
              // className="content__vendor-content-form-input-style"
              placeholder="Item Selling License"
              onChange={(e) => handleChange(e, "license")}
            />
          </div>

          <div>
            <p className="content__vendor-content-form-p-style">
              Trade mark Registration
            </p>
            <input
              name="trademarkRegistration"
              type="file"
              // className="content__vendor-content-form-input-style"
              placeholder="Trade mark Registration"
              onChange={(e) => handleChange(e, "tradeMark")}
            />
          </div>

          <div>
            <p className="content__vendor-content-form-p-style">
              Track Record of Item
            </p>
            <input
              name="trackRecord"
              type="file"
              // className="content__vendor-content-form-input-style"
              placeholder="Track Record of Item"
              onChange={(e) => handleChange(e, "trackRecord")}
            />
          </div>

          <div>
            <p className="content__vendor-content-form-p-style">
              Operational History of company
            </p>
            <input
              name="operationalHistory"
              type="file"
              // className="content__vendor-content-form-input-style"
              placeholder="Operational History of company"
              onChange={(e) => handleChange(e, "operational")}
            />
          </div>

          <div>
            <p className="content__vendor-content-form-p-style">
              Business Registration
            </p>
            <input
              name="businessRegistration"
              type="file"
              // className="content__vendor-content-form-input-style"
              placeholder="Business Registration"
              onChange={(e) => handleChange(e, "bussiness")}
            />
          </div>
        </div>
        <div className="content__vendor-form-content-button">
          <button className="content__vendor-form-button" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
