import React from 'react';
import { Link } from 'react-router-dom';
import MySponsers from '../MySponsers/MySponsers';
import ProfileSettings from '../ProfileSettings/ProfileSettings';
import PromoteItems from '../PromoteItems/PromoteItems';
import "./CelebrityEarning.css";
export default function CelebrityEarning() {
    return <div>
        <section className='dashboard_container2'>
            <span className='menuIcon'></span>
            <div className='celebrity-myearnig-main'>
                <section className='myearning-tabs-main'>
                    <div>
                        {/* <!-- Nav tabs --> */}
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <Link to="/celebrity-dashboard" className="nav-link" ><span>
                                    <img src='images/icons8-speed-30.png' alt='dollor'></img></span> My Dashboard</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#home"><span>
                                    <img className='myearning-active' src='images/Icon metro-dollar.png' alt='dollor'></img>
                                    <img className='myearning-inactive' src='images/Icon-metro-dollar-inactive.png' alt='dollor'></img>
                                </span> My Earning</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#menu1"><span>
                                    <img className='myearning-active' src='images/audience-active.png' alt='dollor'></img>
                                    <img className='myearning-inactive' src='images/audience.png' alt='dollor'></img>
                                </span> Audiences</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#menu2"><span>
                                    <img className='myearning-active' src='images/Icon material-local-offer.png' alt='dollor'></img>
                                    <img className='myearning-inactive' src='images/Icon-material-local-offer.png' alt='dollor'></img>
                                </span> Promote items</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#menu3"><span>
                                    <img className='myearning-active' src='images/setting-active.png' alt='dollor'></img>
                                    <img className='myearning-inactive' src='images/Path 5719.png' alt='dollor'></img>
                                </span> settings</a>
                            </li>
                        </ul>
                    </div>
                    <div className='myearning-tabs-content'>
                        <div className="">
                            {/* <!-- Tab panes --> */}
                            <div className="tab-content">
                                <div id="home" className="tab-pane active">
                                    <ul className='total-EM-list'>
                                        <li>
                                            <h2>$55k</h2>
                                            <span><a href='#'><img src='images/Icon awesome-arrow-circle-up.svg' alt='arrowup'></img></a></span>
                                            <p className='total-earning-text'>Total Earnings</p>
                                        </li>
                                        <li>
                                            <h2>$40k</h2>
                                            <span><a href='#'><img src='images/Icon awesome-arrow-circle-up.svg' alt='arrowup'></img></a></span>
                                            <p className='total-earning-text'>total money donated <br />
                                                to charity</p>
                                        </li>

                                    </ul>
                                    <div className='my-earning-graph'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='earning-graph-box'>
                                                    <p>Earnings by Product Category</p>
                                                    <div className='earning-graph-img'><span><img src='images/earningg1.png' alt='g1'></img></span></div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='earning-graph-box'>
                                                    <p>Charity donation</p>
                                                    <span className='graph-right-text'>Last 6 month</span>
                                                    <div className='earning-graph-img'><span><img src='images/Chart1.png' alt='g1'></img></span></div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='earning-graph-box'>
                                                    <p>Monthely earning</p>
                                                    <span className='graph-right-text w-100'>15 JAN - 21 JUN</span>
                                                    <div className='earning-graph-img'><span><img src='images/chart2.jpg' alt='g1'></img></span></div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='earning-graph-box'>
                                                    <p>Earnings from sponsers</p>
                                                    <span className='graph-right-text'>Last 6 month</span>
                                                    <div className='earning-graph-img'><span><img src='images/chart3.png' alt='g1'></img></span></div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='earning-graph-box'>
                                                    <p>Earnings by Products</p>
                                                    <span className='graph-right-text'>Last 6 month</span>
                                                    <div className='earning-graph-img'><span><img src='images/graph5.jpg' alt='g1'></img></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <MySponsers />
                                    <div className='more-sponser'>
                                       
                                        <ul>
                                            <li>
                                                <span><img src='images/lighting.png' alt='lighting'></img></span>
                                            </li>
                                            <li>
                                                <span><img src='images/hex-lab.png' alt='lighting'></img></span>
                                            </li>
                                            <li>
                                                <span><img src='images/muzica.png' alt='lighting'></img></span>
                                            </li>
                                            <li>
                                                <span><img src='images/asgardia.png' alt='lighting'></img></span>
                                            </li>
                                            <li>
                                                <span><img src='images/utosia-2.png' alt='lighting'></img></span>
                                            </li>
                                            <li>
                                                <span><img src='images/fossa.png' alt='lighting'></img></span>
                                            </li>
                                            <li>
                                                <span><img src='images/earth-2.png' alt='lighting'></img></span>
                                            </li>
                                            <li>
                                                <span><img src='images/ztos.png' alt='lighting'></img></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div id="menu1" className="tab-pane fade">
                                    <ul className='total-EM-list'>
                                        <li>
                                            <h2>2450</h2>
                                            <span><a href='#'><img src='images/Icon awesome-arrow-circle-up.svg' alt='arrowup'></img></a></span>
                                            <p className='total-earning-text'>Total Audience</p>
                                        </li>
                                        <li>
                                            <h2>108</h2>
                                            <span><a href='#'><img src='images/Icon material-fiber-new.png' alt='arrowup'></img></a></span>
                                            <p className='total-earning-text'>New Audience</p>
                                        </li>
                                        <li className='active-li'>
                                            <h2>89</h2>
                                            <p className='total-earning-text'>Active Audience</p>
                                        </li>
                                    </ul>
                                    <div className='my-earning-graph'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='earning-graph-box'>
                                                    <p>New Audiences</p>
                                                    <span className='graph-right-text'>
                                                        <select className='form-control'>
                                                            <option>This Month</option>
                                                            <option>Last Month</option>
                                                            <option>New Month</option>
                                                        </select>
                                                    </span>
                                                    <div className='earning-graph-img'><span><img src='images/g6.png' alt='g1'></img></span></div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='earning-graph-box'>
                                                    <p>Active Audiences</p>
                                                    <span className='graph-right-text'>
                                                        <select className='form-control'>
                                                            <option>This Month</option>
                                                            <option>Last Month</option>
                                                            <option>New Month</option>
                                                        </select>
                                                    </span>
                                                    <div className='earning-graph-img'><span><img src='images/g7.png' alt='g1'></img></span></div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='earning-graph-box'>
                                                    <p>Audience Grouth</p>
                                                    <span className='graph-right-text w-100'>Last 3 Month</span>
                                                    <div className='earning-graph-img'><span><img src='images/g8.png' alt='g1'></img></span></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                

                                </div>
                                <div id="menu2" className=" tab-pane fade">
                                    <PromoteItems />
                                </div>
                                <div id="menu3" className=" tab-pane fade">
                                    <ProfileSettings/>
                                </div>
                            </div>
                        </div>

                        <span className="db-bottom-logo"><img src="images/Group 1145.png" alt="logo" className="img-fluid" /></span>
                    </div>


                </section>



            </div>

        </section>

    </div>
}
