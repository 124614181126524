import React from "react";
import "./Footer.css";
export default function Footer() {
  return <div>
    <section className='footer-section'>
      <div className='container'>
        <section className="footer">
            <div className=" footer_cont">
              <div className="row justify-content-center">

                <div className="col-lg-4 col-md-4 col-12 text-md-left text-center pt-md-0 pt-4 footer-border-left">
                  <div>
                    <p className="h2 text-white link_text">Contact us</p>
                    <div className="row">
                      <div className="col-12 text-white footer-mob-resp">

                        <div>
                          <div className="d-flex justify-content-start  align-items-center">
                            <div>
                              <span><img className="" src="images/phone.svg" alt="phone" /></span>
                            </div>
                            <div className="pl-md-5 pl-0">
                              <p className="mb-md-0 mb-2 pl-md-0 pl-2 address-text">(498)981-4317</p>
                            </div>
                          </div>

                        </div>
                        <div>
                          <div className="d-flex justify-content-start align-items-center">
                            <div>
                              <span><img className="" src="images/adress.svg" alt="phone" /></span>
                            </div>
                            <div className="pl-md-5 pl-0">
                              <p className="mb-md-0 mb-2 pl-md-0 pl-2 address-text">Utah</p>
                            </div>
                          </div>

                        </div>
                        <div>
                          <div className="d-flex justify-content-start  align-items-center">
                            <div>
                              <span><img className="" src="images/contact.svg" alt="phone" /></span>
                            </div>
                            <div className="pl-md-5 pl-0">
                              <a href='#' className=" address-text">info@fangway.org</a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 text-md-left text-center pt-md-0 pt-4 footer-border-left">
                  <div>
                    <p className="h2 text-white link_text">Social Contacts</p>
                    <div className="row">
                      <div className="col-12 text-white footer-mob-resp">

                        <div>
                          <div className="d-flex justify-content-start align-items-center">
                            <div>
                              <span><img className="" src="images/facebook.svg" alt="phone" /></span>
                            </div>
                            <div className="pl-md-5 pl-0">
                              <a href='#' className=" address-text">Facebook</a>
                            </div>
                          </div>

                        </div>
                        <div>
                          <div className="d-flex justify-content-start align-items-center">
                            <div>
                              <span><img className="" src="images/instagram.svg" alt="phone" /></span>
                            </div>
                            <div className="pl-md-5 pl-0">
                              <a href='#' className=" address-text">Instagram</a>
                            </div>
                          </div>

                        </div>
                        <div>
                          <div className="d-flex justify-content-start align-items-center">
                            <div>
                              <span><img className="" src="images/twitter.svg" alt="phone" /></span>
                            </div>
                            <div className="pl-md-5 pl-0">
                              <a href='#' className=" address-text">Twitter</a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="  col-lg-4  col-md-4 col-12 footer-border-right">
                  <div className="footer-logo"><a href='#'> <img src="images/logo.png" className="footerlogo" alt="logo" /></a></div>
                  <div className='copyright-text'>Copyright © 2021. Fangway.org</div>

                </div>
              </div>
            </div>
        </section>
      </div>
    </section>
  </div>
}
