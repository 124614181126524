import React from "react";
import Pagination from "@mui/material/Pagination";


export default function PaginationMaterial({totalPages,page,handleChange}) {
  return (
    <div>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
        size="large"
      />
    </div>
  );
}
