import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getInfoVendorDash } from "../../../../Actions/DashboardVendor/DashboarVendor";
import { userLogoutAction } from "../../../../Actions/LoginActions/LoginAction";


import "../Sidebar/Sidebar.css";
import "../VenderSidebar/VenderSidebar.css";

export default function VenderSidebar() {
  // localStorage.setItem(
  //   "tokenVendor",
  //   JSON.stringify(require("../../../../pagesVendor/vendor.json"))
  // );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = async () => {

    dispatch(userLogoutAction());
    navigate("/signin");
  };

  const storage = JSON.parse(localStorage.getItem("userInfo"));

  // if(!storage) navigate('/singIn')

  const reduxDashboardVendor = useSelector((state) => state.dashboardVendor);

  const [dataVendor, setDataVendor] = useState(null);

  useEffect(() => {
    dispatch(getInfoVendorDash());
  }, []);

  useEffect(() => {
    if (reduxDashboardVendor && reduxDashboardVendor.vendorInfoDash)
      setDataVendor(reduxDashboardVendor.vendorInfoDash.data);
  }, [reduxDashboardVendor.loading]);

  return (
    <div>
      {reduxDashboardVendor.loading ? <div className="se-pre-con"></div> : ""}
      <div className="sidebar-main">
        <span className="closeNav"></span>
        <div className="sidebar_main-inner">
          <div className="db-profile">
            <span>
              <img
                src={storage && storage.profile && storage.profile.imagePath ? storage.profile.imagePath : "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fvectorified.com%2Fimages%2Fno-profile-picture-icon-14.png&f=1&nofb=1"}
                alt="profiledb"
              ></img>
            </span>
            <div className="db-proname-btn">
              <h6>
                {
                  storage && storage.profile ? `
                  ${storage.profile.firstName} ${storage.profile.middleName} 
                  ${storage.profile.lastName}
                  ` : ""
                }
                
              </h6>
              <p>Vendor science Jan 2021</p>
              <button className="btn logout" onClick={handleClick}>
                Logout{" "}
                <i>
                  <img src="images/eye.svg" alt="eyelogouticon"></img>
                </i>
              </button>
            </div>
          </div>
          <ul className="vender-sidebarlist">
            <li>
              <h3>{dataVendor ? dataVendor.product_listed : 0}</h3>
              <p>Product Listed</p>
            </li>
            <li>
              <h3>{dataVendor ? dataVendor.active_deiles : 0}</h3>
              <p>Active deiles</p>
            </li>
            <li>
              <h3>$2500+</h3>
              <p>Total Earning </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
