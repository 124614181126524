// import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

const url = "https://fangway.com";
// const storage = JSON.parse(localStorage.getItem("userInfo"));

export const getCategories = () => async (dispatch) => {
  dispatch({
    type: "CATEGORY_INFO_INIT",
  });
  try {
    const { data } = await axios.get(`${url}/users/misc/categories`);
    dispatch({
      type: "CATEGORY_INFO_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "CATEGORY_INFO_FAIL",
      payload: error,
    });
  }
};

export const uploadFile = (file) => async (dispatch) => {
  dispatch({
    type: "UPLOAD_FILE_INIT",
  });
  try {
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    var bodyFormData = new FormData();
    bodyFormData.append("files", file);
    const { data } = await axios.post(
      `${url}/users/misc/upload`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${storage.token}`,
        },
      }
    );
    dispatch({
      type: "UPLOAD_FILE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "UPLOAD_FILE_FAIL",
      payload: error,
    });
  }
};

export const addProduct =
  (
    amount,
    category,
    description,
    longDescription,
    name,
    tax,
    stock,
    image,
    itemHistory,
    licensing,
    materials,
    reviewsProductFile,
    attributes,
    discount,
    // shipping,
    weight,
    length,
    width,
    height
  ) =>
  async (dispatch) => {
    dispatch({
      type: "PRODUCT_INFO_INIT",
    });
    try {
      const storage = JSON.parse(localStorage.getItem("userInfo"));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${storage.token}`,
        },
      };
      var itemFormData = new FormData();
      var licensingFormData = new FormData();
      var materialsFormData = new FormData();
      var reviewsProductFileFormData = new FormData();
      itemFormData.append("files", itemHistory);
      licensingFormData.append("files", licensing);
      materialsFormData.append("files", materials);
      reviewsProductFileFormData.append("files", reviewsProductFile);
      const item = await axios.post(
        `${url}/users/misc/upload`,
        itemFormData,
        config
      );

      const lice = await axios.post(
        `${url}/users/misc/upload`,
        licensingFormData,
        config
      );
      const materi = await axios.post(
        `${url}/users/misc/upload`,
        materialsFormData,
        config
      );
      const revi = await axios.post(
        `${url}/users/misc/upload`,
        reviewsProductFileFormData,
        config
      );

      const objectFinal = {
        amount: parseFloat(amount),
        category,
        description,
        longDescription,
        name,
        tax,
        stock: parseInt(stock),
        discount: parseFloat(discount),
        image,
        authVendorId: storage.profile.authId,
        ranking: 5,
        currency: "USD",
        itemHistory: item.data.data[0].filepath,
        licensing: lice.data.data[0].filepath,
        materials: materi.data.data[0].filepath,
        reviewsProductFile: revi.data.data[0].filepath,
        attributes,
        // shipping: parseFloat(shipping),
        weight,
        length,
        width,
        height
      };
      const { data } = await axios.post(`${url}/products/vendor`, objectFinal, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage.token}`,
        },
      });

      dispatch({
        type: "PRODUCT_INFO_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "PRODUCT_INFO_FAIL",
        payload: error,
      });
    }
  };

export const uploadImageGallery = (file) => async (dispatch) => {
  dispatch({
    type: "GALLERY_IMAGE_INIT",
  });
  try {
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    var bodyFormData = new FormData();
    bodyFormData.append("files", file);
    const { data } = await axios.post(
      `${url}/users/misc/upload`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${storage.token}`,
        },
      }
    );

    dispatch({
      type: "GALLERY_IMAGE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GALLERY_IMAGE_FAIL",
      payload: error,
    });
  }
};

export const addImageToGallery = (idProduct, urlImage) => async (dispatch) => {
  dispatch({
    type: "ADD_GALLERY_IMAGE_PRODUCT_INIT",
  });
  try {
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.post(
      `${url}/products/vendor/gallery/${idProduct}`,
      { image: urlImage },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage.token}`,
        },
      }
    );

    dispatch({
      type: "ADD_GALLERY_IMAGE_PRODUCT_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "ADD_GALLERY_IMAGE_PRODUCT_FAIL",
      payload: error,
    });
  }
};

export const deleteImageToGallery =
  (idProduct, idImage) => async (dispatch) => {
    dispatch({
      type: "DELETE_GALLERY_IMAGE_PRODUCT_INIT",
    });
    try {
      const storage = JSON.parse(localStorage.getItem("userInfo"));
      const { data } = await axios.delete(
        `${url}/products/vendor/${idProduct}/gallery/image/${idImage}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storage.token}`,
          },
        }
      );

      dispatch({
        type: "DELETE_GALLERY_IMAGE_PRODUCT_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "DELETE_GALLERY_IMAGE_PRODUCT_FAIL",
        payload: error,
      });
    }
  };

export const getInfoVendorDash = () => async (dispatch) => {
  dispatch({
    type: "GET_INFO_VENDOR_INIT",
  });
  try {
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.get(`${url}/products/vendor/general/info`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage.token}`,
      },
    });
    //
    dispatch({
      type: "GET_INFO_VENDOR_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_INFO_VENDOR_FAIL",
      payload: error,
    });
  }
};

export const getAllProductsVendor = (pagesScroll) => async (dispatch) => {
  dispatch({
    type: "GET_ALL_PRODUCT_VENDOR_INIT",
  });
  try {
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.get(
      `${url}/products/vendor/all/products?page=${pagesScroll}&limit=15`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage.token}`,
        },
      }
    );

    if (pagesScroll === 1) {
      dispatch({
        type: "DOWNLOAD_PRODUCT_VENDOR-1",
        payload: data.data.docs,
      });
    } else {
      dispatch({
        type: "DOWNLOAD_PRODUCT_VENDOR",
        payload: data.data.docs,
      });
    }

    dispatch({
      type: "GET_ALL_PRODUCT_VENDOR_SUCCESS",
      payload: data,
    });
  } catch (error) {
    toast.error("Login Failed");
    dispatch({
      type: "GET_ALL_PRODUCT_VENDOR_FAIL",
      payload: error,
    });
  }
};

export const deleteProduct = (productId, callback) => async (dispatch) => {
  dispatch({
    type: "DELETE_PRODUCT_INIT",
  });
  try {
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.delete(
      `${url}/products/vendor/${productId}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage.token}`,
        },
      }
    );

    if (data.statusCode == "201") {
      callback(data);
    } else {
      toast.error("Login Failed");
    }
    dispatch({
      type: "DELETE_PRODUCT_SUCCESS",
      payload: data.data.docs,
    });
  } catch (error) {
    callback(error);
    dispatch({
      type: "DELETE_PRODUCT_FAIL",
      payload: error,
    });
  }
};

export const updateInfoVendor =
  (objetVendor, image, callback) => async (dispatch) => {
    dispatch({
      type: "UPDATE_INFO_VENDOR_INIT",
    });
    try {
      const storage = JSON.parse(localStorage.getItem("userInfo"));
      const info = {
        business: {
          name: objetVendor.name,
          productTypes: objetVendor.productTypes,
          website: objetVendor.website,
          contactEmail: objetVendor.contactEmail,
          representatorName: objetVendor.representatorName,
          businessType: objetVendor.businessType,
          sellingLicense: storage.profile.businessInfo.sellingLicense,
          trademarkRegistration:
            storage.profile.businessInfo.trademarkRegistration,
          trackRecord: storage.profile.businessInfo.trackRecord,
          operationalHistory: storage.profile.businessInfo.operationalHistory,
          businessRegistration:
            storage.profile.businessInfo.businessRegistration,
        },
        imagePath: storage.profile.imagePath ? storage.profile.imagePath : "",
        gender: storage.profile.gender,
        middleName: storage.profile.middleName,
        firstName: storage.profile.firstName,
        dob: storage.profile.dob,
        lastName: storage.profile.lastName,
        phone: storage.profile.phone,
      };

      //   const info = {
      //     business: {
      //       name: "name",
      //       productTypes: "productTypes",
      //       website: "website",
      //       contactEmail: "contactEmail",
      //       representatorName: "representatorName",
      //       businessType: "businessType"
      //     },
      //     imagePath: "",
      //     gender: "",
      //     middleName: "",
      //     firstName: "",
      //     dob: "",
      //     lastName: "",
      //     phone: storage.profile.phone
      // }

      if (image) info.imagePath = image;

      const { data } = await axios.put(`${url}/users/vendor`, info, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage.token}`,
        },
      });

      if (data.statusCode == 200) {
        await callback(data);
      }
      dispatch({
        type: "UPDATE_INFO_VENDOR_SUCCESS",
        payload: data,
      });
    } catch (error) {
      console.log(error);
      toast.error("It seems there was an error, please try again.");
      dispatch({
        type: "GET_ALL_PRODUCT_VENDOR_FAIL",
        payload: error,
      });
    }
  };

export const updatePasswordVendor =
  (newPassObject, callback) => async (dispatch) => {
    dispatch({
      type: "UPDATE_PASSWORD_VENDOR_INIT",
    });
    try {
      const storage = JSON.parse(localStorage.getItem("userInfo"));
      const { data } = await axios.post(
        `${url}/users/auth/change-password`,
        newPassObject,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storage.token}`,
          },
        }
      );

      callback(data, null);
      //Hacer peticion de token ???
      dispatch({
        type: "UPDATE_PASSWORD_VENDOR_SUCCESS",
        payload: data,
      });
    } catch (error) {
      callback(null, error);
      dispatch({
        type: "UPDATE_PASSWORD_VENDOR_FAIL",
        payload: error,
      });
    }
  };

export const getEditProduct = (id) => async (dispatch) => {
  dispatch({
    type: "GET_EDIT_PRODUCT_VENDOR_INIT",
  });
  try {
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.get(
      `${url}/products/vendor/product/edit/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage.token}`,
        },
      }
    );

    // console.log("data update pass >>> ", data);
    // callback(data,null);
    //Hacer peticion de token ???
    dispatch({
      type: "GET_EDIT_PRODUCT_VENDOR_SUCCESS",
      payload: data,
    });
  } catch (error) {
    // callback(null,error);
    dispatch({
      type: "GET_EDIT_PRODUCT_VENDOR_FAIL",
      payload: error,
    });
  }
};

export const editProduct =
  (
    id,
    amount,
    category,
    description,
    longDescription,
    name,
    tax,
    stock,
    discount,
    // shipping,
    image,
    itemHistory,
    licensing,
    materials,
    reviewsProductFile,
    attributes,
    weight,
    length,
    width,
    height,
    callback
  ) =>
  async (dispatch) => {
    dispatch({
      type: "UPDATE_PRODUCT_VENDOR_INIT",
    });
    try {
      const storage = JSON.parse(localStorage.getItem("userInfo"));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${storage.token}`,
        },
      };

      let itemFin = null;
      let liceFin = null;
      let materiFin = null;
      let reviFin = null;

      if (itemHistory) {
        var itemFormData = new FormData();
        itemFormData.append("files", itemHistory);
        const item = await axios.post(
          `${url}/users/misc/upload`,
          itemFormData,
          config
        );
        itemFin = item.data.data[0].filepath;
      }
      if (licensing) {
        var licensingFormData = new FormData();
        licensingFormData.append("files", licensing);
        const lice = await axios.post(
          `${url}/users/misc/upload`,
          licensingFormData,
          config
        );
        liceFin = lice.data.data[0].filepath;
      }
      if (materials) {
        var materialsFormData = new FormData();
        materialsFormData.append("files", materials);
        const materi = await axios.post(
          `${url}/users/misc/upload`,
          materialsFormData,
          config
        );
        materiFin = materi.data.data[0].filepath;
      }
      if (materials) {
        var reviewsProductFileFormData = new FormData();
        reviewsProductFileFormData.append("files", reviewsProductFile);
        const revi = await axios.post(
          `${url}/users/misc/upload`,
          reviewsProductFileFormData,
          config
        );
        reviFin = revi.data.data[0].filepath;
      }
      let objectFinal = {
        amount: parseFloat(amount),
        discount: parseFloat(discount),
        category,
        description,
        longDescription,
        name,
        tax,
        stock: parseInt(stock),
        image,
        attributes,
        // shipping: parseFloat(shipping),
        weight,
        length,
        width,
        height
      };

      if (itemFin) objectFinal = { ...objectFinal, itemHistory: itemFin };
      if (itemFin) objectFinal = { ...objectFinal, licensing: liceFin };
      if (itemFin) objectFinal = { ...objectFinal, materials: materiFin };
      if (itemFin)
        objectFinal = { ...objectFinal, reviewsProductFile: reviFin };

      const { data } = await axios.put(
        `${url}/products/vendor/${id}`,
        objectFinal,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storage.token}`,
          },
        }
      );
      console.log(data);
      dispatch({
        type: "GET_EDIT_PRODUCT_VENDOR_SUCCESS",
        payload: data,
      });
      callback(data, null);
    } catch (error) {
      callback(null, error);
      dispatch({
        type: "UPDATE_PRODUCT_VENDOR_FAIL",
        payload: error,
      });
    }
  };

export const resetEditProduct = () => async (dispatch) => {
  dispatch({
    type: "RESET_REDUX_PRODUCT_EDIT_VENDOR",
  });
};

export const resetInfoProduct = () => async (dispatch) => {
  try {
    dispatch({
      type: "PRODUCT_INFO_RESET",
    });
  } catch (error) {}
};

export const resetGalleryInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: "PRODUCT_GALLERY_RESET",
    });
  } catch (error) {}
};

export const verifyLinkStripe = () => async (dispatch) => {
  dispatch({
    type: "VERIFY_LINK_STRIPE_VENDOR_INIT",
  });
  try {
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.get(
      `${url}/ecommerce/payment/vendor/link/verify`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage.token}`,
        },
      }
    );
    dispatch({
      type: "VERIFY_LINK_STRIPE_VENDOR_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "VERIFY_LINK_STRIPE_VENDOR_FAIL",
      payload: error,
    });
  }
};

export const addDiscount =
  (productId, discount, ratediscount) => async (dispatch) => {
    dispatch({
      type: "ADD_DISCOUNT_VENDOR_INIT",
    });
    try {
      const storage = JSON.parse(localStorage.getItem("userInfo"));
      const dataObject = {
        starDate: new Date(),
        finishDate: new Date(),
        discountRate: parseFloat(ratediscount),
        discountPrice: parseFloat(discount),
      };
      const { data } = await axios.post(
        `${url}/products/vendor/product/${productId}/celebrity/zl1MtLjlrWcxJ7y0FfWJMAOjHLB2`,
        dataObject,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storage.token}`,
          },
        }
      );
      dispatch({
        type: "ADD_DISCOUNT_VENDOR_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "ADD_DISCOUNT_VENDOR_FAIL",
        payload: error,
      });
    }
  };

export const getDiscountProduct = (productId) => async (dispatch) => {
  dispatch({
    type: "GET_PROMOTION_VENDOR_INIT",
  });
  try {
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.get(
      `${url}/products/vendor/action/get/product/${productId}/celebrity/zl1MtLjlrWcxJ7y0FfWJMAOjHLB2`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage.token}`,
        },
      }
    );
    dispatch({
      type: "GET_PROMOTION_VENDOR_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_PROMOTION_VENDOR_FAIL",
      payload: error,
    });
  }
};

export const consultLinkStripeApi = (callback) => async (dispatch) => {
  dispatch({
    type: "GET_LINK_STRIPE_VENDOR_INIT",
  });
  try {
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.get(`${url}/ecommerce/payment/vendor/link`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage.token}`,
      },
    });
    // console.log(data);

    callback(data, false);
    dispatch({
      type: "GET_LINK_STRIPE_VENDOR_SUCCESS",
    });
  } catch (error) {
    callback(false, error);
    dispatch({
      type: "GET_LINK_STRIPE_VENDOR_FAIL",
      payload: error,
    });
  }
};

//Get deals from select product
export const addIdPromotionRedux = (promotionId) => async (dispatch) => {
  try {
    dispatch({
      type: "ADD_PROMOTION_PRODUCT_VENDOR",
      payload: promotionId,
    });
  } catch (error) {
    dispatch({
      type: "STATE_ERROR_DASHBOARD_VENDOR",
      payload: error,
    });
  }
};

//Get deals from select product
export const addIdPromotionReduxIndex = (index) => async (dispatch) => {
  try {
    dispatch({
      type: "ADD_INDEX_PRODUCT_VENDOR",
      payload: index,
    });
  } catch (error) {
    dispatch({
      type: "STATE_ERROR_DASHBOARD_VENDOR",
      payload: error,
    });
  }
};

//Get deals and celebrityes for product if exist
export const getCelebrityFromProduct = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CELEBRITYES_PRODUCT_VENDOR_INIT",
    });
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.get(
      `${url}/products/vendor/action/get/deals/celebritys/${productId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage.token}`,
        },
      }
    );
    dispatch({
      type: "GET_CELEBRITYES_PRODUCT_VENDOR_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CELEBRITYES_PRODUCT_VENDOR_FAIL",
      payload: error,
    });
  }
};

export const getInfoPromotion =
  (promotionId, productId) => async (dispatch) => {
    try {
      dispatch({
        type: "STATE_INIT_DASHBOARD_VENDOR_INIT",
      });
      const storage = JSON.parse(localStorage.getItem("userInfo"));

      const data = await Promise.all([
        await axios.get(
          `${url}/products/vendor/deals/${promotionId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${storage.token}`,
            },
          }
        ),
        await axios.get(
          `${url}/products/vendor/celebrities/deals/${productId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${storage.token}`,
            },
          }
        ),
      ]);

      dispatch({
        type: "ADD_PROMOTION_INFO_VENDOR_SUCCESS",
        payload: data[0].data.data,
        payload2: data[1].data.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_CELEBRITYES_PRODUCT_VENDOR_FAIL",
        payload: error,
      });
    }
  };

export const getCelebritiesForProduct = () => async (dispatch) => {
  try {
    dispatch({
      type: "STATE_INIT_DASHBOARD_VENDOR_INIT",
    });
  } catch (error) {
    dispatch({
      type: "GET_CELEBRITYES_PRODUCT_VENDOR_FAIL",
      payload: error,
    });
  }
};

export const createPromotionSuggerens = (dataPromotion,productId,celebrityId, callback) => async (dispatch) => {
  try {
    dispatch({
      type: "STATE_INIT_DASHBOARD_VENDOR_INIT",
    });
    console.log(productId,celebrityId)
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.post(`${url}/products/vendor/suggeration/deals/${productId}/celebrity/${celebrityId}`, dataPromotion, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage.token}`,
      },
    });

    callback(data,null);
    dispatch({
      type: "CREATE_PROMOTION_PRODUCT",
    });

  } catch (error) {
    callback(null,error);
    dispatch({
      type: "GET_CELEBRITYES_PRODUCT_VENDOR_FAIL",
      payload: error,
    });
  }
};


export const getOrdersVendor = (page = 1) => async (dispatch) => {
  try {
    // console.log("Entro order")
    dispatch({
      type: "STATE_INIT_DASHBOARD_VENDOR_INIT",
    });
    const storage = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.get(`${url}/ecommerce/order/vendor?page=${page}&limit=15`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage.token}`,
      },
    })

    // console.log("data order >>> ",data)

    dispatch({
      type: "GET_ORDERS_VENDOR",
      payload: data.data
    })

  } catch (error) {
    console.log(error)
    dispatch({
      type: "GET_CELEBRITYES_PRODUCT_VENDOR_FAIL",
      payload: error,
    });
  }
}

export const selectContentDashboard = (content) => async (dispatch) => {
  try {

    dispatch({
      type: "SELECT_CONTENT_DASHBOARD_VENDOR",
      payload: content
    });

    
  } catch (error) {
    dispatch({
      type: "GET_CELEBRITYES_PRODUCT_VENDOR_FAIL",
      payload: error,
    });
  }
}