import React, { useState, useEffect } from "react";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import { getCart } from "../../Actions/Products/ProductsAction";
import { useDispatch, useSelector } from "react-redux";
import { /* Link, */ useNavigate } from "react-router-dom";
import { CreateOrder } from "../../Actions/Products/ProductsAction";
import { toast } from "react-toastify";
import ProgressBarDiscount from "./ProgressBarDiscount";
import ImageNoCart from './assets/In no time-amico.svg';

import "./ShoppingCart.css";
import TextProgressBar from "../ProductDetailSlider/TextProgressBar";
export default function ShoppingCart() {
  // localStorage.setItem("userInfo",JSON.stringify(require("../../utils/user-token.json")));
  const navigate = useNavigate();
  const data = useSelector((state) => state.productDetail);


  const storage = localStorage.getItem("carUser");

  const dispatch = useDispatch();
  const [infoCart, setInfoCart] = useState([]);
  const [reload, setReload] = useState(false);

  const [subtotal, setSubtotal] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [subTotalSinDisc, setSubTotalSinDisc] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [total, setTotal] = useState(0);

  const handleClickCheckout = () => {
    const cart = JSON.parse(localStorage.getItem("carUser"));
    if(cart.length === 0){
      return toast.error("You have no items in your cart")
    }
    localStorage.setItem("order", JSON.stringify(cart));
    const user = localStorage.getItem("userInfo");
    if (!user) {
      localStorage.setItem("check","Cart");
      navigate("/signin");
      return;
    }
    dispatch(
      CreateOrder("Cart", (data, sk, error) => {
        // console.log(error);
        if (error) {
          toast.error(error);
          localStorage.removeItem("userInfo");
          navigate("/signin");
        } else {
          navigate(`/checkout?o=${data.data.idOrder}&s=${sk}`);
        }
      })
    );
  };

  useEffect(() => {
    if (storage !== null) {
      const cart = JSON.parse(storage);
      setInfoCart(cart);
      let subTem = 0;
      let shippingTem = 0;
      let discountMoney = 0;
      let disco = 0;

      for (let i = 0; i < cart.length; i++) {
        const element = cart[i];

        subTem += element.product.promotion
          ? parseFloat(element.amount) *
            parseFloat(element.product.promotion.discountPrice) *
            element.amount
          : parseFloat(element.amount) *
            (element.product.amount -
              (element.product.amount / 100) * element.product.newDiscount) *
            element.amount;

        // shippingTem += parseFloat(
        //   element.product.shipping ? element.product.shipping : 5
        // );

        disco += element.product.promotion
          ? parseFloat(element.amount) * element.amount
          : element.product.amount * element.amount;

        // discountMoney += element.product.promotion
        // ? ((parseFloat(element.amount) * parseFloat(`.${element.product.promotion.discountPrice}`)) * element.amount)
        // : ( ( parseFloat(element.amount) * parseFloat(`.${element.product.newDiscount}`) ) * element.amount);

        discountMoney +=
          parseFloat(element.product.amount) *
          parseFloat(`.${element.product.newDiscount}`) *
          element.amount;
      }

      setSubtotal(subTem);
      setShipping(shippingTem);
      setDiscountPrice(discountMoney);
      setSubTotalSinDisc(disco);
      setTotal(
        (
          parseFloat(disco) -
          parseFloat(discountMoney) +
          parseFloat(shippingTem)
        ).toFixed(2)
      );
    }
  }, [reload]);

  return (
    <div>
      {data.loading ? <div className="se-pre-con"></div> : ""}
      <section className="shopping-cart-main">
        <div className="container">
          <div className="row">

            <div className="col-md-8">
              <h1 className="shopping-cart-hed">Shopping Cart</h1>

              {infoCart && infoCart.length > 0
                ? infoCart.map((pro, i) => {
                    // console.log(pro)
                    return (
                      <ProductCart
                        index={i}
                        setReload={setReload}
                        reload={reload}
                        pro={pro}
                        dispatch={dispatch}
                      />
                    );
                  })
                : (
                  <div>
                    <div style={{display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: "center"}}>
                      <img width={400} src={ImageNoCart} alt="no cart" />
                      <p className="h5">You still don't add products, what are you waiting for? add them now!!</p>
                    </div>
                  </div>

                )}
              {/* <ul className='cart-coupen-box'>
                    <li><input type="text" placeholder='Coupon Code'></input></li>
                    <li> <a href='#'>Apply Coupon</a></li>
                </ul> */}
            </div>
            
            <div className="col-md-4">
              <TextProgressBar />
              <ProgressBarDiscount />
              <section className="shopping-cart-totals">
                <h1 className="cartTotals">Cart Totals</h1>
                <div className="cart-subtotals">
                  <div className="subtotals-left">
                    <h2>Subtotal</h2>
                    <h2>Discount</h2>
                    {/* <h2>Shipping</h2> */}
                  </div>
                  <div className="subtotals-left">
                    <h2>${subTotalSinDisc.toFixed(2)}</h2>
                    <h2
                      style={{ textDecoration: "line-through" }}
                      className="text-danger"
                    >
                      ${discountPrice.toFixed(2)}
                    </h2>
                    {/* <h2 className="text-right">${shipping}</h2> */}
                  </div>
                </div>
                {/* <a href="#" className="calculate-shiping">
                  Calculate Shipping{" "}
                </a> */}
                <div className="total-last-cart">
                  <h2>Total</h2>
                  <h3>${total}</h3>
                </div>
                <ul
                  className="checkout-list"
                  style={{cursor: "pointer"}}
                  onClick={() => handleClickCheckout()}
                >
                  {/* <li className="mb-3">
                    <a href="#">Update Cart</a>
                  </li> */}

                  <li>
                    <a>Proceed to Checkout</a>
                  </li>
                </ul>
              </section>
              
            </div>
          </div>
          <PaymentMethod />
        </div>
      </section>
    </div>
  );
}

function 








ProductCart({ pro, setReload, reload, index, dispatch }) {
  // const [dataProduct, setdataProduct] = useState(pro);
  const navigate = useNavigate();

  const handleChangeCart = (product, action) => {
    const storage = localStorage.getItem("carUser");
    const cart = JSON.parse(storage);
    let cartFinal = [];
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      if (element.product.id === product.product.id) {
        let proTemp = { ...product };
        console.log(proTemp);
        proTemp.amount =
          action === "SUM"
            ? parseFloat(proTemp.amount) + 1
            : parseFloat(proTemp.amount) - 1;
        cartFinal.push(proTemp);
      } else {
        cartFinal.push(element);
      }
    }
    // console.log(cartFinal)
    localStorage.setItem("carUser", JSON.stringify(cartFinal));
    setReload(!reload);
  };

  //   console.log(dataProduct.amount)

  const onClickDeleteProduct = (index) => {
    const storage = localStorage.getItem("carUser");
    let cart = JSON.parse(storage);
    cart.splice(index, 1);

    let discountMedia = 0;
    let discount = 0;

    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      discountMedia += element.product.discount + cart.length * 2;
    }

    discount = discountMedia / cart.length;
    
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      element.product.newDiscount = discount > 65 ? 65 : discount;
    }

    localStorage.setItem("carUser", JSON.stringify(cart));
    setReload(!reload);
    dispatch(getCart());
  };

  const ProductDetailNavigate = (product) => {
    navigate(`/product-detail?product=${product.slug}`);
  }

  // const calculateNewDiscount


  return (
    <div className="cart-detail-table">
      <div className="cart-product">
        <h2 className="cart-list-heding">Product</h2>
        <div style={{cursor: "pointer"}} onClick={() => ProductDetailNavigate(pro.product)} className="cart-quantitity-image">
          <div className="cart-product-img-box">
            <img className="w-100 m-0" src={pro.product.image} alt="cart"></img>
          </div>
          <p style={{ fontSize: "12px" }}>{pro.product.name}</p>
        </div>
      </div>
      <div className="cart-quantity">
        <h2 className="cart-list-heding">quantity</h2>
        <div className="cart-quantity-inner cart-quantitity-1">
          <p>
            $
            {pro.product.promotion
              ? parseFloat(pro.product.promotion.discountPrice).toFixed(2)
              : (
                  pro.product.amount -
                  (pro.product.amount / 100) * pro.product.newDiscount
                ).toFixed(2)}
          </p>
          <div className="qty">
            <span
              onClick={(e) => handleChangeCart(pro, "RES")}
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              -
            </span>
            <input
              value={pro.amount}
              type="number"
              placeholder="Quanlity"
              className="count pt-2"
              name={`cart-${pro.product.id}`}
            />
            <span style={{cursor: "pointer"}} onClick={(e) => handleChangeCart(pro, "SUM")} className="">
              +
            </span>
          </div>
        </div>
      </div>
      <div className="cart-total">
        <h2 className="cart-list-heding">total</h2>
        <div className="cart-total-inner cart-quantitity-1">
          <p>
            $
            {pro.product.promotion
              ? (
                  parseFloat(pro.product.promotion.discountPrice) *
                  parseFloat(pro.amount)
                ).toFixed(2)
              : (
                  parseFloat(
                    pro.product.amount -
                      (pro.product.amount / 100) * pro.product.newDiscount
                  ) * parseFloat(pro.amount)
                ).toFixed(2)}
          </p>
          <a style={{cursor: "pointer"}} onClick={() => onClickDeleteProduct(index)}>
            <img src="images/delete-icon.png" alt="delete"></img>
          </a>
        </div>
      </div>
    </div>
  );
}
