import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { TextField, Divider, Button } from "@mui/material";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { gettingOrderlist } from "../Actions/OrderListAction/OrderListAction";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ShowDetailModal = ({ open, handleActionModal, order }) => {
  // const dispatch = useDispatch();
  // const [page/* , setPage */] = useState("1");
  // const [limit/* , setLimit */] = useState("10");
  // const productData = useSelector((state) => state.customerRedcuer);

  // let { moreDetail: { orderItem = [] } = {} } = productData;
  // console.log({ orderItem });
  // const orderItem = order.orderItem;
  const [orderDetail, setOrderDetail] = useState([]);
  const [shipping, setShipping] = useState("");
  const [loading, setloading] = useState(false);
  const [shippingAdd, setShippingAdd] = useState(false)

  const navigate = useNavigate();

  const handleChange = (e) => {
    setShipping(e.target.value);
  };

  console.log(order);

  const handleClick = async (orderP) => {
    try {
      setloading(true);
      const storage = JSON.parse(localStorage.getItem("userInfo"));
      if (!storage) return navigate('/');
      const { data } = await axios.put(
        `https://fangway.com/ecommerce/order/${orderP.generalId}/track`,
        {
          trackingNumber: shipping,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storage.token}`,
          },
        }
      );
      if (data.statusCode === 200) {
        toast.success("Add shipping code");
        setShippingAdd(true);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error in network");
    }
  };

  useEffect(() => {
    if (order) {
      setOrderDetail(order.orderItem);
      if (order && order.tracking && order.tracking.id) {
        setShipping(order.tracking.id);
        setShippingAdd(true);
      }else{
        setShipping("");
        setShippingAdd(false);
      }
    }
  }, [order]);

  // useEffect(async () => {
  //   await dispatch(gettingOrderlist(page, limit));
  // }, []);
  return (
    <>
      <div>
        {loading ? <div className="se-pre-con"></div> : ""}
        <Modal
          open={open}
          onClose={() => handleActionModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {/* <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
          </Box> */}
          <Box sx={style}>
            <table className="table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Detail</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {orderDetail &&
                  orderDetail.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span className="ch-table-img">
                          <img
                            src={item.infoProduct.image}
                            alt="whatch-large"
                          ></img>
                        </span>
                      </td>
                      <td>{item.infoProduct.name}</td>
                      <td>{item.infoProduct.description}</td>
                      <td>
                        {item.infoProduct.amount}
                        {item.infoProduct.currency}
                      </td>
                    </tr>
                  ))}
                {/* <tr>
                  <td>
                    <span className="ch-tables-img">
                      <img
                        src="images/img_2.png"
                        alt="whatch-large"
                        // style={{ width: "70px", height: "70px" }}
                      ></img>
                    </span>
                  </td>
                  <td>Awesome Headphone</td>
                  <td>randomised words even slightly believable</td>
                  <td>$16.00</td>
                  <td className="completed-text">Completed</td>
                  <td>
                    <button className="edit-history">
                      <i className="fa fa-edit"></i>
                    </button>
                    <button className="edit-history ml-3">
                      <i className="fa fa-trash"></i>
                    </button>
                  </td>
                </tr> */}
              </tbody>
            </table>
            {
            shippingAdd === true ? (
              <p>Shipping code: {shipping}</p>
            ) : (
              <Box>
                <Divider>Add shipping code</Divider>
                <Box>
                  <TextField
                    id="outlined-basic"
                    label="Shipping id"
                    variant="outlined"
                    size="small"
                    sx={{
                      marginRight: 1,
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                  <Button
                    onClick={() => handleClick(order)}
                    size="large"
                    variant="outlined"
                  >
                    Add code
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ShowDetailModal;
