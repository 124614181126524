import axios from "axios";
import { toast } from "react-toastify";

export const gettingAuthProfile = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: "GET_AUTH_PROFILE_INIT",
    });

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    console.log("userInfo::>", userInfo);

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `https://fangway.com/users/auth/profile`,
      {},
      config
    );

    console.log("data:::>", JSON.stringify(data.data));

    // if (data.statusCode == "200" || data.statusCode == "201") {
    //   toast.success("Getting Profile Successfully");
    // }
    dispatch({
      type: "GET_AUTH_PROFILE_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);

    dispatch({
      type: "GET_AUTH_PROFILE_FAIL",
      payload: error,
    });
  }
};
