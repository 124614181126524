import axios from "axios";
import { toast } from "react-toastify";

//---------------------- Vendor Verification---------------------//
export const vendorVerification =
  (
    name,
    sellingLicense,
    trademarkRegistration,
    trackRecord,
    operationalHistory,
    businessRegistration,
    callback
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: "VENDOR_VERIFICATION_INIT",
      });
      const user = JSON.parse(localStorage.getItem("userInfo"));

      console.log("first::>", user);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.post(
        "https://fangway.com/users/vendor/verification",
        {
          name,
          sellingLicense,
          trademarkRegistration,
          trackRecord,
          operationalHistory,
          businessRegistration,
        },
        config
      );

      if (data.statusCode == "200") {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      if (data.statusCode == "200") {
        callback();
      }
      dispatch({
        type: "VENDOR_VERIFICATION_SUCCESS",
        payload: data,
      });
    } catch (error) {
      toast.error(error.meesage);
      dispatch({
        type: "VENDOR_VERIFICATION_FAIL",
        payload: error,
      });
    }
  };
