import React,{/* useState, */ useEffect} from "react";
import ContentTraffic from "./components/ContentTraffic";
import SelectCelebrity from "./components/SelectCelebrity";
import "./Traffic.css";
import { useDispatch, useSelector } from 'react-redux'

import { addIdPromotionRedux } from '../../Actions/DashboardVendor/DashboarVendor'

export default function Traffic({dataDealsProduct,productId}) {

  // const [data, setData] = useState(null);
  const reduxSelectedVendorDash = useSelector((state) => state.dashboardVendor);

  const dispatch = useDispatch();

  console.log("dataDealsProduct >>>", dataDealsProduct) 

  useEffect(() => {
    if(dataDealsProduct && dataDealsProduct.data && dataDealsProduct.data.length > 0){
      dataDealsProduct.data.map((p,i) => {
        console.log("data map >>> ",p)
        if(i === reduxSelectedVendorDash.index){
          dispatch(addIdPromotionRedux(p.id))
        }
      }) 
    }
  }, [dataDealsProduct,reduxSelectedVendorDash.index])

  return (
    <div>
      <p className="traffic__vendor-title-content">
        Current Celebrity Exposure
      </p>
      <div className="traffic__vendor">
        <div className="traffic__vendor-content-left">
          <div className="traffic__vendor-responsive-section-celebrieties">
            <select className="traffic__vendor-responsive-section-celebrieties-select" id="title" name="title">
              <option value="" selected>
                Steve Alvarado
              </option>
              <option value="" selected>
                Steve Alvarado
              </option>
              <option value="" selected>
                Steve Alvarado
              </option>
              <option value="" selected>
                Steve Alvarado
              </option>
              <option value="" selected>
                Steve Alvarado
              </option>
            </select>
          </div>
          <div className="traffic__vendor-content-left-content">
            {
              dataDealsProduct && dataDealsProduct.data && dataDealsProduct.data.map((deal,i) => {
                return <SelectCelebrity index={i} deal={deal} select={i === reduxSelectedVendorDash.index ? "select__celebrity-vendor-selection" : ""} />
              })
            }
            
            {/* <SelectCelebrity select={"select__celebrity-vendor-selection"} />
            <SelectCelebrity />
            <SelectCelebrity />
            <SelectCelebrity />
            <SelectCelebrity />
            <SelectCelebrity />
            <SelectCelebrity />
            <SelectCelebrity /> */}
          </div>
        </div>
        <div className="traffic__vendor-content-rigth">
          <div>
            <ContentTraffic productId={productId} />
          </div>
        </div>
      </div>
    </div>
  );
}
