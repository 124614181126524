import React from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import SignupLink from '../../components/SignupLink/SignupLink'
import ContentSearch from './ContentSearch'

export default function SearchPageProducts() {
  return (
    <div>
        <Header />
        <ContentSearch />
        <SignupLink />
        <Footer />
    </div>
  )
}
