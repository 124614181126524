import React from 'react';

export default function CartBanner({text}) {
 
  return <div>

    <section className='detailbanner'>
      <div className='container'>
        <h1 className='text-center'>{text}</h1>
      </div>
    </section>

  </div>
}
 