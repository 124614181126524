let initialState = {
    state: {},
    loading: false,
    searchProducts: [],
  };
  
  export const ProductSearchReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SEARCH_PRODUCT_GET":
        return { loading: true };
      case "SEARCH_PRODUCT_SUCCESS":
        return { loading: false, searchProducts: action.payload, ...state };
      case "SEARCH_PRODUCT_FAIL":
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  