import React,{useState, useEffect} from 'react';
import CelebrityEarning from '../../../components/Dashboards/Celebrity/CelebrityEarning/CelebrityEarning';
import Sidebar from '../../../components/Dashboards/Elements/Sidebar/Sidebar';

import { verifyTokenUser } from '../../../utils/Auth';
import { useNavigate } from 'react-router-dom';

export default function CelebrityMyEarning() {


  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  
  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])

  return (<div>
      {loadingSesion ? <div className="se-pre-con"></div> : ""}
       <CelebrityEarning/>
       <Sidebar list={true} />
  </div>)
}
