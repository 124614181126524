import React from 'react';
import ProfileSettings from '../../../components/Dashboards/Celebrity/ProfileSettings/ProfileSettings';
import CustomerSidebar from '../../../components/Dashboards/Elements/CustomerSidebar/CustomerSidebar';


export default function CustomerSettings() {
    return (<div>

        <section className='dashboard_container2'>
        <span className="menuIcon"></span>
            <div className='customer-history-main'>
                <div className='customer-history-inner'>
                <ProfileSettings />
                </div>
            </div>
        </section>
       
        <CustomerSidebar />
    </div>)
}
