import React, { useState, useEffect } from "react";
import {
  MdHome,
  MdOtherHouses,
  MdOutlineMobileFriendly,
  MdOutlineSendToMobile,
} from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { BsBoxSeam } from "react-icons/bs";

import "./TrackOrder.css";

export default function TrackOrder({ detailOrder, stateOrder }) {

    const [stateShipping, setStateShipping] = useState({
        processed: true,
        shipped: false,
        route: false,
        arrive: false,
    });

  useEffect(() => {
    console.log("stateOrder >>> ",stateOrder)
    if (stateOrder) {
        if(stateOrder === "Processing"){
            setStateShipping({
                processed: true,
                shipped: false,
                route: false,
                arrive: false,
            })
        }else if(stateOrder === "Preparation"){
            setStateShipping({
                processed: true,
                shipped: true,
                route: false,
                arrive: false,
            })
        }else if(stateOrder === "Send"){
            setStateShipping({
                processed: true,
                shipped: true,
                route: true,
                arrive: false,
            })
        }else if(stateOrder === "Delivered"){
            setStateShipping({
                processed: true,
                shipped: true,
                route: true,
                arrive: true,
            })
        }
    }
  }, [stateOrder]);

  return (
    <div>
      <div className="container mt-4">
        <h1 className="Track-order-hed">Track Your Order</h1>
        {/* <form className="track-form">
          <div className="row w-100 mr-0 ml-0">
            <div className="col-md-5">
              <div className="form-group">
                <label>Order ID</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Type"
                ></input>
              </div>
            </div>
            <div className="col-md-5">
              <div className="form-group">
                <label>Billing Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                ></input>
              </div>
            </div>
            <div className="col-md-2">
              <button type="button" className="btn track-btn">
                Track
              </button>
            </div>
          </div>
        </form> */}
        <section className="tracking-detail-box">
          <h1 className="tracking-detail-hed">
            <img
              src="images/tracking-list-icon.png"
              alt="listicon"
              className="mr-3"
            ></img>
            Tracking Details
          </h1>
          <h2>Estimated delivery between 25 January-26 January</h2>
          <div className="tracking-route">
            <div className="row no-gutters">
              <div className="col-12 hh-grayBox p-0">
                <div className="row justify-content-start">
                  <div className={`order-tracking ${stateShipping.processed === true ? "" : "completed"}`}>
                    <span className={`is-complete`} >
                      {/* <img src="images/order-online.png" alt="order"></img> */}
                      <MdOutlineMobileFriendly className={`shiping-icons ${stateShipping.processed === true ? "shipping-icon-complete" : "shipping-icon-pending"} `} />
                    </span>
                    <p>Processed</p>
                  </div>
                  <div className={`order-tracking ${stateShipping.shipped === true ? "" : "completed"}`}>
                    <span className={`is-complete`} >
                      {/* <img src="images/return.png" alt="order"></img> */}
                      <BsBoxSeam className={`shiping-icons ${stateShipping.shipped === true ? "shipping-icon-complete" : "shipping-icon-pending"} `}  />
                    </span>
                    <p>Shipped</p>
                  </div>
                  <div className={`order-tracking ${stateShipping.route === true ? "" : "completed"}`}>
                    <span className={`is-complete`} >
                      {/* <img src="images/on-route-icon.png" alt="order"></img> */}
                      <FaShippingFast className={`shiping-icons ${stateShipping.route === true ? "shipping-icon-complete" : "shipping-icon-pending"} `} />
                    </span>
                    <p>On Route</p>
                  </div>
                  <div className={`order-tracking ${stateShipping.arrive === true ? "" : "completed"}`}>
                    <span className={`is-complete`} >
                      {/* <img src="images/arrive-icon.png" alt="order"></img> */}
                      <MdHome className={`shiping-icons ${stateShipping.arrive === true ? "shipping-icon-complete" : "shipping-icon-pending"} `} />
                    </span>
                    <p>Arrive</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1 className="tracking-detail-hed mt-5 mb-4">
              <img
                src="images/tracking-list-icon.png"
                alt="listicon"
                className="mr-3"
              ></img>
              Order Detail
            </h1>
            <table className="table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Detail</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {detailOrder &&
                  detailOrder.ordersByVendors &&
                  detailOrder.ordersByVendors.orderItem.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span className="ch-table-img">
                          <img
                            src={item.product.image}
                            alt="whatch-large"
                          ></img>
                        </span>
                      </td>
                      <td>{item.product.name}</td>
                      <td>{item.product.description}</td>
                      <td>
                        {item.amount.toFixed(2)}{" "}
                        {item.product.currency}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <h2 className="float-right">
              Total: ${" "}
              {detailOrder &&
                detailOrder.ordersByVendors &&
                detailOrder.ordersByVendors.totalOrderVendor}{" "}
            </h2>
          </div>
        </section>
      </div>
    </div>
  );
}
