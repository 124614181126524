import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TrackOrder from "../../components/TrackOrder/TrackOrder";
import UserSesionSection from "../../components/UserSesionSection/UserSesionSection";
import { MdReply } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import { verifyTokenUser } from "../../utils/Auth";
import { getOrderDetail } from "../../Actions/OrderListAction/OrderListAction";

import axios from "axios";

export default function CustomerOrderTraking() {
  const navigate = useNavigate();
  let [searchParams /* , _  */] = useSearchParams();
  let idGeneral = searchParams.get("id");
  let idOrder = searchParams.get("order");

  const dispatch = useDispatch();
  const selectorOrderRedux = useSelector((state) => state.getOrderListReducer);

  console.log("idGeneral >>> ", idGeneral);
  console.log("idOrder >>> ", idOrder);

  const [loadingSesion, setLoadingSesion] = useState(false);
  const [detailOrder, setDetailOrder] = useState();
  const [stateOrder, setStateOrder] = useState("")

  useEffect(() => {
    dispatch(getOrderDetail(idGeneral, idOrder));
  }, [idGeneral, idOrder]);

  useEffect(() => {
    const getStateOrder = async (trackingID) => {
      try {
        const user = JSON.parse(localStorage.getItem("userInfo"));
        if (!user) return;
        const config = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        };

        const { data } = await axios.get(
          `https://fangway.com/ecommerce/shipment/${trackingID}/track`,
          config
        );
        if(data.statusCode === 200){
          setStateOrder(data.data.status);
        }
        // console.log(data)

      } catch (error) {}
    };
    if (selectorOrderRedux.orderDetail) {
      setDetailOrder(selectorOrderRedux.orderDetail.data);
      // console.log(selectorOrderRedux.orderDetail.data);
      if (
        selectorOrderRedux.orderDetail.data &&
        selectorOrderRedux.orderDetail.data.ordersByVendors &&
        selectorOrderRedux.orderDetail.data.ordersByVendors.tracking &&
        selectorOrderRedux.orderDetail.data.ordersByVendors.tracking.id
      ) {
        getStateOrder(
          selectorOrderRedux.orderDetail.data.ordersByVendors.tracking.id
        );
      }
    }
  }, [selectorOrderRedux.orderDetail]);

  useEffect(() => {
    const very = async () => {
      const data = await verifyTokenUser();
      if (!data.token) return navigate("/");
      if (!data.sesion) return navigate("/signin");
      setLoadingSesion(false);
    };
    setLoadingSesion(true);
    very();
  }, []);

  if (loadingSesion && selectorOrderRedux.loading) {
    return <div className="se-pre-con"></div>;
  }

  return (
    <div>
      <div>
        <div className="dashboard__navegacion">
          <div className="dashboard__navegacion__user">
            <UserSesionSection />
          </div>
          <div className="dashboard__navegacion-conten-nav">
            <section className="myearning-tabs-main">
              <div>
                {/* <!-- Nav tabs --> */}
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <Link to="/customer-order-history" className="nav-link">
                      <MdReply className="mr-2 mb-1" />
                      Back
                    </Link>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="myearning-tabs-content">
        <TrackOrder stateOrder={stateOrder} detailOrder={detailOrder} />
      </div>
    </div>
  );
}
