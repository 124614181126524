import React,{useState, useEffect} from 'react';
import CartBanner from '../../components/CartBanner/CartBanner';
// import CheckoutBilling from '../../components/CheckoutBilling/CheckoutBilling';
import PaymentElementStripe from '../../components/CheckoutBilling/PaymentElementStripe';
import Footer from '../../components/Footer/Footer';
import SignupLink from '../../components/SignupLink/SignupLink';

import { useSearchParams, useNavigate } from "react-router-dom";
import { verifyTokenUser } from '../../utils/Auth';

export default function Checkout() {

  let [searchParams, _ ] = useSearchParams();
  let orderId = searchParams.get("o");
  let s = searchParams.get("s");

  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  
  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])

  return (
  <div>
    {loadingSesion ? <div className="se-pre-con"></div> : ""}
    <CartBanner text={"Checkout"} />
    <PaymentElementStripe orderId={orderId} s={s} />
    <SignupLink/>
    <Footer/>
  </div>)
}
