import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";

import { useDispatch, useSelector } from "react-redux";
import { getOrdersVendor } from "../../Actions/DashboardVendor/DashboarVendor";

import LoaderGif from "../../Assets/preloader-4emi.gif";

import { MdSdCardAlert } from "react-icons/md";

import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";

import ShowDetailOrderReuserModal from "../../Modals/ShowDetailOrderReuserModal";

import PaginationMaterial from "../../components/Pagination/PaginationMaterial";
import { Auth } from "../../utils/Auth";
import axios from "axios";
import { toast } from "react-toastify";

import moment from "moment";

export default function Orders() {
  const dispatch = useDispatch();
  const selectorReduxDashboard = useSelector((state) => state.dashboardVendor);

  const [dataOrders, setDataOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setloading] = useState(false);
  const [reload, setReload] = useState();

  const [open, setOpen] = useState(false);
  const handleActionModal = (action) => setOpen(action);
  const [detailOrder, setDetailOrder] = useState({});

  const [loadingView, setLoadingView] = useState(false);

  const handleChange = (_, value) => {
    setPage(value);
  };

  const putStateCancel = async (item) => {
    try {
      // toast.error("aaaaa")
      console.log(item);
      setLoadingView(true);
      const { data } = await axios.put(
        `http://localhost:3003/ecommerce/order/${item.generalId}/change/Rejected/state/${item.id}`,
        {},
        { headers: Auth() }
      );
      console.log(data);
      if (data.statusCode === 200) {
        setLoadingView(false);
        setReload(!reload);
      }
    } catch (error) {
      setLoadingView(false);
      toast.error("Failed");
    }
  };

  useEffect(() => {
    setloading(true);
    dispatch(getOrdersVendor(page));
  }, [page, reload]);

  useEffect(() => {
    if (selectorReduxDashboard && selectorReduxDashboard.ordersVendor) {
      setDataOrders(selectorReduxDashboard.ordersVendor.docs);
      setTotalPages(selectorReduxDashboard.ordersVendor.totalPages);
      setloading(false);
    }
  }, [selectorReduxDashboard.ordersVendor]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading === false && loadingView === false ? (
        <div className="customer-history-inner">
          <h2 className="cutomer-section-hed">Orders List</h2>
          <div className="customer-history-table-main table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Products</th>
                  <th>State</th>
                  <th>Total</th>
                  <th>Create At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {dataOrders &&
                  dataOrders.map((order) => {
                    return (
                      <RowTableOrder
                        handleActionModal={handleActionModal}
                        setDetailOrder={setDetailOrder}
                        order={order}
                        putStateCancel={putStateCancel}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <PaginationMaterial
              totalPages={totalPages}
              page={page}
              handleChange={handleChange}
            />
            {/* <Pagination
              count={totalPages}
              page={page}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              size="large"
            /> */}
          </div>
        </div>
      ) : (
        <div
          style={{
            height: window.innerHeight - 300,
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "0px",
              top: "0px",
              width: "100%",
              height: "100%",
              // z-index: 999999999;
              backgroundImage: `url(${LoaderGif})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          ></div>
        </div>
      )}
      <ShowDetailOrderReuserModal
        open={open}
        handleActionModal={handleActionModal}
        order={detailOrder}
      />
    </div>
  );
}

function RowTableOrder({
  handleActionModal,
  setDetailOrder,
  order,
  putStateCancel,
}) {
  return (
    <tr
      className="cursor-pointer"
      //   onClick={() => handleActiveTraffics(product.id)}
      key={order.id}
    >
      <td>{order.id}</td>
      <td
        style={{
          display: "flex",
          justifyContent: "left",
        }}
      >
        <AvatarGroup total={order.orderItem.length}>
          {order.orderItem.map((product) => {
            return (
              <Avatar
                alt={product.infoProduct.slug}
                src={product.infoProduct.image}
              />
            );
          })}
        </AvatarGroup>
      </td>
      <td
        style={{
          fontWeight: "bold",
          color:
            order.state === "Processing"
              ? "rgb(139,179,249)"
              : order.state === "Accepted" ||
                order.state === "Preparation" ||
                order.state === "Send" ||
                order.state === "Delivered"
              ? "rgb(83,166,81)"
              : order.state === "Canceled" ||
                order.state === "Aborted" ||
                order.state === "Rejected"
              ? "red"
              : "black",
        }}
      >
        {" "}
        {order.state}{" "}
      </td>
      <td>$ {order.totalOrderVendor}</td>
      <td>{moment(order.createdAt).format("MM-DD-YYYY")}</td>

      <td>
        {order.state === "Waiting" ? (
          <button
            type="button"
            className="btn btn-outline-success mr-2"
            onClick={() => putStateCancel(order)}
          >
            Acept Order
          </button>
        ) : null}

        <button
          type="button"
          onClick={() => {
            setDetailOrder(order);
            handleActionModal(true);
          }}
          className="btn btn-outline-warning"
        >
          Detail <MdSdCardAlert />
        </button>
      </td>
    </tr>
  );
}
