import React,{useState, useEffect} from 'react';
import Footer from "../../components/Footer/Footer";
import SignupLink from "../../components/SignupLink/SignupLink";
import Header from "../../components/Header/Header";
import SelectCelebrity from "../../components/SelectCelebrity/SelectCelebrity";

import { verifyTokenUser } from '../../utils/Auth';
import { useNavigate } from 'react-router-dom';

export default function CelebritySelecting() {

  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  
  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])

  return (
    <div>
      {loadingSesion ? <div className="se-pre-con"></div> : ""}
      {/* <Header /> */}
      <SelectCelebrity />
      {/* <SignupLink />
      <Footer /> */}
    </div>
  );
}
