import React from 'react';
import { Link } from 'react-router-dom';
export default function PaymentMethod() {
    return <div>
        <div className='payment-method-main'>
            <ul className='payment-method-list'>
                <li>
                    <Link to="/world-wide-shipping">
                        <img src='images/worldwide.png' alt='world'></img>
                        <p>Worldwide
                            Shipping</p>
                    </Link>
                </li>
                <li>
                    <Link to="/order-online-service">
                        <img src='images/order-online.png' alt='world'></img>
                        <p>Order Online Service</p>
                    </Link>
                </li>
                <li>
                    <Link to="/make-payment">
                        <img src='images/payment.png' alt='world'></img>
                        <p>Make Payment
                        </p>
                    </Link>
                </li>
                <li>
                    <Link to="/return-days">
                        <img src='images/return.png' alt='world'></img>
                        <p>Return Days 30</p>
                    </Link>
                </li>


            </ul>
        </div>

    </div>
}
