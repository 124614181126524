export const celebrityReducer = (state = {}, action) => {
  console.log("data", action.payload);

  switch (action.type) {
    //------------ Get Celebrity --------------//
    case "GET_CELEBRITY_INIT":
      return { loading: true };
    case "GET_CELEBRITY_SUCCESS":
      return { loading: false, celebrity: action.payload };
    case "GET_CELEBRITY_FAIL":
      return { loading: false, error: action.payload };

    //------------ Upload Celebrity --------------//
    case "UPLOAD_CELEBRITY_INIT":
      return { loading: true };
    case "UPLOAD_CELEBRITY_SUCCESS":
      return { loading: false, uploadCelebrity: action.payload };
    case "UPLOAD_CELEBRITY_FAIL":
      return { loading: false, error: action.payload };

    //------------ Get Celebrity Setting --------------//
    case "GET_CELEBRITY_SETTING_INIT":
      return { loading: true };
    case "GET_CELEBRITY_SETTING_SUCCESS":
      return { loading: false, uploadCelebrity: action.payload };
    case "GET_CELEBRITY_SETTING_FAIL":
      return { loading: false, error: action.payload };

    //------------ Get Celebrity EARNING --------------//
    case "GET_CELEBRITY_EARNING_INIT":
      return { loading: true };
    case "GET_CELEBRITY_EARNING_SUCCESS":
      return { loading: false, celebrityEarning: action.payload };
    case "GET_CELEBRITY_EARNING_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
