import React from "react";
import "./ValidationCelebrities.css";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";
import SignupLink from "../../../components/SignupLink/SignupLink";
import ContentCalebrities from "./ContentCalebrities";

export default function ValidationCelebrities() {
  return (
    <div>
      <Header />
      <ContentCalebrities />
      <SignupLink />
      <Footer />
    </div>
  );
}
