// import { ImportExport } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchProducts } from "../../Actions/Products/ProducSearchAction";
import CardProductSearch from "./CardProductSearch";


export default function ContentSearch() {
  const dispatch = useDispatch();
  const productsRedux = useSelector((state) => state.ProductSearch);
  let [searchParams/* , _  */] = useSearchParams();
  let searchUrl = searchParams.get("search");
  let categoryUrl = searchParams.get("category");
  const [dataSearch, setDataSearch] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (productsRedux.searchProducts) {
      console.log(productsRedux.searchProducts);
      setDataSearch(productsRedux.searchProducts);
      setLoading(false);
    }
  }, [productsRedux.searchProducts]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      searchProducts(categoryUrl === "" ? "todo" : categoryUrl, searchUrl)
    );
  }, [searchUrl, categoryUrl]);

  console.log(dataSearch);

  return (
    <>
      {loading ? <div className="se-pre-con"></div> : ""}
      <div className="container">
        <h4 className="elctornic-text pt-3">Results</h4>
        <div className="row">
            {dataSearch &&
                dataSearch.map((el, index) => {
                  if(
                    !el.slug || 
                    !el.imagen ||
                    !el.name ||
                    !el.amount /* ||
                    !el.infoProduct.discount  */
                    ) return null;
                  return (
                    <div className="col-lg-3">
                      <CardProductSearch index={index} data={el} />
                    </div>
                  );
                })}
        </div>
      </div>
      {/* <React.Fragment>
      <section className="container-fluid product-inner-main">
        <div className=" product_header">
          
        </div>
        <section className="container-fluid product-inner-main p-0">
            <div className="mm-procuct-list">
              {dataSearch &&
                dataSearch.map((el, index) => {
                  return <CardProductSearch data={el} />;
                })}
            </div>
          </section>
      </section>
    </React.Fragment> */}
    </>
  );
}

