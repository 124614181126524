import axios from "axios";
import { useNavigate } from "react-router-dom";

export function Auth() {
  // return authorization header with jwt token
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // if (user && user.token) {
  // return { 'Authorization': 'Bearer ' ${user.token}
  // } else {
  //     return {};
  // }
  if (!userInfo) {
    return {
      Authorization: ``,
    };
  }

  return {
    Authorization: `Bearer ${userInfo.token}`,
  };
}

export async function verifyTokenUser() {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (!userInfo) return { token: false, sesion: false };
  return await verifySesionServer(userInfo);
}

export async function verifySesionServer(user) {
  return axios
    .get("https://fangway.com/users/settings", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((data) => {
      return { token: true, sesion: true };
    })
    .catch((err) => {
      localStorage.removeItem("userInfo");
      return { token: true, sesion: false };
    });
  
}
