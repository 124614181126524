import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ProductDetailsCount,
  CreateOrder,
} from "../../Actions/Products/ProductsAction";
import { useDispatch } from "react-redux";

export default function ProductsCard({ data }) {
  // console.log(data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = (e, slug) => {
    // localStorage.setItem("product-detail", JSON.stringify(slug));
    navigate(`/product-detail?product=${slug}`);
  };

  const [loadingOrder, setLoadingOrder] = useState(false);

  const handleClickBuy = (e,slug) => {
    e.preventDefault();
    const userLocal = localStorage.getItem("userInfo");
    if (!userLocal) {
      localStorage.setItem("check","One");
      setLoadingOrder(true);
      dispatch(
        ProductDetailsCount(slug, (product) => {
          localStorage.setItem(
            "order",
            JSON.stringify([
              {
                product: product,
                amount: 1,
              },
            ])
          );
          setLoadingOrder(false);
          navigate("/signin");
        })
      );
    }
    setLoadingOrder(true);
    dispatch(
      ProductDetailsCount(slug, (product) => {
        localStorage.setItem(
          "order",
          JSON.stringify([
            {
              product: product,
              amount: 1,
            },
          ])
        );
        dispatch(
          CreateOrder("One", (data, sk, err) => {
            console.log({
              data,
              sk,
              err,
            });
            if (err) {
              toast.error(err);
            } else {
              navigate(`/checkout?o=${data.data.idOrder}&s=${sk}`);
            }
            setLoadingOrder(false);
          })
        );
      })
    );
  };

  return (
    <React.Fragment>
      {loadingOrder ? <div className="se-pre-con"></div> : ""}
      <div key={data.slug} className="product-grid4 text-left">
        <div
          onClick={(e) => {
            handleClick(e, data.slug);
          }}
        >
          <div className="product-image4">
            <img src={data.imagePath} alt="hellow" className="img-fluid" />
          </div>
          <div className="product-content">
            <h3 className="title">
              <a href="#">{data.title}</a>
            </h3>
            <ul className="rating">
              {Array(5)
                .fill(5)
                .map((_, i) => {
                  return <li key={i} className="fa fa-star"></li>;
                })}
            </ul>

            <div className="price">
              <span>${data.price}</span>${data.discountedPrice}
            </div>

            <div className="discount-off">
              <span>
                {" "}
                <img
                  src="images/fangwaysmall-logo.png"
                  alt="logo"
                  className="img-fluid"
                />
              </span>
              <div>
                <h4>30% OFF</h4>
                <p>
                  with <strong>Fangway</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ borderRadius: "50%!important" }}
          onClick={(e) => handleClickBuy(e,data.slug)}
          className="productdatabtn mb-3 ml-3 float-left "
        >
          <a className="btn btn-warning">Buy Now</a>
        </div>
      </div>
    </React.Fragment>
  );
}
