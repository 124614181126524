import React,{useState, useEffect} from 'react';
import Footer from '../../components/Footer/Footer';
import SignupLink from '../../components/SignupLink/SignupLink';
import Header from '../../components/Header/Header';
import ThankyouContent from '../../components/ThankyouContent/ThankyouContent';

import { useSearchParams, useNavigate } from "react-router-dom";
import { verifyTokenUser } from '../../utils/Auth';

export default function ThankYou() {

  let [searchParams/* , _  */] = useSearchParams();
  let orderId = searchParams.get("o");

  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  
  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])

  return (<div>
        {loadingSesion ? <div className="se-pre-con"></div> : ""}
        <Header />
        <ThankyouContent orderId={orderId} />
        <SignupLink/>
        <Footer />
  </div>)
} 
