import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogoutAction } from "../../../../Actions/LoginActions/LoginAction";
// import CharityList from "../CharityList/CharityList";
import "../Sidebar/Sidebar.css";
export default function Sidebar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let uploadImage = userInfo && userInfo.profile && userInfo.profile.imagePath;
  let userName = userInfo && userInfo.profile && userInfo.profile.firstName;

  let getAuth = JSON.parse(localStorage.getItem("getAuth"));

  let profile = (getAuth && getAuth.imagePath) || "";
  let name = (getAuth && getAuth.firstName) || "";

  console.log("profileeeeeeee::>", profile);

  const [profileImage, setProfileImage] = useState(profile);
  const [firstName, setFirstName] = useState(name);

  useEffect(() => {
    if (getAuth) {
      setProfileImage(profile);
      setFirstName(name);
    } else {
      setProfileImage(uploadImage);
      setFirstName(userName);
    }
  }, [userInfo]);

  console.log("uploadFileReducer::>", profile);

  const handleLogout = async () => {
    setIsLoading(true);
    await dispatch(userLogoutAction());
    navigate("/signin");
    setIsLoading(true);
  };
  return (
    <div>
      {isLoading ? <div className="se-pre-con"></div> : ""}

      <div className="sidebar-main">
        <span className="closeNav"></span>
        <div className="sidebar_main-inner">
          <div className="db-profile">
            <span>
              {/* <img src="images/Elipse 1.png" alt="profiledb"></img> */}
              {profileImage ? (
                <img src={profileImage} alt="Profileimage" />
              ) : (
                <Avatar alt="Remy Sharp" sx={{ width: 100, height: 100 }} />
              )}
            </span>
            <div className="db-proname-btn">
              <h6>{firstName}</h6>
              {/* <p>Member science Jan 2021</p> */}
              <button className="btn logout" onClick={handleLogout}>
                Logout{" "}
                <i>
                  <img src="images/eye.svg" alt="eyelogouticon"></img>
                </i>
              </button>

            </div>
          </div>
          {/*<p className="sidebar-para">*/}
          {/*  Proin ex ipsum, facilisis id tincidunt sed, vulputate in lacus.*/}
          {/*  Donec pharetra faucibus leo, vitae vestibulum leo scelerisque eu.{" "}*/}
          {/*</p>*/}
          {/*<h5 className="sb-my-link-text">My Link</h5>*/}
          {/*<div className="support-link">*/}
          {/*  <a href="#" className="fw-site-link">*/}
          {/*    http://fangway.org/support/user/benjamin2122/*/}
          {/*  </a>*/}
          {/*</div>*/}
          {/*{props.list && <CharityList />}*/}
        </div>
      </div>
    </div>
  );
}
