import React,{useState, useEffect} from 'react';
import DbContent from "../../components/Dashboards/Elements/DbContent/DbContent";
import Sidebar from "../../components/Dashboards/Elements/Sidebar/Sidebar";

import { verifyTokenUser } from '../../utils/Auth';
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {

  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  
  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])

  return (
    <div>
      {loadingSesion ? <div className="se-pre-con"></div> : ""}
      <DbContent />
      <Sidebar />
    </div>
  );
}
