import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWishlist,
  gettingWishlist,
} from "../../../../Actions/WishListAction/WishListAction";
import ".//FavouriteProductHistory.css";
export default function FavouriteProductHistory() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [page/* , setPage */] = useState("1");
  const [limit/* , setLimit */] = useState("10");

  const whishlistInfo = useSelector((state) => state.getWishListReducer);

  const { wishList: { docs = [] } = {} } = whishlistInfo;
  console.log("whishlist:>", docs);

  useEffect(async () => {
    setIsLoading(true);
    await dispatch(gettingWishlist(page, limit));
    setIsLoading(false);
  }, [gettingWishlist]);

  const handleDeleteWishList = async (item, index) => {
    console.log("index", index);
    setIsLoading(true);
    await dispatch(deleteWishlist(item.productInfo.id));
    setIsLoading(true);
  };
  return (
    <div>
      {isLoading ? <div className="se-pre-con"></div> : ""}

      <section className="dashboard_container2">
        <span className="menuIcon"></span>
        <div className="customer-history-main">
          <div className="customer-history-inner">
            <h2 className="cutomer-section-hed">Favourite Products</h2>
            <div className="customer-history-table-main table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Product Name</th>
                    <th>Detail</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {docs.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span className="ch-table-img">
                          <img
                            src={item.productInfo.image}
                            alt="whatch-large"
                          ></img>
                        </span>
                      </td>
                      <td>{item.productInfo.name}</td>
                      <td>{item.productInfo.category}</td>
                      <td>${item.productInfo.amount}</td>
                      <td>
                        {/* <button className="edit-history">
                            <i className="fa fa-edit"></i>
                          </button> */}
                        <button
                          className="edit-history ml-3"
                          onClick={() => handleDeleteWishList(item, index)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}

                  {/* <tr>
                    <td>
                      <span className="ch-tables-img">
                        <img src="images/img_2.png" alt="whatch-large"></img>
                      </span>
                    </td>
                    <td>Awesome Headphone</td>
                    <td>randomised words even slightly believable</td>
                    <td>$16.00</td>
                    <td className="completed-text">Completed</td>
                    <td>
                      <button className="edit-history">
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="edit-history ml-3">
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td>
                      <span className="ch-table-img">
                        <img src="images/recent2.png" alt="whatch-large"></img>
                      </span>
                    </td>
                    <td>Different colors Clock</td>
                    <td>randomised words even slightly believable</td>
                    <td>$16.00</td>
                    <td className="not-complete">Not Completed</td>
                    <td>
                      <button className="edit-history">
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="edit-history ml-3">
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td>
                      <span className="ch-table-img">
                        <img
                          src="images/desktop-small.png"
                          alt="whatch-large"
                        ></img>
                      </span>
                    </td>
                    <td>New small desktop</td>
                    <td>randomised words even slightly believable</td>
                    <td>$16.00</td>
                    <td>In Progress</td>
                    <td>
                      <button className="edit-history">
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="edit-history ml-3">
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="ch-table-img">
                        <img src="images/img_3.png" alt="whatch-large"></img>
                      </span>
                    </td>
                    <td>new Black Clock</td>
                    <td>randomised words even slightly believable</td>
                    <td>$16.00</td>
                    <td>In Progress</td>
                    <td>
                      <button className="edit-history">
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="edit-history ml-3">
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="ch-table-img">
                        <img src="images/Image 7.png" alt="whatch-large"></img>
                      </span>
                    </td>
                    <td>Octa cells</td>
                    <td>randomised words even slightly believable</td>
                    <td>$16.00</td>
                    <td>In Progress</td>
                    <td>
                      <button className="edit-history">
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="edit-history ml-3">
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="ch-table-img">
                        <img src="images/order1.png" alt="whatch-large"></img>
                      </span>
                    </td>
                    <td>New genration laptop</td>
                    <td>randomised words even slightly believable</td>
                    <td>$16.00</td>
                    <td>In Progress</td>
                    <td>
                      <button className="edit-history">
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="edit-history ml-3">
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="ch-table-img">
                        <img
                          src="images/printer-small.png"
                          alt="whatch-large"
                        ></img>
                      </span>
                    </td>
                    <td>New prints</td>
                    <td>randomised words even slightly believable</td>
                    <td>$16.00</td>
                    <td>In Progress</td>
                    <td>
                      <button className="edit-history">
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="edit-history ml-3">
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="ch-table-img">
                        <img
                          src="images/romote-small.png"
                          alt="whatch-large"
                        ></img>
                      </span>
                    </td>
                    <td>Simple Black Clock</td>
                    <td>randomised words even slightly believable</td>
                    <td>$16.00</td>
                    <td>In Progress</td>
                    <td>
                      <button className="edit-history">
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="edit-history ml-3">
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
