import React from "react";
import "./UserSesionSection.css";

import { useNavigate } from "react-router-dom";

// import LogoutIcon from "../../pages/payment/assets/eye.png";

export default function UserSesionSection() {
  const storage = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();

  const removeSesion = () => {
    localStorage.removeItem('userInfo');
    navigate('/signin');
  }

  return (
    <div className="UserSesionSection">
      {/* <div
        style={{
          backgroundImage:
            'url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fvectorified.com%2Fimages%2Fno-profile-picture-icon-14.png&f=1&nofb=1")',
          height: "85px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "85px",
          borderRadius: "50%",
          border: "1px solid",
          backgroundSize: "150%",
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
      >
        <img />
      </div> */}
      <div className="db-profile">
        <span>
          <img
            src={storage && storage.profile && storage.profile.imagePath ? storage.profile.imagePath : "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fvectorified.com%2Fimages%2Fno-profile-picture-icon-14.png&f=1&nofb=1"}
            alt="profiledb"
          ></img>
        </span>
        <div className="db-proname-btn">
          <h6>
            {storage.profile.firstName} {storage.profile.middleName}
          </h6>
          <p>Member science Jan 2021</p>
          <button onClick={() => removeSesion()} className="btn logout">
            Logout{" "}
            <i>
              {/* <img src="images/eye.svg" alt="eyelogouticon"></img> */}
            </i>
          </button>
        </div>
      </div>
      {/* <div className="UserSesionSection__info-user">
        <p className="UserSesionSection__info-user-name">Jerry Lawson</p>
        <p className="UserSesionSection__info-user-rol">
          Vendor science Jan 2021
        </p>
        <div className="UserSesionSection__info-user-button-logout">
          <button>
            LOGOUT{" "}
            <img
              className="UserSesionSection__info-user-button-logout-icon"
              src={LogoutIcon}
              alt="icon-logout"
            />{" "}
          </button>
        </div>
      </div> */}
    </div>
  );
}
