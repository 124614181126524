import React from 'react';
import './ToltipCelebrity.css';

export default function ToltipCelebrity() {
  return (
    <div className='toltip__celebrity'>
        <p className='toltip__celebrity-sale'>$5000+ Sale</p>
        <p className='toltip__celebrity-date'>Month of Jan 2021</p>
        <p className='toltip__celebrity-supporters'>21323 Supporters</p>
    </div>
  )
}
