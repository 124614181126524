import React,{useState, useEffect} from 'react';
import './AddProduct.css';
// import { Link, Route, Routes, useSearchParams } from "react-router-dom";

import Sidebar from '../../components/Dashboards/Elements/Sidebar/Sidebar';
import ContentProduct from './ContentProduct';

import { verifyTokenUser } from '../../utils/Auth';
import { useNavigate } from 'react-router-dom';

export default function AddProduct() {

  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  
  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])


  return (
    <div>
        {loadingSesion ? <div className="se-pre-con"></div> : ""}
        <Sidebar/>
        <ContentProduct/>
    </div>
  )
}
