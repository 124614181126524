import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import ProductDetailSlider from "../../components/ProductDetailSlider/ProductDetailSlider";
import ProductDetailTabs from "../../components/ProductDetailTabs/ProductDetailTabs";
import ResentProduct from "../../components/ResentProduct/ResentProduct";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { ProductDetails, RecentProduct } from "../../Actions/Products/ProductsAction";
import { useSearchParams } from "react-router-dom";


export default function ProductDetail() {
  const data = useSelector((state) => state.productDetail);

  const [productDetailInfo, setproductDetailInfo] = useState(null);
  const [recentProduct, setRecentProduct] = useState(null);

  let [searchParams/* , _  */] = useSearchParams();
  let slug = searchParams.get("product");
  // const { id  }  = useParams()
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ProductDetails(slug));
  }, [slug])

  useEffect(() => {
    dispatch(RecentProduct());
  }, [])

  useEffect(() => {
    if(data && data.state) setproductDetailInfo(data.state)

    if(data && data.productsRecent) setRecentProduct(data.productsRecent);
    
  }, [data.state,data.productsRecent])

  // console.log(productDetailInfo)

  // useEffect(() => {
  // }, [data.productsRecent])
  
  

  return (
    <>
      {data.loading ? <div className="se-pre-con"></div> : ""}
      <Header />
      <ProductDetailSlider data={productDetailInfo} />
      <ProductDetailTabs data={productDetailInfo} />
      <ResentProduct data={recentProduct} />
      <Footer />
    </>
  );
}
