import React, { useState, useEffect } from "react";
import "./Account.css";

import Logo from "../../Assets/FangWaylogo.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// import "./Account.scss";
// import MuiPhoneNumber from "material-ui-phone-number";

import LogoApple from "../../Assets/apple-black-logo.png";
import LogoGoogle from "../../Assets/google.png";
import LogoFace from "../../Assets/facebook (1).png";
import LogoInsta from "../../Assets/instagram (1).png";
import { useDispatch, useSelector } from "react-redux";
import {
  // celebritySignup,
  customerSignup,
} from "../../Actions/SignupActions/SignupActions";
// import MaterialSnackbar from "./MaterialSnackbar";
// import * as SignupAction from "../../Actions/SignupActions/SignupAction";
import { useNavigate } from "react-router-dom";
// import { MenuItem, Select } from "@mui/material";
// import { authHeader } from "../../utils/Auth";

export default function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userSignUp = useSelector((state) => state.userSignUp);
  const [isLoading, setIsLoading] = useState(false);

  const { loading } = userSignUp;

  const userLoginInfo = useSelector((state) => state.userLoginInfo);

  const { userInfo: { profile: { role = "" } = {} } = {} } = userLoginInfo;

  useEffect(() => {
    if (role) {
      if (role === "user") {
        navigate("/");
      }
      if (role === "vendor") {
        navigate("/verification-vendor");
      }
      if (role === "celebrity") {
        navigate("/celebrity-dashboard");
      }
    }
  }, [role]);

  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <div className="body-login-1">
        <div className="login-body"></div>
        <div className="login">
          <div className="login__container">
            <img
              className="login__container-logo-image img-fluid"
              src={Logo}
              alt="logo fangway"
              // height="100px"
            />
            <p className="login__container-p-text">Sign Up to FangWay</p>
            {/* <div className="login__container-sign-social-network">
              <div className="login__container-sign-social-network-unitari">
                <img
                  className="login__container-sign-social-network-unitari-image-network"
                  src={LogoApple}
                  alt="logo apple"
                />
              </div>
              <div className="login__container-sign-social-network-unitari">
                <img
                  className="login__container-sign-social-network-unitari-image-network"
                  src={LogoInsta}
                  alt="logo apple"
                />
              </div>
              <div className="login__container-sign-social-network-unitari">
                <img
                  className="login__container-sign-social-network-unitari-image-network"
                  src={LogoFace}
                  alt="logo apple"
                />
              </div>
              <div className="login__container-sign-social-network-unitari">
                <img
                  className="login__container-sign-social-network-unitari-image-network"
                  src={LogoGoogle}
                  alt="logo apple"
                />
              </div>
            </div>
            <p className="login__container-email-addres">
              Or Sign in using email address
            </p> */}
          </div>
          {/* MM tabs start */}
          <div className="container">
            <ul className="nav nav-tabs signup-navs" role="tablist">
              <li role="presentation">
                <a
                  className="active"
                  href="#flamingo2"
                  aria-controls="home"
                  role="tab"
                  data-toggle="tab"
                >
                  Customer
                </a>
              </li>
              <li role="presentation">
                <a
                  href="#profile"
                  aria-controls="profile"
                  role="tab"
                  data-toggle="tab"
                >
                  Celebrity
                </a>
              </li>
              <li role="presentation">
                <a
                  href="#messages"
                  aria-controls="messages"
                  role="tab"
                  data-toggle="tab"
                >
                  Vendor
                </a>
              </li>
            </ul>
            <div className="tab-content mt-3 d-flex justify-content-center">
              <div
                className="tab-pane active"
                id="flamingo2"
                role="tabpanel"
                aria-labelledby="flamingo-tab"
              >
                <div className="login__container-form">
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      phone: "",
                      password: "",
                      cpassword: "",
                      gender: "Male",
                      role: "user",
                      dob: "",
                      nickName: "",
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string().required(
                        "First Name is required"
                      ),
                      lastName: Yup.string().required("Last Name is required"),
                      dob: Yup.string().required("Date of birth is required"),
                      role: Yup.string().required("Role is required"),
                      // nickName: Yup.string().required("NickName is required"),
                      email: Yup.string()
                        .email("Email is invalid")
                        .required("Email is required"),
                      phone: Yup.string("Enter a valid Phone Number")
                        .required("Phone number required")
                        .length(13, "Phone number is not valid"),
                      password: Yup.string()
                        .min(6, "Password must be at least 6 characters")
                        .required("Password is required"),
                      cpassword: Yup.string()
                        .oneOf(
                          [Yup.ref("password"), null],
                          "Passwords must match"
                        )
                        .required("Confirm Password is required"),
                    })}
                    onSubmit={async (fields) => {
                      console.log("filds::>", fields);
                      dispatch(
                        customerSignup(
                          fields.firstName,
                          fields.lastName,
                          fields.email,
                          fields.phone,
                          fields.password,
                          fields.cpassword,
                          fields.gender,
                          fields.role,
                          fields.dob,
                          fields.nickName,
                          () => {
                            navigate("/celebrity-selecting");
                          }
                        )
                      );
                    }}
                    render={({
                      errors,
                      touched,
                    }) => (
                      <Form>
                        <div className="name-field">
                          <div className="form-group">
                            <Field
                              name="firstName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.firstName && touched.firstName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="First name"
                            />
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              name="lastName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.lastName && touched.lastName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Last name"
                            />
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="name-field">
                          <div className="form-group">
                            {/* <label htmlFor="email">Email</label> */}
                            <Field
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <Field
                            name="phone"
                            type="text"
                            className={
                              "form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="+92"
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          />

                          {/* <MuiPhoneNumber
                            defaultCountry={"us"}
                            // className="anonymous"
                            // variant="standard"
                            className={
                              "form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                            name="phone"
                            value={values.phone}
                            onChange={(e) => setFieldValue("phone", e)}
                            onBlur={handleBlur("phone")}
                            // error={touched.phone && Boolean(errors.phone)}
                            helperText={touched.phone && errors.phone}
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          /> */}
                        </div>
                        <div className="name-field">
                          <div className="form-group">
                            <Field
                              name="password"
                              type="password"
                              className={
                                "form-control" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Password"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div
                            className="form-group"
                          >
                            <Field
                              name="cpassword"
                              type="password"
                              className={
                                "form-control" +
                                (errors.cpassword && touched.cpassword
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Confirm Password"
                            />
                            <ErrorMessage
                              name="cpassword"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              name="dob"
                              type="date"
                              className={
                                "form-control" +
                                (errors.dob && touched.dob ? " is-invalid" : "")
                              }
                              placeholder="Confirm Password"
                            />
                            <ErrorMessage
                              name="dob"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {/* <div className="form-group">
                            
                            <Field
                              name="nickName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.nickName && touched.nickName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Nick Name"
                            />
                            <ErrorMessage
                              name="nickName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div> */}
                          {/* <div
                            className="form-group"
                            style={{ marginBottom: "25px" }}
                          >
                            <Select
                              name="role"
                              className={
                                "form-control" +
                                (errors.role && touched.role
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={handleChange}
                            >
                              <MenuItem disabled value="0">
                                <em>Placeholder</em>
                              </MenuItem>
                              <MenuItem value="admin">Admin</MenuItem>
                              <MenuItem value="celebrity">Celebrity</MenuItem>
                              <MenuItem value="vendor">Vendor</MenuItem>
                              <MenuItem value="user">User</MenuItem>
                            </Select>
                            <ErrorMessage
                              name="role"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div> */}
                        </div>
                        <div className="form-group">
                          <button
                            className="login__signInButton"
                            // type="submit"
                            // onClick={handleClick}
                            // disabled={!(isValid && dirty)}
                            // className={
                            //   !(isValid && dirty)
                            //     ? "main-button disable "
                            //     : "main-button"
                            // }
                            type="submit"
                            // onClick={handleClick}
                            // disabled={!(isValid && dirty)}
                          >
                            SIGN UP
                            {loading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginLeft: "12px" }}
                              ></span>
                            )}
                          </button>
                          {/*  */}
                          <p className="login__new-user-text">
                            News user?
                            <a href="#">
                              <span className="login__new-user-text-span">
                                Sign Up
                              </span>
                            </a>
                          </p>
                          {/* <button type='reset' className='btn btn-secondary'>
                        Reset
                      </button> */}
                        </div>
                      </Form>
                    )}
                  />
                  <p className="login__forgot-text">Forgot password?</p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div className="login__container-form">
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      phone: "+",
                      password: "",
                      cpassword: "",
                      gender: "Male",
                      role: "celebrity",
                      dob: "",
                      nickName: "",
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string().required(
                        "First Name is required"
                      ),
                      lastName: Yup.string().required("Last Name is required"),
                      dob: Yup.string().required("Date of birth is required"),
                      role: Yup.string().required("Role is required"),
                      nickName: Yup.string().required("NickName is required"),
                      email: Yup.string()
                        .email("Email is invalid")
                        .required("Email is required"),
                      phone: Yup.string("Enter a valid Phone Number")
                        .required("Phone number required")
                        .length(13, "Phone number is not valid"),
                      password: Yup.string()
                        .min(6, "Password must be at least 6 characters")
                        .required("Password is required"),
                      cpassword: Yup.string()
                        .oneOf(
                          [Yup.ref("password"), null],
                          "Passwords must match"
                        )
                        .required("Confirm Password is required"),
                    })}
                    onSubmit={async (fields) => {

                      setIsLoading(true);
                      dispatch(
                        customerSignup(
                          fields.firstName,
                          fields.lastName,
                          fields.email,
                          fields.phone,
                          fields.password,
                          fields.cpassword,
                          fields.gender,
                          fields.role,
                          fields.dob,
                          fields.nickName,
                          () => {
                            if (fields.role === "celebrity") {
                              navigate("/celebrity-dashboard");
                            }
                          }
                        )
                      );
                      setIsLoading(false);
                    }}
                    render={({
                      errors,
                      touched,
                    }) => (
                      <Form>
                        <div className="name-field">
                          <div className="form-group">
                            {/* <label htmlFor="firstName">First Name</label> */}
                            <Field
                              name="firstName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.firstName && touched.firstName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="First name"
                            />
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {/* <div className="form-group">
                          <Field
                            name="middleName"
                            type="text"
                            className={
                              "form-control" +
                              (errors.middleName && touched.middleName
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Middle name"
                          />
                          <ErrorMessage
                            name="middleName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div> */}
                          <div className="form-group">
                            {/* <label htmlFor="lastName">Last Name</label> */}
                            <Field
                              name="lastName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.lastName && touched.lastName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Last name"
                            />
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="name-field">
                          <div className="form-group">
                            {/* <label htmlFor="email">Email</label> */}
                            <Field
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          {/* <label htmlFor="email">Phone</label> */}

                          <Field
                            name="phone"
                            type="text"
                            className={
                              "form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="+92"
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          />

                          {/* <MuiPhoneNumber
                            defaultCountry={"us"}
                            // className="anonymous"
                            // variant="standard"
                            className={
                              "form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                            name="phone"
                            value={values.phone}
                            onChange={(e) => setFieldValue("phone", e)}
                            onBlur={handleBlur("phone")}
                            // error={touched.phone && Boolean(errors.phone)}
                            helperText={touched.phone && errors.phone}
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          /> */}
                        </div>
                        <div className="name-field">
                          <div className="form-group">
                            {/* <label htmlFor="password">Password</label> */}
                            <Field
                              name="password"
                              type="password"
                              className={
                                "form-control" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Password"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div
                            className="form-group"
                            // style={{ marginBottom: "25px" }}
                          >
                            {/* <label htmlFor="cpassword">
                            Confirm Password
                          </label> */}
                            <Field
                              name="cpassword"
                              type="password"
                              className={
                                "form-control" +
                                (errors.cpassword && touched.cpassword
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Confirm Password"
                            />
                            <ErrorMessage
                              name="cpassword"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group">
                            {/* <label htmlFor="confirmPassword">
                            Confirm Password
                          </label> */}
                            <Field
                              name="dob"
                              type="date"
                              className={
                                "form-control" +
                                (errors.dob && touched.dob ? " is-invalid" : "")
                              }
                              placeholder="Confirm Password"
                            />
                            <ErrorMessage
                              name="dob"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              name="nickName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.nickName && touched.nickName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Nick Name"
                            />
                            <ErrorMessage
                              name="nickName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {/* <div
                            className="form-group"
                            style={{ marginBottom: "25px" }}
                          >
                            <Select
                              name="role"
                              className={
                                "form-control" +
                                (errors.role && touched.role
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={handleChange}
                            >
                              <MenuItem disabled value="0">
                                <em>Placeholder</em>
                              </MenuItem>
                              <MenuItem value="admin">Admin</MenuItem>
                              <MenuItem value="celebrity">Celebrity</MenuItem>
                              <MenuItem value="vendor">Vendor</MenuItem>
                              <MenuItem value="user">User</MenuItem>
                            </Select>
                            <ErrorMessage
                              name="role"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div> */}
                        </div>
                        <div className="form-group">
                          <button
                            className="login__signInButton"
                            // type="submit"
                            // onClick={handleClick}
                            // disabled={!(isValid && dirty)}
                            // className={
                            //   !(isValid && dirty)
                            //     ? "main-button disable "
                            //     : "main-button"
                            // }
                            type="submit"
                            // onClick={handleClick}
                            // disabled={!(isValid && dirty)}
                          >
                            SIGN UP
                            {loading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginLeft: "12px" }}
                              ></span>
                            )}
                          </button>
                          {/*  */}
                          <p className="login__new-user-text">
                            News user?
                            <a href="#">
                              <span className="login__new-user-text-span">
                                Sign Up
                              </span>
                            </a>
                          </p>
                          {/* <button type='reset' className='btn btn-secondary'>
                        Reset
                      </button> */}
                        </div>
                      </Form>
                    )}
                  />
                  <p className="login__forgot-text">Forgot password?</p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="messages"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                <div className="login__container-form">
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      phone: "",
                      password: "",
                      cpassword: "",
                      gender: "Male",
                      role: "vendor",
                      dob: "",
                      nickName: "",
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string().required(
                        "First Name is required"
                      ),
                      lastName: Yup.string().required("Last Name is required"),
                      dob: Yup.string().required("Date of birth is required"),
                      role: Yup.string().required("Role is required"),
                      // nickName: Yup.string().required("NickName is required"),
                      email: Yup.string()
                        .email("Email is invalid")
                        .required("Email is required"),
                      phone: Yup.string("Enter a valid Phone Number")
                        .required("Phone number required")
                        .length(13, "Phone number is not valid"),
                      password: Yup.string()
                        .min(6, "Password must be at least 6 characters")
                        .required("Password is required"),
                      cpassword: Yup.string()
                        .oneOf(
                          [Yup.ref("password"), null],
                          "Passwords must match"
                        )
                        .required("Confirm Password is required"),
                    })}
                    onSubmit={async (fields) => {
                      // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 7));
                      console.log("filds::>", fields);
                      setIsLoading(true);
                      await dispatch(
                        customerSignup(
                          fields.firstName,
                          fields.lastName,
                          fields.email,
                          fields.phone,
                          fields.password,
                          fields.cpassword,
                          fields.gender,
                          fields.role,
                          fields.dob,
                          fields.nickName,
                          () => {
                            // if (fields.role === "user") {
                            //   navigate("/celebrity-selecting");
                            // }
                            // if (fields.role === "celebrity") {
                            //   navigate("/verification-celebritie");
                            // }
                            if (fields.role === "vendor") {
                              navigate("/verification-vendor");
                            }
                          }
                        )
                      );
                      setIsLoading(false);

                      // navigate("/customer-dashboard");
                    }}
                    render={({
                      errors,
                      status,
                      touched,
                      values,
                      setFieldValue,
                      handleBlur,
                      handleChange,
                      isValid,
                      dirty,
                      // handleSubmit
                    }) => (
                      <Form>
                        <div className="name-field">
                          <div className="form-group">
                            {/* <label htmlFor="firstName">First Name</label> */}
                            <Field
                              name="firstName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.firstName && touched.firstName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="First name"
                            />
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {/* <div className="form-group">
                          <Field
                            name="middleName"
                            type="text"
                            className={
                              "form-control" +
                              (errors.middleName && touched.middleName
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Middle name"
                          />
                          <ErrorMessage
                            name="middleName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div> */}
                          <div className="form-group">
                            {/* <label htmlFor="lastName">Last Name</label> */}
                            <Field
                              name="lastName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.lastName && touched.lastName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Last name"
                            />
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="name-field">
                          <div className="form-group">
                            {/* <label htmlFor="email">Email</label> */}
                            <Field
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          {/* <label htmlFor="email">Phone</label> */}

                          <Field
                            name="phone"
                            type="text"
                            className={
                              "form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="+92"
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          />

                          {/* <MuiPhoneNumber
                            defaultCountry={"us"}
                            // className="anonymous"
                            // variant="standard"
                            className={
                              "form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                            name="phone"
                            value={values.phone}
                            onChange={(e) => setFieldValue("phone", e)}
                            onBlur={handleBlur("phone")}
                            // error={touched.phone && Boolean(errors.phone)}
                            helperText={touched.phone && errors.phone}
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          /> */}
                        </div>
                        <div className="name-field">
                          <div className="form-group">
                            {/* <label htmlFor="password">Password</label> */}
                            <Field
                              name="password"
                              type="password"
                              className={
                                "form-control" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Password"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div
                            className="form-group"
                            // style={{ marginBottom: "25px" }}
                          >
                            {/* <label htmlFor="cpassword">
                            Confirm Password
                          </label> */}
                            <Field
                              name="cpassword"
                              type="password"
                              className={
                                "form-control" +
                                (errors.cpassword && touched.cpassword
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Confirm Password"
                            />
                            <ErrorMessage
                              name="cpassword"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group">
                            {/* <label htmlFor="confirmPassword">
                            Confirm Password
                          </label> */}
                            <Field
                              name="dob"
                              type="date"
                              className={
                                "form-control" +
                                (errors.dob && touched.dob ? " is-invalid" : "")
                              }
                              placeholder="Confirm Password"
                            />
                            <ErrorMessage
                              name="dob"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {/* <div className="form-group">
                            
                            <Field
                              name="nickName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.nickName && touched.nickName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Nick Name"
                            />
                            <ErrorMessage
                              name="nickName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div> */}
                          {/* <div
                            className="form-group"
                            style={{ marginBottom: "25px" }}
                          >
                            
                            <Select
                              name="role"
                              className={
                                "form-control" +
                                (errors.role && touched.role
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={handleChange}
                            >
                              <MenuItem disabled value="0">
                                <em>Placeholder</em>
                              </MenuItem>
                              <MenuItem value="admin">Admin</MenuItem>
                              <MenuItem value="celebrity">Celebrity</MenuItem>
                              <MenuItem value="vendor">Vendor</MenuItem>
                              <MenuItem value="user">User</MenuItem>
                            </Select>
                            <ErrorMessage
                              name="role"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div> */}
                        </div>
                        <div className="form-group">
                          <button
                            className="login__signInButton"
                            // type="submit"
                            // onClick={handleClick}
                            // disabled={!(isValid && dirty)}
                            // className={
                            //   !(isValid && dirty)
                            //     ? "main-button disable "
                            //     : "main-button"
                            // }
                            type="submit"
                            // onClick={handleClick}
                            // disabled={!(isValid && dirty)}
                          >
                            SIGN UP
                            {loading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginLeft: "12px" }}
                              ></span>
                            )}
                          </button>
                          {/*  */}
                          <p className="login__new-user-text">
                            News user?
                            <a href="/signin">
                              <span className="login__new-user-text-span">
                                Sign in
                              </span>
                            </a>
                          </p>
                          {/* <button type='reset' className='btn btn-secondary'>
                        Reset
                      </button> */}
                        </div>
                      </Form>
                    )}
                  />
                  <p className="login__forgot-text">Forgot password?</p>
                </div>
              </div>
            </div>
          </div>
          {/* tabs end  */}
        </div>
      </div>
    </>
  );
}
