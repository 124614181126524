import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { getCart, ProductDetailsCount, AggregateNewDiscount, AgregateStogareDiscount, CreateOrder } from "../../Actions/Products/ProductsAction";
import { useDispatch } from "react-redux";
import ProgressBarDiscount from '../../components/ShoppingCart/ProgressBarDiscount';
import TextProgressBar from "./TextProgressBar";

import { useNavigate } from 'react-router-dom';

export default function DetailSection({ data }) {
  const dispatch = useDispatch();
  // const [dataProduct, setDataProduct] = useState(null);

  console.log(data);
  const navigate = useNavigate();

  const [clickButton, setclickButton] = useState(false);
  const [amount, setAmount] = useState(1);
  const [loadingOrder, setLoadingOrder] = useState(false);

  const handleClickBuy = (slug) => {
    const userLocal = localStorage.getItem("userInfo");
    if (!userLocal) {
      localStorage.setItem("check","One");
      setLoadingOrder(true);
      dispatch(
        ProductDetailsCount(slug, (product) => {
          localStorage.setItem(
            "order",
            JSON.stringify([
              {
                product: product,
                amount: 1,
              },
            ])
          );
          setLoadingOrder(false);
          navigate("/signin");
        })
      );
    }
    setLoadingOrder(true);
    dispatch(
      ProductDetailsCount(slug, (product) => {
        localStorage.setItem(
          "order",
          JSON.stringify([
            {
              product: product,
              amount: 1,
            },
          ])
        );
        dispatch(
          CreateOrder("One", (data, sk, err) => {
            console.log({
              data,
              sk,
              err,
            });
            if (err) {
              toast.error(err);
            } else {
              navigate(`/checkout?o=${data.data.idOrder}&s=${sk}`);
            }
            setLoadingOrder(false);
          })
        );
      })
    );
  };

  const handleClickAddCart = async (product) => {
    // const car = localStorage.getItem("carUser");
    // // console.log(car);
    // if (car !== null) {
    //   const carSto = JSON.parse(car);
    //   let fonund = false;
    //   for (let i = 0; i < carSto.length; i++) {
    //     const element = carSto[i];
    //     if (element.product.id === product.id) {
    //       fonund = true;
    //       element.amount += amount;
    //     }
    //   }

    //   if (fonund) {
    //     localStorage.setItem("carUser", JSON.stringify(carSto));
    //   } else {
    //     localStorage.setItem(
    //         "carUser",
    //         JSON.stringify([
    //           ...carSto,
    //           {
    //             product: product,
    //             amount: amount,
    //           },
    //         ])
    //     );
    //   }
    // } else {
    //   localStorage.setItem(
    //       "carUser",
    //       JSON.stringify([
    //         {
    //           product: product,
    //           amount: amount,
    //         },
    //       ])
    //   );
    // }
    await AggregateNewDiscount(product,amount);
    // dispatch(AgregateStogareDiscount(discount));
    dispatch(getCart());
  };

  const handleClickAction = (action) => {
    // if(amount - 1 < 1) return
    if (clickButton === false) {
      if (action === "SUM") {
        setclickButton(true);
        return setAmount(2);
      } else {
        return setAmount(amount - 1);
      }
    } else {
      if (action === "SUM") {
        // setclickButton(true);
        // console.log("emntro");
        return setAmount(amount + 1);
      } else {
        return setAmount(amount - 1);
      }
    }
  };

  useEffect(() => {
    dispatch(getCart());
  }, []);

  return (
      <div className="col-md-6">
        {/* <ToastContainer /> */}
        {loadingOrder ? <div className="se-pre-con"></div> : ""}
        <section className="detail-cart">
          <h2>{data ? data.name : ""}</h2>
          <h3 className="smart-whatch">{data ? data.category : ""}</h3>
          <ul className="rating">
            {data ? (
                [1, 2, 3, 4, 5].map((start) => {
                  return (
                      <li
                          className={`fa fa-star ${
                              data.ranking && start <= data.ranking ? "" : "disable"
                          }`}
                      ></li>
                  );
                })
            ) : (
                <>
                  <li className="fa fa-star disable"></li>
                  <li className="fa fa-star disable"></li>
                  <li className="fa fa-star disable"></li>
                  <li className="fa fa-star disable"></li>
                  <li className="fa fa-star disable"></li>
                </>
            )}

            {/* <li className="fa fa-star"></li>
          <li className="fa fa-star"></li>
          <li className="fa fa-star disable"></li> */}
          </ul>
          <div className="rating-detail">
            <ul className="reviews-ul">
              <li>{data && data.review ? data.review.data.length : 0} Reviews</li>
              <li>Add Your Review</li>
            </ul>
          </div>
          <div className="linethrough-text">
            <span>${data && data.amount ? data.amount.toFixed(2) : 0}</span>
          </div>
          <h4 className="product-price">
            $
            {data && data.promotion
                ? data.promotion.discountPrice
                : data && data.amount
                    ? data.discount
                        ? (data.amount - ((data.amount / 100) * parseInt(data.discount))).toFixed(2)
                        : (data.amount - ((data.amount / 100) * parseInt(30))).toFixed(2)
                    : 0}
          </h4>
          {/* <ul className="avialabilty-ul">
          <li>Availability</li>
          <li>
            <a href="#">{data && data.stock ? "In stock" : "Not stock"}</a>
          </li>
        </ul> */}
          <div className="product-detail-para">
            <p>{data && data.description ? data.description : ""}</p>
          </div>
          {/* <h6 className="product-code">SKU: FW51 1948218</h6> */}
          <ul className="color-quantity-ul">
            {/* <li>
            <div className="dropdown">
              <button
                type="button"
                className="btn dropdown-toggle"
                data-toggle="dropdown"
              >
                Select Color <i className="fas fa-chevron-down"></i>
              </button>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="#">
                  Link 1
                </a>
                <a className="dropdown-item" href="#">
                  Link 2
                </a>
                <a className="dropdown-item" href="#">
                  Link 3
                </a>
              </div>
            </div>
          </li> */}
            <li>
              <div className="qty">
              <span
                  onClick={() => handleClickAction("SUBTRACT")}
                  className="minus bg-dark"
              >
                -
              </span>
                <input
                    type="number"
                    placeholder="Quanlity"
                    className="count mt-2"
                    name="qty"
                    value={amount}
                />
                <span
                    onClick={() => handleClickAction("SUM")}
                    className="plus bg-dark"
                >
                +
              </span>
            </div>
          </li>
        </ul>
        <ul className="add-cart-ul">
          <li>
            <button
              onClick={() => handleClickBuy(data.slug)}
              className="add-to-crat-btn btn"
            >
                Buy now
              </button>
            </li>
            <li>
              <button
                  onClick={() => handleClickAddCart(data)}
                  className="add-to-crat-btn btn"
              >
              <span>
                <img src="images/small-cart-icon.png" alt="cart"></img>
              </span>
                Add to Cart
              </button>
            </li>
            <li>
              <TextProgressBar />
            </li>

            {/* <li>
            <a className="">
              <span>
                <img src="images/refresh.png" alt="compare"></img>
              </span>
              Compare
            </a>
          </li> */}
            {/* <li>
            <a className="">
              <span>
                <img src="images/love.png" alt="love"></img>
              </span>
              Whishlist
            </a>
          </li> */}
          </ul>
          {/* <ul className="product-socials">
          <li>SHARE</li>
          <li>
            <a href="#">
              <img src="images/youtube.png" alt="you"></img>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="images/whatsapp.png" alt="you"></img>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="images/twitter.png" alt="you"></img>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="images/facebook.png" alt="you"></img>
            </a>
          </li>
        </ul> */}
        <ProgressBarDiscount />
        </section>
      </div>
  );
}