import React,{useState, useEffect} from 'react';
import CustomerSidebar from '../../../components/Dashboards/Elements/CustomerSidebar/CustomerSidebar';
import OrderHistory from "../../../components/Dashboards/Customer/OrderHistory/CustomerHistory";


import { verifyTokenUser } from '../../../utils/Auth';
import { useNavigate } from 'react-router-dom';


export default function CustomerOrderHistory() {

  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  
  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])

  if(loadingSesion){
    return <div className="se-pre-con"></div>;
  }

  return (<div>
      {/* {loadingSesion ?  : ""} */}
      <OrderHistory/>
      <CustomerSidebar/>
  </div>)
}
