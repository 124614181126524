import axios from "axios";
import { toast } from "react-toastify";

//-----------------------Get Celebrity ---------------------//
export const getingCelebrity = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: "GET_CELEBRITY_INIT",
    });

    // const {
    //   userLogin: { userInfo },
    // } = getState();
    // let userInfo = localStorage.getItem("userInfo");
    const user = JSON.parse(localStorage.getItem("userInfo"));

    console.log("first::>", user);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    const { data } = await axios.get(
      "https://fangway.com/users/celebrity",
      config
    );
    dispatch({
      type: "GET_CELEBRITY_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CELEBRITY_FAIL",
      payload: error,
      // error.response && error.response.data.message
      //   ? error.response.data.message
      //   : error.message,
    });
  }
};

//-----------------------Upload Celebrity ---------------------//
export const celebrityUpload =
  (firstName, lastName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPLOAD_CELEBRITY_INIT",
      });

      const user = JSON.parse(localStorage.getItem("userInfo"));

      console.log("first::>", user);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.put(
        "https://fangway.com/users/celebrity",
        { firstName, lastName },
        config
      );
      dispatch({
        type: "UPLOAD_CELEBRITY_SUCCESS",
        payload: data,
      });
      toast.success(data.message);
    } catch (error) {
      dispatch({
        type: "UPLOAD_CELEBRITY_FAIL",
        payload: error,
        // error.response && error.response.data.message
        //   ? error.response.data.message
        //   : error.message,
      });
    }
  };

//-----------------------GET Celebrity Setting ---------------------//
export const getingCelebritySetting = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CELEBRITY_SETTING_INIT",
    });

    const user = JSON.parse(localStorage.getItem("userInfo"));

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    const { data } = await axios.get(
      "https://fangway.com/users/celebrity",
      config
    );
    dispatch({
      type: "GET_CELEBRITY_SETTING_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CELEBRITY_SETTING_FAIL",
      payload: error,
      // error.response && error.response.data.message
      //   ? error.response.data.message
      //   : error.message,
    });
  }
};

//-----------------------GET Celebrity Setting ---------------------//
export const getingCelebrityEarning = (authId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CELEBRITY_EARNING_INIT",
    });

    const user = JSON.parse(localStorage.getItem("userInfo"));

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    const { data } = await axios.get(
      `https://fangway.com/ecommerce/stats/celebrity/${authId}/earnings`,
      config
    );
    dispatch({
      type: "GET_CELEBRITY_EARNING_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CELEBRITY_EARNING_FAIL",
      payload: error,
      // error.response && error.response.data.message
      //   ? error.response.data.message
      //   : error.message,
    });
  }
};
