export const WishListReducer = (state = {}, action) => {
  switch (action.type) {
    //--------- Get Wish List --------------//
    case "GET_WISH_LIST_INIT":
      return { loading: true };
    case "GET_WISH_LIST_SUCCESS":
      return { loading: false, wishList: action.payload };
    case "GET_WISH_LIST_FAIL":
      return { loading: false, error: action.payload };

    //--------- Delete WIsh List --------------//
    case "DELETE_WISH_LIST_INIT":
      return { loading: true };
    case "DELETE_WISH_LIST_SUCCESS":
      return { loading: false, wishListDelete: action.payload };
    case "DELETE_WISH_LIST_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
