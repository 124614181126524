import axios from "axios";
import { toast } from "react-toastify";

export const customerSignup =
  (
    firstName,
    lastName,
    email,
    phone,
    password,
    cpassword,
    gender,
    role,
    dob,
    nickName,
    callback
  ) =>
  async (dispatch) => {
    dispatch({
      type: "SIGN_UP_INIT",
    });

    await axios
      .post(
        `https://fangway.com/users/auth/signup`,
        {
          firstName,
          lastName,
          email,
          phone,
          password,
          cpassword,
          gender,
          role,
          dob,
          nickName,
        }
        // config
      )
      .then((res) => {
        dispatch({
          type: "SIGN_UP_SUCCESS",
          payload: res.data.data,
        });

        localStorage.setItem("userInfo", JSON.stringify(res.data.data));

        if (res.data.statusCode == "201" || res.data.statusCode == "200") {
          toast.success(res.data.message);
          callback();
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(error.response.data.message);

        dispatch({
          type: "SIGN_UP_FAIL",
          payload: error,
        });
      });
  };

// export const celebritySignup = (
//   firstName,
//   lastName,
//   email,
//   phone,
//   password,
//   cpassword,
//   nickname
// ) => async (dispatch) => {
//   dispatch({
//     type: "CELEBRITY_SIGN_UP_INIT",
//   });

//   try {
//     // const config = {
//     //   headers: {
//     //     "Content-Type": "application/json",
//     //   },
//     // };

//     const data = await axios.post(
//       `http://fangway.devjunction.xyz/users/auth/celebrity/verification`,
//       {
//         firstName,
//         lastName,
//         email,
//         phone,
//         password,
//         cpassword,
//         nickname,
//       }
//       // config
//     );

//     dispatch({
//       type: "CELEBRITY_SIGN_UP_SUCCESS",
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: "CELEBRITY_SIGN_UP_FAIL",
//       payload: error,
//       // error.response && error.response.data.message
//       //   ? error.response.data.message
//       //   : error.message,
//     });
//   }
// };
