import React,{useState,useEffect} from "react";
import ProgressBar from "@ramonak/react-progress-bar";

export default function ProgressBarDiscount() {

    const [discountCart, setDiscountCart] = useState(30);

    const cart = localStorage.getItem("carUser");

    useEffect(() => {
        if(cart){
            const cart2 = JSON.parse(cart);
            let discountSum = 0;
            for (let z = 0; z < cart2.length; z++) {
                const element = cart2[z];
                discountSum += element.product.newDiscount;
            }
            const discount = parseFloat((discountSum / cart2.length).toFixed(2));
            setDiscountCart(discount ? discount : 30);
        }
    }, [cart])
    

  return (
    <div>
      <div className="my-4">
        {/* <p>Your discount is: <span>60%</span></p> */}
        <ProgressBar completed={discountCart} maxCompleted={65} bgColor="#fed340" labelColor="#000" />
        <p className="text-right mt-2 font-weight-bold h5">Limit of discount: 65%</p>
      </div>
    </div>
  );
}
