import axios from "axios";
import { Auth } from "../../utils/Auth";

const url = true ? 'https://fangway.com' : 'http://localhost:3002';


export const searchProducts = (category,search) => async (dispatch) => {
    dispatch({
      type: "SEARCH_PRODUCT_GET",
    });
    try {
      // console.log(category,search)
      const { data } = await axios.get(
        `${url}/products/celebrity/search?search=${search}&category=${category}&page=1&limit=30`,
        { headers: Auth() }
      );
      // console.log(data)
      dispatch({
        type: "SEARCH_PRODUCT_SUCCESS",
        payload: data.data.docs,
      });
    } catch (error) {
      dispatch({
        type: "SEARCH_PRODUCT_FAIL",
        payload: error,
      });
    }
  }