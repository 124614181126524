import { Avatar/* , Checkbox */ } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { /* Navigate, */ useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getingCelebrity } from "../../Actions/CelebrityActions/celebrityActions";
import { uploadCelebrityId } from "../../Actions/CustomerActions/customerAction";
import { CreateOrder } from "../../Actions/Products/ProductsAction";
import "./SelectCelebrity.css";
export default function SelectCelebrity() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const celebrityData = useSelector((state) => state.celebrityReducer);
  // const {
  //   loading,
  //   celebrity: { data },
  // } = celebrityData;

  const celebrity = celebrityData.celebrity || [];

  const {  /* loading, */  data } = celebrity;

  // console.log("celebrityData:::>", id);
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveClick = () => {
    setIsLoading(true);
    dispatch(
      uploadCelebrityId(id, () => {
        // const check = localStorage.getItem("check");
        // if(check){
        //   setIsLoading(true);
        //   dispatch(
        //     CreateOrder(`${check}`, (data, sk, err) => {
        //       if (err) {
        //         toast.error(err);
        //       } else {
        //         navigate(`/checkout?o=${data.data.idOrder}&s=${sk}`);
        //       }
        //       setIsLoading(false);
        //       localStorage.removeItem("check")
        //     })
        //   );
        // }else{
         
        // }
        navigate("/");
      })
    );
    setIsLoading(false);
  };

  useEffect(async () => {
    setIsLoading(true);
    dispatch(getingCelebrity());
    setIsLoading(false);
  }, []);

  // const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <div>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <section className="select-celebrity-content">
        <div className="custom-container">
          <h2 className="mt-3 mb-5" >Select the celebrity you want to support </h2>
          {/* <div className="select-celebrity-search">
            <h2>Select Favorites Celebrity To Support</h2>
            <div className="input-group faveroit-select">
              <input
                type="text"
                className="form-control"
                placeholder="Search Celebrity"
              />
              <div className="input-group-append">
                <button className="btn btn-search" type="button">
                  Search
                </button>
              </div>
            </div>
          </div> */}
          {/* cart section start */}
          <div className="row no-gutters">
            {data &&
              data.map((item, i) => (
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3 custom-padding">
                  <div className="celebrity-cart">
                    <span className="favorite-img">
                      <Avatar
                        sx={{ width: 180, height: 180 }}
                        src={item.imagePath}
                      />
                    </span>
                    <h3>
                      {item.nickName}
                    </h3>
                    <button
                      type="button"
                      className="btn select-favorite-btn"
                      onClick={() => setId(item.authId)}
                    >
                      Select
                      {/* <Checkbox onClick={() => setId(item.authId)} {...label} />     */}
                    </button>
                  </div>
                </div>
              ))}

            <div className="col-12 text-center">
              <div className="skip-save-div">
                {/* <button className="btn skipsave-btn">Skip</button> */}
                <button className="btn skipsave-btn" onClick={handleSaveClick}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
