import axios from "axios";
import { toast } from "react-toastify";

//-----------------------Upload Celebrity id to go to customer dashboard ---------------------//
export const uploadCelebrityId =
  (celebrityId, callback) => async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPLOAD_CELEBRITY_ID_INIT",
      });
      const user = JSON.parse(localStorage.getItem("userInfo"));

      console.log("first::>", user);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.put(
        "https://fangway.com/users",
        { celebrityId },
        config
      );

      if (data.statusCode == "200") {
        toast.success("Getting Celebrity Id Successfully");
        callback();
      } else {
        toast.error("Getting Celebrity Id Failed");
      }
      dispatch({
        type: "UPLOAD_CELEBRITY_ID_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "UPLOAD_CELEBRITY_ID_FAIL",
        payload: error,
      });
    }
  };

//-----------------------Get Customer Setting---------------------//
export const getCustomerSetting = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CUSTOMER_SETTING_INIT",
    });

    const user = JSON.parse(localStorage.getItem("userInfo"));

    console.log("first::>", user);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.data.token}`,
      },
    };

    const { data } = await axios.get(
      "https://fangway.com/users/auth/profile",
      config
    );

    if (data.statusCode == "200") {
      toast.success("Get Customer Setting Successfully");
    } else {
      toast.error("Get Customer Setting Failed");
    }
    // if (data.statusCode == "200") {
    //   callback();
    // }
    dispatch({
      type: "GET_CUSTOMER_SETTING_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CUSTOMER_SETTING_FAIL",
      payload: error,
    });
  }
};

//-----------------------Update Customer Setting---------------------//
export const updateCustomerSetting =
  (
    firstName,
    lastName,
    middleName,
    email,
    phone,
    password,
    cpassword,
    gender,
    role,
    dob,
    nickName,
    imagePath
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_CUSTOMER_SETTING_INIT",
      });
      const user = JSON.parse(localStorage.getItem("userInfo"));

      console.log("first::>", imagePath);
      const config = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.put(
        "https://fangway.com/users",
        {
          firstName,
          lastName,
          middleName,
          email,
          phone,
          password,
          cpassword,
          gender,
          role,
          dob,
          nickName,
          imagePath,
        },
        config
      );

      if (data.statusCode == "200") {
        toast.success(data.message);
      } else {
        toast.error("Update Customer Setting Failed");
      }
      // if (data.statusCode == "200") {
      //   callback();
      // }
      dispatch({
        type: "UPDATE_CUSTOMER_SETTING_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_CUSTOMER_SETTING_FAIL",
        payload: error,
      });
    }
  };

//-----------------------Update Customer Display Setting---------------------//
export const updateCustomerDisplaySetting =
  (fontSize, windowSize) => async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_CUSTOMER_DISPLAY_SETTING_INIT",
      });

      const user = JSON.parse(localStorage.getItem("userInfo"));

      console.log("first::>", user);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.data.token}`,
        },
      };

      const { data } = await axios.put(
        "https://fangway.com/users/settings",
        { fontSize, windowSize },
        config
      );

      if (data.statusCode == "200") {
        toast.success("Update Customer Display Setting Successfully");
      } else {
        toast.error("Update Customer Display Setting Failed");
      }
      // if (data.statusCode == "200") {
      //   callback();
      // }
      dispatch({
        type: "UPDATE_CUSTOMER_DISPLAY_SETTING_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_CUSTOMER_DISPLAY_SETTING_FAIL",
        payload: error,
      });
    }
  };

//-----------------------order list products  ---------------------//
export const gettingOrderlistDetails =
  (productId, callBack) => async (dispatch, getState) => {
    try {
      dispatch({
        type: "GET_MORE_DETAIL_ORDER_LIST_INIT",
      });

      const user = JSON.parse(localStorage.getItem("userInfo"));

      const config = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      console.log("config::>", config);

      const { data } = await axios.get(
        // `https://fangway.com/products/order/order/whishlist?page=${page}&limit=${limit}`,
        `https://fangway.com/ecommerce/order/${productId}`,
        config
      );
      // toast.success(data.message);
      if (data.statusCode == "200" || data.statusCode == "201") {
        callBack();
      }
      dispatch({
        type: "GET_MORE_DETAIL_ORDER_LIST_SUCCESS",
        payload: data.data,
      });
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch({
        type: "GET_MORE_DETAIL_ORDER_LIST_FAIL",
        payload: error,
        // error.response && error.response.data.message
        //   ? error.response.data.message
        //   : error.message,
      });
    }
  };
