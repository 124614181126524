import React, {useState, useEffect} from 'react';
import "./ThankyouContent.css";
import { useNavigate } from 'react-router-dom';

export default function ThankyouContent({orderId}) {

    const navigate = useNavigate();

    const [dataOrder, setDataOrder] = useState([]);
    const order = localStorage.getItem("order");
    const user = JSON.parse(localStorage.getItem("userInfo"))

    useEffect(() => {
        if(order){
            const orderSto = JSON.parse(localStorage.getItem("order"));
            setDataOrder(orderSto)
        }
    }, [order])

    if(!user){
        navigate('/')
    }
    

    return <div>
        <section className='thankyou-content-area'>
            {/* <div className='text-center'><img src='images/check-mark.png' alt='checkmark' className='img-fluid'></img></div> */}
            <h1>Thank You</h1>
            <h4>Your order has been placed</h4>
            <div className='thankyou-content-box'>
                <div className='order-number-flex'>
                    <div>
                        <span><img src={user && user.profile && user.profile.celebrity ? user.profile.celebrity.imagePath : "https://developers.google.com/web/images/contributors/no-photo.jpg?hl=es" }></img></span>
                        <p className='order-men'>{user && user.profile && user.profile.celebrity ? user.profile.celebrity.firstName : ""}</p>
                    </div>
                    <p className='order-number'>Order Number: {orderId}</p>
                </div>
                <ul className='order-list'>
                    {dataOrder && dataOrder.map((pro) => {
                        return (
                            <li>
                            <span><img src={pro.product.image} alt='order'></img></span>
                            <p>{pro.product.name}</p>
                        </li>
                        )
                    })}

                    {/* <li>
                        <span><img src="images/order2.png" alt='order'></img></span>
                        <p>iPhone 13 Pro 256GB </p>
                    </li>
                    <li>
                        <span><img src="images/order3.png" alt='order'></img></span>
                        <p>Apple Watch </p>
                    </li> */}
                </ul>
                {/* <h3 className='delivery-time'>Estimated Delivery Time: 11/22/2022</h3> */}
                <div className='thankyou-social'>
                    <a href='#' className='fang-link'>www.fangway.org</a>
                    <ul>
                        <li><span>Share</span></li>
                        <li><a href='#'><img src='images/pinterest.png' alt='pintrest'></img></a></li>
                        <li><a href='#'><img src='images/insta-icon.png' alt='insta'></img></a></li>
                        <li><a href='#'><img src='images/facebook-icon.png' alt='fb'></img></a></li>
                        <li><a href='#'><img src='images/bell-icon.png' alt='bell-icon'></img></a></li>
                    </ul>
                </div>
            </div>
        </section>

    </div>
}
