export const uploadFileReducer = (state = {}, action) => {
  switch (action.type) {
    //--------- Celebrity Sign up --------------//
    case "UPLOAD_FILE_INIT":
      return { loading: true };
    case "UPLOAD_FILE_SUCCESS":
      return { loading: false, imagePath: action.payload };
    case "UPLOAD_FILE_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
