const initialState = {
  loading: false,
  error: null,
  productEdit: null,
  dataProductEdit: null,
  deleteProduct: null,
  allProducts: [],
  dealsProduct: null,
  idPromotion: null,
  infoPromotion: null,
  index: 0,
  selectContent: 'MY-ITEMS',
};

export const dashboardVendor = (state = initialState, action) => {
  switch (action.type) {
    //UPLOAD IMAGE PRODUCT
    case "UPLOAD_FILE_INIT":
      return { ...state, loading: true };
    case "UPLOAD_FILE_SUCCESS":
      return { ...state, loading: false, fileUploadInfo: action.payload };
    case "UPLOAD_FILE_FAIL":
      return { ...state, loading: false, error: action.payload };

    //PRODUCT
    case "PRODUCT_INFO_INIT":
      return { ...state, loading: true };
    case "PRODUCT_INFO_SUCCESS":
      return { ...state, loading: false, productInfo: action.payload };
    case "PRODUCT_INFO_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "PRODUCT_INFO_RESET":
      const { productInfo, ...sta } = state;
      return { ...sta, loading: false };

    case "PRODUCT_GALLERY_RESET":
      const {
        fileUploadInfo,
        galleryInfo,
        imageInfo,
        discountBase,
        discountBaseGet,
        ...stat
      } = state;
      return { ...stat, loading: false };

    //CATEGORY
    case "CATEGORY_INFO_INIT":
      return { ...state, loading: true };
    case "CATEGORY_INFO_SUCCESS":
      return { ...state, loading: false, categoryInfo: action.payload };
    case "CATEGORY_INFO_FAIL":
      return { ...state, loading: false, error: action.payload };

    //UPLOAD IMAGE GALLERY
    case "GALLERY_IMAGE_INIT":
      return { ...state, loading: true };
    case "GALLERY_IMAGE_SUCCESS":
      return { ...state, loading: false, imageInfo: action.payload };
    case "GALLERY_IMAGE_FAIL":
      return { ...state, loading: false, error: action.payload };

    //ADD IMAGE TO GALLERY PRODUCT
    case "ADD_GALLERY_IMAGE_PRODUCT_INIT":
      return { ...state, loading: true };
    case "ADD_GALLERY_IMAGE_PRODUCT_SUCCESS":
      return { ...state, loading: false, galleryInfo: action.payload };
    case "ADD_GALLERY_IMAGE_PRODUCT_FAIL":
      return { ...state, loading: false, error: action.payload };

    //ADD IMAGE TO GALLERY PRODUCT
    case "DELETE_GALLERY_IMAGE_PRODUCT_INIT":
      return { ...state, loading: true };
    case "DELETE_GALLERY_IMAGE_PRODUCT_SUCCESS":
      return { ...state, loading: false, deleteGalleryInfo: action.payload };
    case "DELETE_GALLERY_IMAGE_PRODUCT_FAIL":
      return { ...state, loading: false, error: action.payload };

    //
    case "GET_INFO_VENDOR_INIT":
      return { ...state, loading: true };
    case "GET_INFO_VENDOR_SUCCESS":
      return { ...state, loading: false, vendorInfoDash: action.payload };
    case "GET_INFO_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    //GET PRODUCTS VENDOR
    case "GET_ALL_PRODUCT_VENDOR_INIT":
      return { ...state, loading: true };
    case "DOWNLOAD_PRODUCT_VENDOR":
      return {
        ...state,
        loading: true,
        allProducts: [...state.allProducts, ...action.payload],
      };
    case "DOWNLOAD_PRODUCT_VENDOR-1":
      return {
        ...state,
        loading: true,
        allProducts: action.payload,
      };
    case "GET_ALL_PRODUCT_VENDOR_SUCCESS":
      return { ...state, loading: false, vendorAllProduct: action.payload };
    case "GET_ALL_PRODUCT_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    //DELETE PRODUCT
    case "DELETE_PRODUCT_INIT":
      return { ...state, loading: true };
    case "DELETE_PRODUCT_SUCCESS":
      return { ...state, loading: false, allProducts: action.payload };
    case "DELETE_PRODUCT_FAIL":
      return { ...state, loading: false, error: action.payload };

    //UPDATE DATA SETTINGS VENDOR
    case "UPDATE_INFO_VENDOR_INIT":
      return { ...state, loading: true };
    case "UPDATE_INFO_VENDOR_SUCCESS":
      return { ...state, loading: false, updateVendor: action.payload };
    case "UPDATE_INFO_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    //UPDATE PASSWORD
    case "UPDATE_PASSWORD_VENDOR_INIT":
      return { ...state, loading: true };
    case "UPDATE_PASSWORD_VENDOR_SUCCESS":
      return { ...state, loading: false, updatePass: action.payload };
    case "UPDATE_PASSWORD_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    //GET PRODUCT TO UPDATE
    case "GET_EDIT_PRODUCT_VENDOR_INIT":
      return { ...state, loading: true };
    case "GET_EDIT_PRODUCT_VENDOR_SUCCESS":
      return {
        ...state,
        productEdit: true,
        dataProductEdit: action.payload,
        loading: false,
      };
    case "GET_EDIT_PRODUCT_VENDOR_FAIL":
      return {
        ...state,
        loading: false,
        productEdit: null,
        dataProductEdit: null,
        error: action.payload,
      };

    //UPDATE PASSWORD
    case "UPDATE_PRODUCT_VENDOR_INIT":
      return { ...state, loading: true };
    // case "UPDATE_PRODUCT_VENDOR_SUCCESS":
    //   return {
    //     ...state,
    //     productEdit: true,
    //     dataProductEdit: action.payload,
    //     loading: false,
    //   };
    case "UPDATE_PRODUCT_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "RESET_REDUX_PRODUCT_EDIT_VENDOR":
      return {
        ...state,
        loading: false,
        productEdit: null,
        dataProductEdit: null,
      };

    //UPDATE PASSWORD
    case "VERIFY_LINK_STRIPE_VENDOR_INIT":
      return { ...state, loading: true };
    case "VERIFY_LINK_STRIPE_VENDOR_SUCCESS":
      return { ...state, loading: false, statusLink: action.payload };
    case "VERIFY_LINK_STRIPE_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    //UPDATE PASSWORD
    case "ADD_DISCOUNT_VENDOR_INIT":
      return { ...state, loading: true };
    case "ADD_DISCOUNT_VENDOR_SUCCESS":
      return { ...state, loading: false, discountBase: action.payload };
    case "ADD_DISCOUNT_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    //UPDATE PASSWORD
    case "GET_PROMOTION_VENDOR_INIT":
      return { ...state, loading: true };
    case "GET_PROMOTION_VENDOR_SUCCESS":
      return { ...state, loading: false, discountBaseGet: action.payload };
    case "GET_PROMOTION_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    //UPDATE PASSWORD
    case "GET_LINK_STRIPE_VENDOR_INIT":
      return { ...state, loading: true };
    case "GET_LINK_STRIPE_VENDOR_SUCCESS":
      return { ...state, loading: false };
    case "GET_LINK_STRIPE_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    //GET ALL DEALS FOR PRODUCT
    case "GET_DEALS_PRODUCT_VENDOR_INIT":
      return { ...state, loading: true };
    case "GET_DEALS_PRODUCT_VENDOR_SUCCESS":
      return { ...state, loading: false, dealsProduct: action.payload };
    case "GET_DEALS_PRODUCT_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    //GET CELEBRITY DEALS FOR PRODUCT
    case "GET_CELEBRITYES_PRODUCT_VENDOR_INIT":
      return { ...state, loading: true };
    case "GET_CELEBRITYES_PRODUCT_VENDOR_SUCCESS":
      return {
        ...state,
        loading: false,
        allCelebrityesForProduct: action.payload,
      };
    case "GET_CELEBRITYES_PRODUCT_VENDOR_FAIL":
      return { ...state, loading: false, error: action.payload };

    //ADD THE ID PROMOTION IN STORAGE
    case "STATE_INIT_DASHBOARD_VENDOR_INIT":
      return { ...state, loading: true };

    case "ADD_PROMOTION_PRODUCT_VENDOR":
      return { ...state, loading: false, idPromotion: action.payload };

    case "ADD_INDEX_PRODUCT_VENDOR":
      return { ...state, loading: false, index: action.payload };

    case "ADD_PROMOTION_INFO_VENDOR_SUCCESS":
      return {
        ...state,
        loading: false,
        infoPromotion: {
          ...action.payload,
          celebritiesPromotions: [...action.payload2],
        },
      };

    case "GET_CELEBRITIES_FOR_PRODUCT_VENDOR":
      return { ...state, loading: false, celebritiesProduct: action.payload };

    case "GET_ORDERS_VENDOR":
      return { ...state, loading: false, ordersVendor: action.payload };

    case "CREATE_PROMOTION_PRODUCT":
      return { ...state, loading: false };

    case "SELECT_CONTENT_DASHBOARD_VENDOR":
      return { ...state, loading: false, selectContent: action.payload  };

    case "STATE_ERROR_DASHBOARD_VENDOR":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
