import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import IconDeleteImage from "./assets/icon-delete-image.png";
import ImageNoPreview from "./assets/no-preview.png";
import ImageUploadImage from "./assets/image-upload-image.png";
import { ToastContainer, toast } from "react-toastify";
import { FaTrash } from "react-icons/fa";
import {
  getCategories,
  uploadFile,
  addProduct,
  uploadImageGallery,
  addImageToGallery,
  deleteImageToGallery,
  getEditProduct,
  editProduct,
  resetEditProduct,
  resetInfoProduct,
  resetGalleryInfo,
  // addDiscount,
  getDiscountProduct,
} from "../../Actions/DashboardVendor/DashboarVendor";

import "./ContentProduct.css";
import "react-toastify/dist/ReactToastify.css";

export default function ContentProduct() {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxDashboardVendor = useSelector((state) => state.dashboardVendor);
  // const [imageProduct, setImageProduct] = useState(null);
  const [previewImage, setpreviewImage] = useState(null);
  const [itemHistory, setItemHistory] = useState(null);
  const [licensing, setLicensing] = useState(null);
  const [materials, setMaterials] = useState(null);
  const [reviewsProductFile, setReviewsProductFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [galleryProduct, setGalleryProduct] = useState([]);
  const [imageSelectGallery, setImageSelectGallery] = useState(null);
  const [discountProduct, setDiscountProduct] = useState(0);
  const [rateDiscountProduct, setRateDiscountProduct] = useState(0);
  const [existDiscount, setExistDiscount] = useState(false);
  const [downloadFiles, setDownloadFiles] = useState({
    itemHistory: null,
    licensing: null,
    materials: null,
    reviewsProductFile: null,
  });

  const [valueKey, setValueKey] = useState({
    key: "",
    description: "",
  });

  const [dataProduct, setDataProduct] = useState({
    id: "",
    name: "",
    category: "",
    description: "",
    longDescription: "",
    amount: "",
    tax: "",
    stock: 0,
    discount: 0,
    // shipping: "",
    weight: "",
    length: "",
    width: "",
    height: ""
  });

  const handleChangeFormProduct = (e) => {
    setDataProduct({
      ...dataProduct,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickFormProduct = (e) => {
    if (
      !dataProduct.amount ||
      !dataProduct.category ||
      !dataProduct.description ||
      !dataProduct.longDescription ||
      !dataProduct.name ||
      !dataProduct.tax ||
      !dataProduct.stock ||
      !dataProduct.discount ||
      // !dataProduct.shipping ||
      !previewImage ||
      !itemHistory ||
      !licensing ||
      !materials ||
      !reviewsProductFile ||
      !dataProduct.weight ||
      !dataProduct.length ||
      !dataProduct.width ||
      !dataProduct.height ||
      attributes.length < 1
    )

      // return new Toast("There is a lot of toasts!", Toast.TYPE_ERROR, Toast.TIME_NORMAL);
      return toast.error("The data is incomplate", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    let atributesBase = {};
    for (let i = 0; i < attributes.length; i++) {
      const attr = attributes[i];
      atributesBase = { ...atributesBase, [attr.key]: attr.description };
    }
    // console.log(dataProduct);
    dispatch(
      addProduct(
        dataProduct.amount,
        dataProduct.category,
        dataProduct.description,
        dataProduct.longDescription,
        dataProduct.name,
        dataProduct.tax,
        dataProduct.stock,
        previewImage,
        itemHistory,
        licensing,
        materials,
        reviewsProductFile,
        atributesBase,
        dataProduct.discount,
        // dataProduct.shipping,
        dataProduct.weight,
        dataProduct.length,
        dataProduct.width,
        dataProduct.height,
      )
    );
  };

  const handleChangeFile = async (e) => {
    switch (e.target.name) {
      case "product-image":
        return dispatch(uploadFile(e.target.files[0]));
      case "item-history":
        return setItemHistory(e.target.files[0]);
      case "licensing":
        return setLicensing(e.target.files[0]);
      case "materials":
        return setMaterials(e.target.files[0]);
      default:
        return setReviewsProductFile(e.target.files[0]);
    }
  };

  const handleClickDashboardRedirect = () => {
    const product = JSON.parse(localStorage.getItem("edit-product"));
    if (product) {
      localStorage.removeItem("edit-product");
      setDataProduct({
        id: "",
        name: "",
        category: "",
        description: "",
        longDescription: "",
        amount: "",
        tax: "",
        stock: 0,
        discount: 0,
        // shipping: "",
      });
      setDownloadFiles({
        itemHistory: null,
        licensing: null,
        materials: null,
        reviewsProductFile: null,
      });
      setAttributes([]);
      setGalleryProduct([]);
      setpreviewImage(null);
      setImageSelectGallery(null);
      navigate("/vendor-dashboard-info");
      dispatch(resetEditProduct());
      dispatch(resetGalleryInfo());
      setDiscountProduct(0);
      setRateDiscountProduct(0);
    } else {
      navigate("/vendor-dashboard");
      setpreviewImage(null);
      setImageSelectGallery(null);
      //Reset fileUploadInfo and galleryInfo and imageInfo
      dispatch(resetGalleryInfo());
      setDiscountProduct(0);
      setRateDiscountProduct(0);
    }
  };

  const handleChangeImageGallery = async (e) => {
    return dispatch(uploadImageGallery(e.target.files[0]));
  };

  const obtenerEditor = (value) => {
    // setEditor(value);
    setDataProduct({ ...dataProduct, longDescription: value });
  };

  const handleClickAddValueKey = () => {
    if (!valueKey.description || !valueKey.key) return;
    // console.log(valueKey);
    setAttributes([...attributes, valueKey]);
    setValueKey({
      key: "",
      description: "",
    });
  };

  const handleClickDeleteValueKey = (attr, i) => {
    const attrArray = [...attributes];
    attrArray.splice(i, 1);
    setAttributes(attrArray);
  };

  const handleClickAddImageGallery = () => {
    dispatch(addImageToGallery(dataProduct.id, imageSelectGallery));
    setImageSelectGallery(null);
  };

  const handleClickFormProductEdit = (e) => {
    if (
      !dataProduct.amount ||
      !dataProduct.category ||
      !dataProduct.description ||
      !dataProduct.longDescription ||
      !dataProduct.name ||
      !dataProduct.tax ||
      !dataProduct.stock ||
      !dataProduct.discount ||
      // !dataProduct.shipping ||
      !previewImage ||
      !downloadFiles.itemHistory ||
      !downloadFiles.licensing ||
      !downloadFiles.materials ||
      !downloadFiles.reviewsProductFile ||
      !dataProduct.weight ||
      !dataProduct.length ||
      !dataProduct.width ||
      !dataProduct.height ||
      attributes.length < 1
    )
      return toast.error("The data is incomplate", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    let atributesBase = {};
    for (let i = 0; i < attributes.length; i++) {
      const attr = attributes[i];
      atributesBase = { ...atributesBase, [attr.key]: attr.description };
    }
    dispatch(
      editProduct(
        dataProduct.id,
        dataProduct.amount,
        dataProduct.category,
        dataProduct.description,
        dataProduct.longDescription,
        dataProduct.name,
        dataProduct.tax,
        dataProduct.stock,
        dataProduct.discount,
        // dataProduct.shipping,
        previewImage,
        itemHistory ? itemHistory : null,
        licensing ? licensing : null,
        materials ? materials : null,
        reviewsProductFile ? reviewsProductFile : null,
        atributesBase,
        dataProduct.weight,
        dataProduct.length,
        dataProduct.width,
        dataProduct.height,
        (data, error) => {
          if (error) {
            console.log(error);
            toast.error("An error occurred, please try again.");
          } else {
            toast.success("The product is edited");
          }
        }
      )
    );
  };

  const handleDeleteImageGasllery = (image) => {
    dispatch(deleteImageToGallery(dataProduct.id, image.id));
  };

  // const handleChangeCalculateDiscount = (e) => {
  //   const discount = e.target.value;
  //   setDiscountProduct(discount);
  //   if (discount !== "") {
  //     const amountProduct = dataProduct.amount;
  //     const discountRate =
  //       ((amountProduct - parseFloat(discount)) * 100) / dataProduct.amount;
  //     console.log(discountRate);
  //     setRateDiscountProduct(parseFloat(discountRate).toFixed(2));
  //   }
  // };

  // const handleChangeCalculateRateDiscount = (e) => {
  //   const rate = e.target.value;
  //   setRateDiscountProduct(e.target.value);
  //   if (rate !== "") {
  //     const resDiscount =
  //       dataProduct.amount *
  //       parseFloat(rate > 0 && rate < 10 ? `0.0${rate}` : `0.${rate}`);
  //     const discount = parseFloat(dataProduct.amount - resDiscount);
  //     setDiscountProduct(discount.toFixed(2));
  //   }
  // };

  // const handleClickDiscount = (e) => {
  //   if (!discountProduct || !rateDiscountProduct)
  //     toast.error("Please add a discount.");
  //   dispatch(addDiscount(dataProduct.id, discountProduct, rateDiscountProduct));
  // };

  const getProductEdit = () => {
    const product = JSON.parse(localStorage.getItem("edit-product"));
    if (product) {
      dispatch(getEditProduct(product.id));
      dispatch(getDiscountProduct(product.id));
    }
  };

  useEffect(() => {
    getProductEdit();
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (reduxDashboardVendor && reduxDashboardVendor.dataProductEdit) {
      console.log("Data edit", reduxDashboardVendor.dataProductEdit.data);
      setDataProduct({
        id: reduxDashboardVendor.dataProductEdit.data.id,
        name: reduxDashboardVendor.dataProductEdit.data.name,
        category: reduxDashboardVendor.dataProductEdit.data.category,
        description: reduxDashboardVendor.dataProductEdit.data.description,
        longDescription:
          reduxDashboardVendor.dataProductEdit.data.longDescription,
        amount: reduxDashboardVendor.dataProductEdit.data.amount,
        tax: reduxDashboardVendor.dataProductEdit.data.tax,
        stock: reduxDashboardVendor.dataProductEdit.data.stock,
        discount: reduxDashboardVendor.dataProductEdit.data.discount,
        // shipping: reduxDashboardVendor.dataProductEdit.data.shipping,

        weight: reduxDashboardVendor.dataProductEdit.data.shippingInfo.weight,
        length: reduxDashboardVendor.dataProductEdit.data.shippingInfo.length,
        width: reduxDashboardVendor.dataProductEdit.data.shippingInfo.width,
        height: reduxDashboardVendor.dataProductEdit.data.shippingInfo.height,

      });
      setpreviewImage(reduxDashboardVendor.dataProductEdit.data.image);
      setGalleryProduct(reduxDashboardVendor.dataProductEdit.data.gallery);

      setDownloadFiles({
        itemHistory: reduxDashboardVendor.dataProductEdit.data.itemHistory
          ? reduxDashboardVendor.dataProductEdit.data.itemHistory
          : null,
        licensing: reduxDashboardVendor.dataProductEdit.data.licensing
          ? reduxDashboardVendor.dataProductEdit.data.licensing
          : null,
        materials: reduxDashboardVendor.dataProductEdit.data.materials
          ? reduxDashboardVendor.dataProductEdit.data.materials
          : null,
        reviewsProductFile: reduxDashboardVendor.dataProductEdit.data
          .reviewsProductFile
          ? reduxDashboardVendor.dataProductEdit.data.reviewsProductFile
          : null,
      });
      const att = reduxDashboardVendor.dataProductEdit.data.attributes;
      let dataFinalAtt = [];
      for (const [clave, valor] of Object.entries(att)) {
        dataFinalAtt.push({
          key: clave,
          description: valor,
        });
      }
      setAttributes(dataFinalAtt);
    }
  }, [reduxDashboardVendor.dataProductEdit]);

  useEffect(() => {
    if (reduxDashboardVendor && reduxDashboardVendor.deleteGalleryInfo)
      setGalleryProduct(reduxDashboardVendor.deleteGalleryInfo.data);
  }, [reduxDashboardVendor.deleteGalleryInfo]);

  useEffect(() => {
    if (reduxDashboardVendor && reduxDashboardVendor.galleryInfo) {
      setGalleryProduct(reduxDashboardVendor.galleryInfo.data);
    }
  }, [reduxDashboardVendor.galleryInfo]);

  useEffect(() => {
    //Register producto
    if (reduxDashboardVendor && reduxDashboardVendor.productInfo) {
      setDataProduct(reduxDashboardVendor.productInfo.data);
      toast.success("The product is registered.");
      //Resetear productInfo de redux
      dispatch(resetInfoProduct());
    }
  }, [reduxDashboardVendor.productInfo]);

  useEffect(() => {
    //Get categories
    if (reduxDashboardVendor && reduxDashboardVendor.categoryInfo)
      setCategories(reduxDashboardVendor.categoryInfo.data);
  }, [reduxDashboardVendor.categoryInfo]);

  useEffect(() => {
    //Upload image
    if (reduxDashboardVendor && reduxDashboardVendor.fileUploadInfo) {
      setpreviewImage(reduxDashboardVendor.fileUploadInfo.data[0].filepath);
    }
  }, [reduxDashboardVendor.fileUploadInfo]);

  useEffect(() => {
    //Upload image to product
    if (reduxDashboardVendor && reduxDashboardVendor.imageInfo) {
      setImageSelectGallery(reduxDashboardVendor.imageInfo.data[0].filepath);
    }
    //Get gallery
  }, [reduxDashboardVendor.imageInfo]);

  useEffect(() => {
    if (reduxDashboardVendor && reduxDashboardVendor.discountBase) {
      toast.success("Discount is registered.");
      setExistDiscount(true);
      setDiscountProduct(reduxDashboardVendor.discountBase.data.discountPrice);
      setRateDiscountProduct(
        reduxDashboardVendor.discountBase.data.discountRate
      );
    }
  }, [reduxDashboardVendor.discountBase]);

  useEffect(() => {
    if (
      reduxDashboardVendor &&
      reduxDashboardVendor.discountBaseGet &&
      reduxDashboardVendor.discountBaseGet.data !== null
    ) {
      setExistDiscount(true);
      setDiscountProduct(
        reduxDashboardVendor.discountBaseGet.data.discountPrice
      );
      setRateDiscountProduct(
        reduxDashboardVendor.discountBaseGet.data.discountRate
      );
    }
  }, [reduxDashboardVendor.discountBaseGet]);

  return (
    <div>
      {reduxDashboardVendor.loading ? <div className="se-pre-con"></div> : ""}
      <ToastContainer />
      <section className="dashboard_container2">
        <span className="menuIcon"></span>
        <div className="celebrity-myearnig-main">
          <section className="myearning-tabs-main">
            <div>
              {/* <!-- Nav tabs --> */}
              <ul
                className="nav nav-tabs content__product-vendor-tabs-information ml-3"
                role="tablist"
              >
                <li
                  onClick={() => handleClickDashboardRedirect()}
                  className="nav-item cursor-pointer"
                >
                  <a /* to="/vendor-dashboard" */ className="nav-link">
                    Dashboard
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/product-vendor" className="nav-link">
                    Product
                  </Link>
                </li>
              </ul>
            </div>

            <div className="myearning-tabs-content">
              <div className="content__product-vendor-tabs">
                <p className="content__product-vendor-tabs-title-section">
                  Add Product Details
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="content__product-vendor-tabs-label-style">
                      Enter product name
                    </label>
                    <input
                      className="form-control content__product-vendor-tabs-input-style"
                      placeholder="Type"
                      name="name"
                      value={dataProduct.name}
                      onChange={(e) => handleChangeFormProduct(e)}
                    />
                    <label
                      className="content__product-vendor-tabs-label-style"
                      for="inputState"
                    >
                      Category
                    </label>
                    <select
                      id="inputState"
                      className="form-control content__product-vendor-tabs-select-style"
                      placeholder="watch"
                      name="category"
                      value={dataProduct.category}
                      onChange={(e) => handleChangeFormProduct(e)}
                    >
                      <option
                        className="content__product-vendor-tabs-option-style-hidden"
                        selected
                      >
                        Select category
                      </option>
                      {categories.map((category, i) => (
                        <option
                          key={i}
                          className="content__product-vendor-tabs-option-style-hidden"
                          selected
                          value={category.title}
                        >
                          {category.title}
                        </option>
                      ))}
                    </select>

                    <label className="content__product-vendor-tabs-label-style">
                      Stock
                    </label>
                    <input
                      className="form-control content__product-vendor-tabs-input-style"
                      placeholder="Stock"
                      value={dataProduct.stock}
                      name="stock"
                      type="number"
                      min="0"
                      onChange={(e) => handleChangeFormProduct(e)}
                    />

                    <label className="content__product-vendor-tabs-label-style">
                      Amount
                    </label>
                    <input
                      className="form-control content__product-vendor-tabs-input-style"
                      placeholder="Amount"
                      name="amount"
                      type="number"
                      value={dataProduct.amount}
                      onChange={(e) => handleChangeFormProduct(e)}
                    />

                    <label className="content__product-vendor-tabs-label-style">
                      Discount rate
                    </label>
                    <input
                      className="form-control content__product-vendor-tabs-input-style"
                      placeholder="Discount rate"
                      name="discount"
                      type="number"
                      value={dataProduct.discount}
                      onChange={(e) => handleChangeFormProduct(e)}
                    />

                    {/* <label className="content__product-vendor-tabs-label-style">
                      Shipping price
                    </label>
                    <input
                      className="form-control content__product-vendor-tabs-input-style"
                      placeholder="Shipping price"
                      name="shipping"
                      type="number"
                      value={dataProduct.shipping}
                      onChange={(e) => handleChangeFormProduct(e)}
                    /> */}

                    <label
                      className="content__product-vendor-tabs-label-style"
                      for="inputState"
                    >
                      Tax
                    </label>
                    <select
                      id="inputState"
                      className="form-control content__product-vendor-tabs-select-style"
                      placeholder="Tax"
                      name="tax"
                      value={dataProduct.tax}
                      onChange={(e) => handleChangeFormProduct(e)}
                    >
                      <option
                        className="content__product-vendor-tabs-option-style-hidden"
                        selected
                      >
                        Tax
                      </option>
                      <option value="Included">Included</option>
                      <option value="Excluded">Excluded</option>
                      <option value="N/A">N/A</option>
                    </select>

                    <label className="content__product-vendor-tabs-label-style">
                      Weight
                    </label>
                    <input
                      className="form-control content__product-vendor-tabs-input-style"
                      placeholder="Weight"
                      name="weight"
                      type="number"
                      value={dataProduct.weight}
                      onChange={(e) => handleChangeFormProduct(e)}
                    />

                    <label className="content__product-vendor-tabs-label-style">
                      Length
                    </label>
                    <input
                      className="form-control content__product-vendor-tabs-input-style"
                      placeholder="Length"
                      name="length"
                      type="number"
                      value={dataProduct.length}
                      onChange={(e) => handleChangeFormProduct(e)}
                    />

                    <label className="content__product-vendor-tabs-label-style">
                      Width
                    </label>
                    <input
                      className="form-control content__product-vendor-tabs-input-style"
                      placeholder="Width"
                      name="width"
                      type="number"
                      value={dataProduct.width}
                      onChange={(e) => handleChangeFormProduct(e)}
                    />

                    <label className="content__product-vendor-tabs-label-style">
                      Height
                    </label>
                    <input
                      className="form-control content__product-vendor-tabs-input-style"
                      placeholder="Height"
                      name="height"
                      type="number"
                      value={dataProduct.height}
                      onChange={(e) => handleChangeFormProduct(e)}
                    />

                    <label className="content__product-vendor-tabs-label-style">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="validationTextarea"
                      required
                      name="description"
                      value={dataProduct.description}
                      onChange={(e) => handleChangeFormProduct(e)}
                    ></textarea>
                    <div className="invalid-feedback">....</div>
                  </div>

                  {/* Product info image */}
                  <div className="col-lg-6">
                    <label className="content__product-vendor-tabs-label-style">
                      Product Image
                    </label>
                    <div className="row">
                      <div className="col-lg-5 col-md-6">
                        <div className="content__product-vendor-tabs-image-content">
                          <img
                            src={
                              previewImage !== null
                                ? previewImage
                                : ImageNoPreview
                            }
                            alt="product-image"
                            className="img-fluid h-100"
                          />
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6">
                        <div className="content__product-vendor-tabs-image-content-upload">
                          <input
                            name="product-image"
                            type="file"
                            onChange={(e) => handleChangeFile(e)}
                          />
                          <div>
                            <img src={ImageUploadImage} className="img-fluid" />
                          </div>
                          <p>Click upload</p>
                        </div>
                      </div>
                    </div>

                    <label className="content__product-vendor-tabs-label-style mt-1">
                      Item history
                    </label>
                    <div className="d-flex">
                      <input
                        className="form-control content__product-vendor-tabs-input-style"
                        placeholder="Stock"
                        type="file"
                        name="item-history"
                        onChange={(e) => handleChangeFile(e)}
                      />
                      {downloadFiles.itemHistory ? (
                        <a href={downloadFiles.itemHistory} target="_blank">
                          <button className="btn btn-info h-100">
                            Download
                          </button>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>

                    <label className="content__product-vendor-tabs-label-style">
                      Licensing
                    </label>
                    <div className="d-flex">
                      <input
                        className="form-control content__product-vendor-tabs-input-style"
                        placeholder="Stock"
                        type="file"
                        name="licensing"
                        onChange={(e) => handleChangeFile(e)}
                      />

                      {downloadFiles.licensing ? (
                        <a href={downloadFiles.licensing} target="_blank">
                          <button className="btn btn-info h-100">
                            Download
                          </button>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>

                    <label className="content__product-vendor-tabs-label-style">
                      Materials
                    </label>
                    <div className="d-flex">
                      <input
                        className="form-control content__product-vendor-tabs-input-style"
                        placeholder="Stock"
                        type="file"
                        name="materials"
                        onChange={(e) => handleChangeFile(e)}
                      />
                      {downloadFiles.materials ? (
                        <a href={downloadFiles.materials} target="_blank">
                          <button className="btn btn-info h-100">
                            Download
                          </button>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>

                    <label className="content__product-vendor-tabs-label-style">
                      Reviews of product
                    </label>
                    <div className="d-flex">
                      <input
                        className="form-control content__product-vendor-tabs-input-style"
                        placeholder="Stock"
                        type="file"
                        name="reviews-products"
                        onChange={(e) => handleChangeFile(e)}
                      />
                      {downloadFiles.reviewsProductFile ? (
                        <a
                          href={downloadFiles.reviewsProductFile}
                          target="_blank"
                        >
                          <button className="btn btn-info h-100">
                            Download
                          </button>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row w-100">
                    <div className="col-lg-12 ml-3">
                      <div className="content__product-vendor-tabs-content  mt-3">
                        <ul
                          className="nav nav-tabs content__product-vendor-tabs-information"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link active"
                              id="description-tab"
                              data-toggle="tab"
                              href="#description"
                              role="tab"
                              aria-controls="description"
                              aria-selected="true"
                            >
                              Long description
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="profile-tab"
                              data-toggle="tab"
                              href="#profile"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                            >
                              Technical informations
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content w-100" id="myTabContent">
                          <div
                            className="tab-pane fade show active text-area-content-heigth"
                            id="description"
                            role="tabpanel"
                            aria-labelledby="description-tab"
                          >
                            <Editor
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              value={dataProduct.longDescription}
                              onEditorChange={obtenerEditor}
                              initialValue="<p>Describe your product.</p>"
                              init={{
                                height: "100%",
                                width: "100%",
                                menubar: true,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | " +
                                  "bold italic backcolor | alignleft aligncenter " +
                                  "alignright alignjustify | bullist numlist outdent indent | " +
                                  "removeformat | help",
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              }}
                            />
                            <div className="invalid-feedback"></div>
                          </div>

                          <div
                            className="tab-pane fade text-area-content-heigth"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            <div className="row my-2">
                              <div className="col-lg-2">
                                <label>Key</label>
                                <input
                                  value={valueKey.key}
                                  onChange={(e) =>
                                    setValueKey({
                                      ...valueKey,
                                      key: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                />
                              </div>
                              <div className="col-lg-3">
                                <label>Description</label>
                                <input
                                  value={valueKey.description}
                                  onChange={(e) =>
                                    setValueKey({
                                      ...valueKey,
                                      description: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                />
                              </div>
                              <div className="col-lg-2 content__product-tecnical-information-content-button">
                                <button
                                  onClick={() => handleClickAddValueKey()}
                                  className="content__product-tecnical-information-button"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Key</th>
                                      <th scope="col">Description</th>
                                      <th scope="col">Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {attributes.map((attr, i) => {
                                      return (
                                        <tr key={i}>
                                          <th scope="row">{i + 1}</th>
                                          <td>{attr.key}</td>
                                          <td>{attr.description}</td>
                                          <td>
                                            <button
                                              onClick={() =>
                                                handleClickDeleteValueKey(
                                                  attr,
                                                  i
                                                )
                                              }
                                              className="btn btn-danger"
                                            >
                                              <FaTrash />
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="content__product-vendor-tabs-button">
                        {dataProduct.id ? (
                          <button
                            onClick={(e) => handleClickFormProductEdit(e)}
                            className="content__product-vendor-tabs-button-submit"
                          >
                            Edit Product
                          </button>
                        ) : (
                          <button
                            onClick={(e) => handleClickFormProduct(e)}
                            className="content__product-vendor-tabs-button-submit"
                          >
                            Add Product
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* {dataProduct.id ? (
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="content__product-vendor-tabs-label-style">
                        Discount
                      </label>
                      <input
                        className="form-control content__product-vendor-tabs-input-style"
                        placeholder="Discount product"
                        name="name"
                        value={discountProduct}
                        onChange={(e) => handleChangeCalculateDiscount(e)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        className="content__product-vendor-tabs-label-style"
                        for="inputState"
                      >
                        Rate discount
                      </label>

                      <input
                        className="form-control content__product-vendor-tabs-input-style"
                        placeholder="Rate discount"
                        name="name"
                        value={rateDiscountProduct}
                        onChange={(e) => handleChangeCalculateRateDiscount(e)}
                      />
                      <div className="content__product-vendor-tabs-button">
                        <button
                          onClick={(e) => handleClickDiscount(e)}
                          className="content__product-vendor-tabs-button-submit float-right"
                        >
                          {existDiscount ? "Edit discount" : "Add discount"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {dataProduct.id ? (
                  <>
                    <p className="content__product-vendor-tabs-title-section mt-3">
                      Add Gallery
                    </p>
                    <div>
                      <div className="row ml-3">
                        <div className="col-lg-9 content__product-vendor-tabs-size-content-content">
                          {galleryProduct.map((image, i) => {
                            return (
                              <div key={i}>
                                <div className="icon-delete-product-gallery">
                                  <img
                                    onClick={() =>
                                      handleDeleteImageGasllery(image)
                                    }
                                    className="img-fluid "
                                    src={IconDeleteImage}
                                  />
                                </div>
                                <div className="content__product-vendor-tabs-image-content-gallery">
                                  <img
                                    src={image.image}
                                    alt={`gallery-image-${i}`}
                                    className="img-fluid h-100"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <div className="col-lg-3">
                          {imageSelectGallery === null ? (
                            <>
                              <div className="content__product-vendor-tabs-image-content-upload">
                                <input
                                  onChange={(e) => handleChangeImageGallery(e)}
                                  type="file"
                                />
                                <div>
                                  <img
                                    src={ImageUploadImage}
                                    className="img-fluid"
                                  />
                                </div>
                                <p>Click upload</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="content__product-vendor-tabs-image-content">
                                <img
                                  src={imageSelectGallery}
                                  alt="product-image"
                                  className="img-fluid h-100"
                                />
                              </div>
                            </>
                          )}
                          <div className="content__product-vendor-tabs-button mr-5">
                            <button
                              onClick={() => handleClickAddImageGallery()}
                              className="content__product-vendor-tabs-button-submit"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
