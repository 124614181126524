import React from 'react';
import CartBanner from '../../components/CartBanner/CartBanner';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import SignupLink from '../../components/SignupLink/SignupLink';
import TrackOrder from '../../components/TrackOrder/TrackOrder';

 

export default function OrderTracking() {

  return <div>
    <Header/>
     <CartBanner text={"Checkout"}/>
    <TrackOrder/>
    <SignupLink/>
    <Footer/>
  </div>
}