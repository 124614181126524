import React, { useState, useEffect } from "react";
import "./Settings.css";

// import IconEdit from "./assets/Grupo-1.png";

import {
  // FaBuilding,
  // FaBuffer,
  // FaWindowMaximize,
  // FaUserAlt,
  // FaEnvelope,
  FaLockOpen,
} from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import {
  // uploadFile,
  // updateInfoVendor,
  updatePasswordVendor,
  verifyLinkStripe,
  consultLinkStripeApi,
} from "../../Actions/DashboardVendor/DashboarVendor";

export default function Settings() {
  const storage = JSON.parse(localStorage.getItem("userInfo"));

  const dispatch = useDispatch();

  const reduxDashboardVendor = useSelector((state) => state.dashboardVendor);

  const [datosVendor, setDatosVendor] = useState({
    name: "",
    productTypes: "",
    website: "",
    contactEmail: "",
    representatorName: "",
    businessType: "",
  });

  const [dataPassword, setDataPassword] = useState({
    password: "",
    cpassword: "",
  });

  const [verificadorLink, setVerificadorLink] = useState("pending");

  const [previewImage, setPreviewImage] = useState(null);

  // const handleChangeInput = (e) => {
  //   setDatosVendor({ ...datosVendor, [e.target.name]: e.target.value });
  // };

  const handleChangeInputPass = (e) => {
    setDataPassword({ ...dataPassword, [e.target.name]: e.target.value });
  };

  // const handleClickInfoVendor = () => {
  //   if (
  //     !datosVendor.businessType ||
  //     !datosVendor.contactEmail ||
  //     !datosVendor.name ||
  //     !datosVendor.productTypes ||
  //     !datosVendor.representatorName ||
  //     !datosVendor.website
  //   )
  //     toast.error("Please fill in all the fields");

  //   dispatch(
  //     updateInfoVendor(datosVendor, previewImage, (data) => {
  //       console.log(data);
  //       if (data) {
  //         toast.success(data.message);
  //       }
  //     })
  //   );
  // };

  const handleClickPass = () => {
    console.log(dataPassword);
    if (dataPassword.cpassword !== dataPassword.password)
      toast.error("Passwords do not match");
    dispatch(
      updatePasswordVendor(dataPassword, (data, error) => {
        if (error) {
          toast.error(error.message);
        } else {
          toast.success("The password is updated");
          setDataPassword({
            password: "",
            cpassword: "",
          });
        }
      })
    );
  };

  const handleClickStripeVinculate = async (e) => {
    dispatch(consultLinkStripeApi((data,error) => {
      if(error){
        toast.error(error.message);
      }else{
        if(data.statusCode === 200){
          console.log(data.data.link)
          window.location.href = data.data.link;
        }
      }
    }))
  };

  useEffect(() => {
    dispatch(verifyLinkStripe());
  }, []);

  useEffect(() => {
    if (reduxDashboardVendor.statusLink) {
      setVerificadorLink(reduxDashboardVendor.statusLink.data.status);
      // setVerificadorLink("Active");
    }
  }, [reduxDashboardVendor.statusLink]);

  useEffect(() => {
    setPreviewImage(
      storage.profile.imagePath ? storage.profile.imagePath : null
    );
    setDatosVendor({
      name: storage.profile.businessInfo.name
        ? storage.profile.businessInfo.name
        : "",
      productTypes: storage.profile.businessInfo.productTypes
        ? storage.profile.businessInfo.productTypes
        : "",
      website: storage.profile.businessInfo.website
        ? storage.profile.businessInfo.website
        : "",
      contactEmail: storage.profile.businessInfo.contactEmail
        ? storage.profile.businessInfo.contactEmail
        : "",
      representatorName: storage.profile.businessInfo.representatorName
        ? storage.profile.businessInfo.representatorName
        : "",
      businessType: storage.profile.businessInfo.businessType
        ? storage.profile.businessInfo.businessType
        : "",
    });
  }, []);

  useEffect(() => {
    //Upload image
    if (reduxDashboardVendor && reduxDashboardVendor.fileUploadInfo) {
      setPreviewImage(reduxDashboardVendor.fileUploadInfo.data[0].filepath);
    }
  }, [reduxDashboardVendor.fileUploadInfo]);

  return (
    <div className="settings">
      {reduxDashboardVendor.loading ? <div className="se-pre-con"></div> : ""}
      <div className="settings__container">

        {/* <div className="settings__container-profile">
          <div className="settings__container-profile-container">
            <div className="settings__container-profile-container-images">
              <div className="settings__container-profile-container-images-avatar">
                <div
                  style={{
                    backgroundImage: `url(${
                      previewImage
                        ? `${previewImage}`
                        : "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia.istockphoto.com%2Fvectors%2Fno-image-vector-symbol-missing-available-icon-no-gallery-for-this-vector-id1128826884%3Fk%3D6%26m%3D1128826884%26s%3D170667a%26w%3D0%26h%3DF6kUwTcsLXUojmGFxN2wApEKgjx63zcIshCSOmnfEFs%3D&f=1&nofb=1"
                    })`,
                    width: "180px",
                    height: "180px",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderRadius: "50%",
                    border: ".3px solid rgb(122,116,153)",
                    backgroundSize: "100%",
                  }}
                  className="settings__container-profile-container-images-avatar-avatar"
                >
                  <input onChange={(e) => handleChangeFile(e)} type="file" />
                  <img src={IconEdit} alt="icon edit image" />
                </div>
              </div>
            </div>
            <div className="settings__container-profile-container-info">
              <div className="settings__container-profile-container-info-1">
                <div className="settings__container-profile-container-info-1-label">
                  <label className="promote-item-label">Name company:</label>
                  <div className="settings__container-profile-container-info-1-content">
                    <button>
                      <FaBuilding className="settings__container-profile-container-info-1-content-icon" />
                    </button>
                    <input
                      name="name"
                      value={datosVendor.name}
                      onChange={(e) => handleChangeInput(e)}
                      type="text"
                    />
                  </div>
                </div>
                <div className="settings__container-profile-container-info-1-label">
                  <label className="promote-item-label">Business type</label>
                  <div className="settings__container-profile-container-info-1-content">
                    <button>
                      <FaBuilding className="settings__container-profile-container-info-1-content-icon" />
                    </button>
                    <input
                      name="businessType"
                      value={datosVendor.businessType}
                      onChange={(e) => handleChangeInput(e)}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div className="settings__container-profile-container-info-1">
                <div className="settings__container-profile-container-info-1-label">
                  <label className="promote-item-label">
                    Product / Service:
                  </label>
                  <div className="settings__container-profile-container-info-1-content">
                    <button>
                      <FaBuffer className="settings__container-profile-container-info-1-content-icon" />
                    </button>
                    <input
                      name="productTypes"
                      value={datosVendor.productTypes}
                      onChange={(e) => handleChangeInput(e)}
                      type="text"
                    />
                  </div>
                </div>
                <div className="settings__container-profile-container-info-1-label">
                  <label className="promote-item-label">Company website:</label>
                  <div className="settings__container-profile-container-info-1-content">
                    <button>
                      <FaWindowMaximize className="settings__container-profile-container-info-1-content-icon" />
                    </button>
                    <input
                      name="website"
                      value={datosVendor.website}
                      onChange={(e) => handleChangeInput(e)}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div className="settings__container-profile-container-info-1">
                <div className="settings__container-profile-container-info-1-label">
                  <label className="promote-item-label">
                    Name person presenting:
                  </label>
                  <div className="settings__container-profile-container-info-1-content">
                    <button>
                      <FaUserAlt className="settings__container-profile-container-info-1-content-icon" />
                    </button>
                    <input
                      name="representatorName"
                      value={datosVendor.representatorName}
                      onChange={(e) => handleChangeInput(e)}
                      type="text"
                    />
                  </div>
                </div>
                <div className="settings__container-profile-container-info-1-label">
                  <label className="promote-item-label">Contact email:</label>
                  <div className="settings__container-profile-container-info-1-content">
                    <button>
                      <FaEnvelope className="settings__container-profile-container-info-1-content-icon" />
                    </button>
                    <input
                      name="contactEmail"
                      value={datosVendor.contactEmail}
                      onChange={(e) => handleChangeInput(e)}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <button
                onClick={() => handleClickInfoVendor()}
                className="settings__container-profile-button update-profile-btn"
              >
                Update Profile Changes
              </button>
            </div>
          </div>
        </div> */}

        {/* <div className="settings__container-display">
            <h1 className="">Dispaly Settings</h1>
            <div className="settings__container-display-window">
                  <p>Select Fonts Size</p>
                  <div className="settings__container-display-window-select">
                    <button className="">Small </button>
                    <button className="settings__container-display-window-select-active">Medium</button>
                    <button className="">Large</button>
                  </div>
            </div>
          </div>
          <div className="settings__container-display-window">
            <p>Scale Window</p>
            <div className="settings__container-display-window-select">
              <button className="">100 % </button>
              <button className="settings__container-display-window-select-active">
                200%
              </button>
              <button className="">300%</button>
            </div>
        </div> */}

        <div className="settings__container-display">
          <h1 className="">Change Password</h1>

          <div className="settings__container-password-container-info">
            <div className="settings__container-password-container-info-1">
              <div className="settings__container-password-container-info-1-label">
                <label className="promote-item-label">New Password:</label>
                <div className="settings__container-password-container-info-1-content">
                  <button>
                    <FaLockOpen className="settings__container-password-container-info-1-content-icon" />
                  </button>
                  <input
                    value={dataPassword.password}
                    name="password"
                    onChange={(e) => handleChangeInputPass(e)}
                    type="password"
                  />
                </div>
              </div>
              <div className="settings__container-password-container-info-1-label">
                <label className="promote-item-label">Confirm Password</label>
                <div className="settings__container-password-container-info-1-content">
                  <button>
                    <FaLockOpen className="settings__container-password-container-info-1-content-icon" />
                  </button>
                  <input
                    value={dataPassword.cpassword}
                    name="cpassword"
                    onChange={(e) => handleChangeInputPass(e)}
                    type="password"
                  />
                </div>
              </div>
            </div>

            <div className="settings__container-password-container-info-1">
              <div className="settings__container-password-container-info-1-label">
                {/* <label className="promote-item-label">Confirm Password:</label>
                  <div className="settings__container-password-container-info-1-content">
                    <button>
                      <FaLockOpen className="settings__container-password-container-info-1-content-icon" />
                    </button>
                    <input type="text" />
                  </div> */}
              </div>
              <div className="settings__container-password-container-info-1-label">
                <button
                  onClick={() => handleClickPass()}
                  className="settings__container-profile-button update-profile-btn"
                >
                  Update Profile Changes
                </button>
              </div>
            </div>
          </div>
        </div>

        {verificadorLink !== "pending" ? (
          <div className="settings__container-display">
            <h3 className="mr-5 text-success">Your account is already linked with stripe, now you can receive transfers by fangway.</h3>
          </div>
        ) : (
          <div className="settings__container-display">
            <h1 className="mr-5">Check your stripe link</h1>

            <div className="settings__container-password-container-info-1-label">
              <button
                onClick={() => handleClickStripeVinculate()}
                className="settings__container-profile-button update-profile-btn"
              >
                Vinculate with stripe
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
