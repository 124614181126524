import React,{useState, useEffect} from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import SignupLink from '../../../components/SignupLink/SignupLink';
import ContentValidation from './ContentValidation';

import { verifyTokenUser } from '../../../utils/Auth';
import { useNavigate } from 'react-router-dom';

import './ValidationVendor.css';

export default function ValidationVendor() {
  
  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  
  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])

  return (
    <div>
        {loadingSesion ? <div className="se-pre-con"></div> : ""}
        <Header />
        <ContentValidation />
        <SignupLink />
        <Footer />
    </div>
  )
}
