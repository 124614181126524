import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productHistory } from "../../../../Actions/OrderListAction/OrderListAction";
import "./SearchHistory.css";



export default function SearchHistory() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);

  const product = useSelector((state) => state.getOrderListReducer);

  const { history = [] } = product;
  console.log("product:>", history);

  useEffect(async () => {
    if (history) {
      setHistoryList(history);
    }
  }, [history]);

  useEffect(async () => {
    setIsLoading(true);
    await dispatch(productHistory());
    setIsLoading(false);
  }, [productHistory]);

  return (
    <div>
      {isLoading ? <div className="se-pre-con"></div> : ""}

      <section className="dashboard_container2">
        <span className="menuIcon"></span>
        <div className="customer-history-main">
          <div className="customer-history-inner">
            <h2 className="cutomer-section-hed">Payment History</h2>
            <div className="customer-history-table-main table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr/no</th>
                    <th>Id </th>
                    <th>Transaction Status</th>
                    <th>Transaction Type</th>
                    <th>Amount</th>
                    {/*<th>Action</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {historyList.map((item, index) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{item.id}</td>
                      <td>{item.transactionStatus}</td>
                      <td>{item.transactionType}</td>
                      <td>{item.amount}$</td>
                      {/*<td>*/}
                      {/*  <button className="edit-history ml-3">*/}
                      {/*    <i className="fa fa-trash"></i>*/}
                      {/*  </button>*/}
                      {/*</td>*/}
                    </tr>
                  ))}
                  {/* <tr>
                    <td>
                      <span className="ch-table-img">
                        <img
                          src="images/whatch-large.png"
                          alt="whatch-large"
                        ></img>
                      </span>
                    </td>
                    <td>Simple Black Clock</td>
                    <td>randomised words even slightly believable</td>
                    <td>$16.00</td>
                    <td className="progress-text">In Progress</td>
                    <td>
                      <button className="edit-history">
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="edit-history ml-3">
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr> */}
                  {/* <tr>
                                    <td><span className='ch-table-img'><img src='images/img_2.png' alt='whatch-large'></img></span></td>
                                    <td>Awesome Headphone</td>
                                    <td>randomised words even slightly believable</td>
                                    <td>$16.00</td>
                                    <td className='completed-text'>Completed</td>
                                    <td><button className='edit-history'><i className='fa fa-edit'></i></button>
                                    <button className='edit-history ml-3'><i className='fa fa-trash'></i></button></td>
                                </tr>
                                <tr>
                                    <td><span className='ch-table-img'><img src='images/recent2.png' alt='whatch-large'></img></span></td>
                                    <td>Different colors Clock</td>
                                    <td>randomised words even slightly believable</td>
                                    <td>$16.00</td>
                                    <td className='not-complete'>Not Completed</td>
                                    <td><button className='edit-history'><i className='fa fa-edit'></i></button>
                                    <button className='edit-history ml-3'><i className='fa fa-trash'></i></button></td>
                                </tr>
                                <tr>
                                    <td><span className='ch-table-img'><img src='images/desktop-small.png' alt='whatch-large'></img></span></td>
                                    <td>New small desktop</td>
                                    <td>randomised words even slightly believable</td>
                                    <td>$16.00</td>
                                    <td>In Progress</td>
                                    <td><button className='edit-history'><i className='fa fa-edit'></i></button>
                                    <button className='edit-history ml-3'><i className='fa fa-trash'></i></button></td>
                                </tr>
                                <tr>
                                    <td><span className='ch-table-img'><img src='images/img_3.png' alt='whatch-large'></img></span></td>
                                    <td>new Black Clock</td>
                                    <td>randomised words even slightly believable</td>
                                    <td>$16.00</td>
                                    <td>In Progress</td>
                                    <td><button className='edit-history'><i className='fa fa-edit'></i></button>
                                    <button className='edit-history ml-3'><i className='fa fa-trash'></i></button></td>
                                </tr>
                                <tr>
                                    <td><span className='ch-table-img'><img src='images/Image 7.png' alt='whatch-large'></img></span></td>
                                    <td>Octa cells</td>
                                    <td>randomised words even slightly believable</td>
                                    <td>$16.00</td>
                                    <td>In Progress</td>
                                    <td><button className='edit-history'><i className='fa fa-edit'></i></button>
                                    <button className='edit-history ml-3'><i className='fa fa-trash'></i></button></td>
                                </tr>
                                <tr>
                                    <td><span className='ch-table-img'><img src='images/order1.png' alt='whatch-large'></img></span></td>
                                    <td>New genration laptop</td>
                                    <td>randomised words even slightly believable</td>
                                    <td>$16.00</td>
                                    <td>In Progress</td>
                                    <td><button className='edit-history'><i className='fa fa-edit'></i></button>
                                    <button className='edit-history ml-3'><i className='fa fa-trash'></i></button></td>
                                </tr>
                                <tr>
                                    <td><span className='ch-table-img'><img src='images/printer-small.png' alt='whatch-large'></img></span></td>
                                    <td>New prints</td>
                                    <td>randomised words even slightly believable</td>
                                    <td>$16.00</td>
                                    <td>In Progress</td>
                                    <td><button className='edit-history'><i className='fa fa-edit'></i></button>
                                    <button className='edit-history ml-3'><i className='fa fa-trash'></i></button></td>
                                </tr>
                                <tr>
                                    <td><span className='ch-table-img'><img src='images/romote-small.png' alt='whatch-large'></img></span></td>
                                    <td>Simple Black Clock</td>
                                    <td>randomised words even slightly believable</td>
                                    <td>$16.00</td>
                                    <td>In Progress</td>
                                    <td><button className='edit-history'><i className='fa fa-edit'></i></button>
                                    <button className='edit-history ml-3'><i className='fa fa-trash'></i></button></td>
                                </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
