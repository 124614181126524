import React from 'react';
import "./FaqPage.css";

import Accordion from './Acordeon';

import SignupLink from '../../components/SignupLink/SignupLink';
import Footer from "../../components/Footer/Footer";


export default function FaqPage() {

    const accordeon = [
        {
            question: 'Who Can Place An Adult Use Delivery Order?',
            response: 'Anyone who is 21+ years of age, has created an account with NETA, lives in the delivery radius, and has completed the ID verification step.'
        },
        {
            question: 'Who Can Accept An Adult Use Delivery Order?',
            response: 'Only the ordering customer can accept a delivery order. A delivery driver will validate that the customer’s physical ID matches the encrypted ID on file.'
        },
        {
            question: 'How Do I Order Adult Use Delivery?',
            response: 'Once you have a NETA account and your ID has been verified you can order delivery directly from netacare.org by selecting delivery in "My Store" in the navigation menu.'
        },
        {
            question: 'Where Do You Deliver?',
            response: 'We deliver across the Greater Boston, Northampton and Pioneer Valley. You can input your address in the account section to verify your delivery address.'
        },
        {
            question: 'Are Medical And Adult Use Delivery Different?',
            response: 'Yes, medical delivery has a different delivery radius and is delivered by a NETA employee. You must have a registered medical marijuana account with NETA to receive medical delivery.'
        },
        {
            question: 'What Do I Need In Preparation For My Delivery?',
            response: 'In order to receive your delivery, you will need your payment (exact cash, debit card, or Canpay) and the physical copy of the government issued ID that was used for delivery registration.'
        },
        {
            question: 'How Do I Know My ID Is Secure?',
            response: 'All ID information is stored with a secure 256 bit end-to-end encryption—The same level of security used in online banking.'
        },
        {
            question: 'Where Can I Receive A Delivery?',
            response: 'You can receive a delivery at any residential address within our delivery radius. Some municipalities do not allow cannabis delivery services.'
        },
        {
            question: 'How Do I Pay For Delivery?',
            response: 'You can pay for your delivery with exact cash, debit card, or Canpay. You can prepay for your Adult use delivery through Canpay.'
        },
        {
            question: 'Can I Modify A Delivery Order After It’s Placed?',
            response: 'Yes, you can modify your delivery order by contacting customer care at 617-377-7408 or contact@netacare.org. Please reference your order confirmation #.'
        },
    ]


  return (
    <div className="faq__page">
      <div className="faq__page-banner">
        <div className="container faq__page-banner-content">
          <h1>Frequently Asked Questions</h1>
          <p>
            Shop home entertainment, TVs, home audio, headphones, cameras,
            accessories and more
          </p>
        </div>
      </div>
      <div className="container faq__page-banner-faq-content">
        <div className='row '>
            {accordeon.map((acor,i) => {
                return <div className='col-lg-6 col-md-12 col-sm-12 faq__page-banner-faq-content-accordeon'>
                    <Accordion index={i} question={acor.question} response={acor.response} />
                </div>
            })}
        </div>
        <div className='faq__page-banner-faq-content-have-question'>
            <p>Do you have any questions?</p>
            <button>
                Click Here
            </button>
        </div>
      </div>
      <SignupLink />
      <Footer />
    </div>
  );
}
