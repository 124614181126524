import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { gettingOrderlistDetails } from "../../../../Actions/CustomerActions/customerAction";
import { gettingOrderlist } from "../../../../Actions/OrderListAction/OrderListAction";
import ShowDetailModal from "../../../../Modals/ShowDetailModal";
import "../FavouriteProductHistory/FavouriteProductHistory.css";

import PaginationMaterial from "../../../Pagination/PaginationMaterial";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { toast } from "react-toastify";
import { Auth } from "../../../../utils/Auth";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";

export default function CustomerHistory() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // const [page/* , setPage */] = useState("1");
  // const [limit/* , setLimit */] = useState("10");
  const [open, setOpen] = React.useState(false);
  const [docList, setDocsList] = React.useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [order, setOrder] = useState({});

  const [loadingView, setLoadingView] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [reload, setReload] = useState();

  const handleChange = (_, value) => {
    setPage(value);
  };

  const orderListInfo = useSelector((state) => state.getOrderListReducer);

  const getMoreDetailOfProduct = async (order) => {
    // setOrder(order)
    // handleOpen();
    navigate(`/customer-detail-order?id=${order.generalId}&order=${order.id}`);
  };

  const putStateCancel = async (item) => {
    try {
      // toast.error("aaaaa")

      setLoadingView(true);
      const { data } = await axios.put(
        `http://localhost:3003/ecommerce/order/${item.generalId}/change/Aborted/state/${item.id}`,
        {},
        { headers: Auth() }
      );
      console.log(data);
      if (data.statusCode === 200) {
        setLoadingView(false);
        setReload(!reload);
      }
    } catch (error) {
      setLoadingView(false);
      toast.error("Failed");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(gettingOrderlist(page));
  }, [page, reload]);

  useEffect(() => {
    if (
      orderListInfo &&
      orderListInfo.orderList &&
      orderListInfo.orderList.docs
    ) {
      setDocsList(orderListInfo.orderList.docs);
      setTotalPages(orderListInfo.orderList.totalPages);
      setIsLoading(false);
    }
  }, [orderListInfo.orderList]);

  console.log(docList);

  return (
    <div>
      {isLoading || loadingView ? <div className="se-pre-con"></div> : ""}

      <section className="dashboard_container2">
        <span className="menuIcon"></span>
        <div className="customer-history-main">
          <div className="customer-history-inner">
            <h2 className="cutomer-section-hed">Order List</h2>
            <div className="customer-history-table-main table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr/no</th>
                    <th>Items</th>
                    <th>Order id</th>
                    <th>Order Status</th>
                    <th>Created at</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {docList.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <AvatarGroup max={4}>
                          {item.orderItem.map((itemP) => {
                            return (
                              <Avatar
                                alt="Remy Sharp"
                                src={itemP.productInfo.image}
                              />
                            );
                          })}
                        </AvatarGroup>
                      </td>
                      <td>{item.id}</td>
                      <td>{item.state}</td>
                      <td>
                        {new Date(item.createdAt).toString("MMMM dS, yyyy")}
                      </td>

                      <td>
                        {item.state === "Aborted" ? (
                          <p className="text-danger text-rigth">Aborted</p>
                        ) : (
                          <div>
                            <Button
                              onClick={() => putStateCancel(item)}
                              className="mr-2"
                              color="error"
                              variant="outlined"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={() => getMoreDetailOfProduct(item)}
                            >
                              View More Detail
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <PaginationMaterial
                totalPages={totalPages}
                page={page}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      </section>
      <ShowDetailModal open={open} order={order} handleClose={handleClose} />
    </div>
  );
}
