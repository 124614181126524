import React,{useState} from "react";
import "./SuperSale.css";
import IconStar from "./assets/Grupo 22.png";
import IconDiamont from './assets/Icon awesome-gem.png';
import IconQueen from './assets/Icon awesome-chess-queen.png';

import IconElite from './assets/Grupo 221-3.svg';
import iconPrem from './assets/cosmetics(1).svg';
import IconCosmetic from './assets/cosmetic2.svg';

import Back from './assets/Grupo 1.png';
import { CreateOrder } from "../../Actions/Products/ProductsAction";
import { useDispatch, useSelector } from "react-redux";
import { /* Link, */ useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function SuperSale() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)

  const basicCard = {
    discountRate: 50,
    totalPrice: 70,
    noTotalPrice: 120,
    products: [
      {
        amount: 1,
        product: {
          id: "RmRKD8GXwE8HAlOWks8Y",
          name: "Boho Glam Eyeshadow Palette 01 Hippie Chick",
          description: "Free returns on all opening orders\nYou’re eligible for free returns on this order with Dobner Kosmetik.",
          ranking: 5,
          category: "Make-up and Beauty",
          amount: 7.55,
          longDescription: "<p>TO THE PRODUCT:</p>\n<p>* 9 optimally coordinated eye shadow shades</p>\n<p>* For a matte and shimmering finish</p>\n<p>* Highly pigmented</p>\n<p>* maximum color intensity</p>\n<p>* long hold</p>\n<p>* integrated mirror</p>\n<p>&nbsp;</p>\n<p>MORE INFO:</p>\n<p>* vegan</p>\n<p>* cruelty-free</p>\n<p>_All YouStar products are climate-neutral and provide an active Contribute to climate protection. _</p>\n<p>Vegan: X</p>\n<p>Eco-friendly: X</p>\n<p>Climate-neutral goal: X</p>\n<p>Pet friendly: X<br />Made in China</p>",
          attributes: {
            Weight: "0.21 lb"
          },
          image: "https://fangway.com/users/misc/uploads/a02c67f8cd85da164de985b3549e83a1.webp",
          shipping: 5,
          stock: 113,
          discount: 30,
          newDiscount: 30,
          authVendorId: "umuMEFMlSpT79kAtRLIVGFN0FZ93",
          gallery: [
            {
              id: "m0r72UfTl7macy7nRyFO",
              featured: "yes",
              image: "https://fangway.com/users/misc/uploads/ea25c1261017943c32412e8d25d60032e.webp",
              sort: 3
            },
            {
              id: "ovAfxGzvHjlvfZXgvz3n",
              featured: "yes",
              sort: 1,
              image: "https://fangway.com/users/misc/uploads/6893ba38fd0f19be1327b148a686346c.webp"
            }
          ],
          slug: "boho-glam-eyeshadow-palette-01-hippie-chick-921182284",
          review: {
            data: [],
            analiticReview: {
              averageScore: 0,
              fiveStart: 0,
              fourStart: 0,
              threeStart: 0,
              twoStart: 0,
              oneStart: 0
            }
          }
        }
      },
      {
        amount: 1,
        product: {
          id: "ezGflkM0CCsICSZ4rXTm",
          name: "Sensitive Foundation Nuance B002 30 ml",
          description: "Fluid foundation with a natural finish, specific for sensitive skin.\nThe special formula with UVA / UVB protection evens out the complexion, protects sensitive skin or skin prone to discoloration and redness, minimizes imperfections and wrinkles, without weighing it down.",
          ranking: 5,
          category: "Make-up and Beauty",
          amount: 20,
          longDescription: "<p>Fluid foundation with a light and impalpable texture for an easy, smooth application with a silky touch.</p>\n<p>The formula, enriched with active ingredients that give elasticity and nourishment to the skin, evens out and illuminates the complexion without weighing it down. Imperfections and discolorations are minimized thanks to the spherical glass particles that melt on the skin, for a &ldquo;naked skin&rdquo; effect and give a luminous and even complexion.</p>\n<p>&nbsp;</p>\n<p>The result of the make-up is a smooth skin, an ultra-light coverage that enhances the natural features of every woman.</p>\n<p>&nbsp;</p>\n<p>Available in 6 NUANCES. <br />Made in Italy</p>",
          attributes: {
            "Made in": "Italy"
          },
          image: "https://fangway.com/users/misc/uploads/86bb18bcfcea19cad51f1cefd62e102a1.jpeg",
          shipping: 5,
          stock: 117,
          discount: 30,
          newDiscount:30,
          authVendorId: "umuMEFMlSpT79kAtRLIVGFN0FZ93",
          gallery: [
            {
              id: "c1IUi0q5ommGoMyRdiWD",
              image: "https://fangway.com/users/misc/uploads/81d44cdd1ae29b72b60c22b6350c3612.webp",
              sort: 1,
              featured: "yes"
            }
          ],
          slug: "sensitive-foundation-nuance-b002-30-ml-735245308",
          review: {
            data: [],
            analiticReview: {
              averageScore: 0,
              fiveStart: 0,
              fourStart: 0,
              threeStart: 0,
              twoStart: 0,
              oneStart: 0
            }
          }
        }
      },
      {
        amount: 1,
        product: {
          id: "tZEXR9VeoWLnmAD7jFSM",
          name: "Romantic Red - Matte Liquid Lipsticks - style 1",
          description: "High pigment combined with a hydrating formula! Romantic Beauty’s long-lasting matte liquid lipsticks deliver an all-day comfortable wear without over-drying the lips. Available in eight scented sultry shades, there’s a perfect red for diverse skin complexions! The elongated applicator allows for a smudge-free application of the weightless formula that immediately sculpts...",
          ranking: 5,
          category: "Make-up and Beauty",
          amount: 7.24,
          longDescription: "<p>High pigment combined with a hydrating formula! Romantic Beauty&rsquo;s long-lasting matte liquid lipsticks deliver an all-day comfortable wear without over-drying the lips. Available in eight scented sultry&nbsp;shades, there&rsquo;s a perfect red for diverse skin complexions! The elongated applicator allows for a smudge-free application of the weightless formula that immediately sculpts your lips while depositing glamorous color.&nbsp;</p>\n<p><strong>Shades:&nbsp;Sable, Cashew, Earth Red, Flamingo, Garnet, Teaberry, Ginger, Super Pink</strong></p>\n<p><strong>Set Quantity: 8&nbsp;</strong></p>\n<p><strong>Display Quantity: 48&nbsp;</strong></p>\n<h6>Ingredients:</h6>\n<h6>ISODODECANE, TRIMETHYLSILOXYSILICATE, POLYPROPYLSILSESQUIOXANE, BEESWAX (CERA ALBA), HYDROGENATED STYRENE/ISOPRENE COPOLYER, SILICA DIMETHYL SILYLATE, MICA, SILICA, SORBITAN OLEATE, TOCOPHERYL ACETATE, PHENOXYETHANOL, FRAGRANCE (PARFUM). MAY CONTAIN: TITAN DIOXIDE (CI 77891), IRON OXIDES (CI 77491, CI 77492, CI 77499), YELLOW 6 LAKE (CI 15985), BLUE 1 LAKE (CI 42090), RED 6 LAKE (CI 15850), RED 7 LAKE (CI 15850), RED 27 LAKE (CI 45410), RED 21 (CI 45380)</h6>",
          attributes: {
            "a": "a"
          },
          image: "https://fangway.com/users/misc/uploads/04fd78b69638ccd2c4b292d8ad58048b.webp",
          shipping: 5,
          stock: 108,
          discount: 30,
          newDiscount:30,
          authVendorId: "umuMEFMlSpT79kAtRLIVGFN0FZ93",
          gallery: [
            {
              id: "nTKizz4XoOvwwCujj9tp",
              featured: "yes",
              image: "https://fangway.com/users/misc/uploads/ec427d3957102c9e13172bdce7eaa1a63.webp",
              sort: 1
            },
            {
              id: "o5aAO4OIzm6sjI2nLxQU",
              image: "https://fangway.com/users/misc/uploads/a101c10c3ae093bdb24e1b4ca420cb00cd.webp",
              sort: 2,
              featured: "yes"
            }
          ],
          slug: "romantic-red---matte-liquid-lipsticks-815470445",
          review: {
            data: [],
            analiticReview: {
              averageScore: 0,
              fiveStart: 0,
              fourStart: 0,
              threeStart: 0,
              twoStart: 0,
              oneStart: 0
            }
          }
        }
      }
    ],
    showProducts: []
  }

  const premiumCard = {
    discountRate: 60,
    totalPrice: 120,
    noTotalPrice: 220,
    products: [
      {
        amount: 1,
        product: {
          id: "RmRKD8GXwE8HAlOWks8Y",
          name: "Boho Glam Eyeshadow Palette 01 Hippie Chick",
          description: "Free returns on all opening orders\nYou’re eligible for free returns on this order with Dobner Kosmetik.",
          ranking: 5,
          category: "Make-up and Beauty",
          amount: 7.55,
          longDescription: "<p>TO THE PRODUCT:</p>\n<p>* 9 optimally coordinated eye shadow shades</p>\n<p>* For a matte and shimmering finish</p>\n<p>* Highly pigmented</p>\n<p>* maximum color intensity</p>\n<p>* long hold</p>\n<p>* integrated mirror</p>\n<p>&nbsp;</p>\n<p>MORE INFO:</p>\n<p>* vegan</p>\n<p>* cruelty-free</p>\n<p>_All YouStar products are climate-neutral and provide an active Contribute to climate protection. _</p>\n<p>Vegan: X</p>\n<p>Eco-friendly: X</p>\n<p>Climate-neutral goal: X</p>\n<p>Pet friendly: X<br />Made in China</p>",
          attributes: {
            Weight: "0.21 lb"
          },
          image: "https://fangway.com/users/misc/uploads/a02c67f8cd85da164de985b3549e83a1.webp",
          shipping: 5,
          stock: 113,
          discount: 30,
          newDiscount: 30,
          authVendorId: "umuMEFMlSpT79kAtRLIVGFN0FZ93",
          gallery: [
            {
              id: "m0r72UfTl7macy7nRyFO",
              featured: "yes",
              image: "https://fangway.com/users/misc/uploads/ea25c1261017943c32412e8d25d60032e.webp",
              sort: 3
            },
            {
              id: "ovAfxGzvHjlvfZXgvz3n",
              featured: "yes",
              sort: 1,
              image: "https://fangway.com/users/misc/uploads/6893ba38fd0f19be1327b148a686346c.webp"
            }
          ],
          slug: "boho-glam-eyeshadow-palette-01-hippie-chick-921182284",
          review: {
            data: [],
            analiticReview: {
              averageScore: 0,
              fiveStart: 0,
              fourStart: 0,
              threeStart: 0,
              twoStart: 0,
              oneStart: 0
            }
          }
        }
      },
      {
        amount: 1,
        product: {
          id: "ezGflkM0CCsICSZ4rXTm",
          name: "Sensitive Foundation Nuance B002 30 ml",
          description: "Fluid foundation with a natural finish, specific for sensitive skin.\nThe special formula with UVA / UVB protection evens out the complexion, protects sensitive skin or skin prone to discoloration and redness, minimizes imperfections and wrinkles, without weighing it down.",
          ranking: 5,
          category: "Make-up and Beauty",
          amount: 20,
          longDescription: "<p>Fluid foundation with a light and impalpable texture for an easy, smooth application with a silky touch.</p>\n<p>The formula, enriched with active ingredients that give elasticity and nourishment to the skin, evens out and illuminates the complexion without weighing it down. Imperfections and discolorations are minimized thanks to the spherical glass particles that melt on the skin, for a &ldquo;naked skin&rdquo; effect and give a luminous and even complexion.</p>\n<p>&nbsp;</p>\n<p>The result of the make-up is a smooth skin, an ultra-light coverage that enhances the natural features of every woman.</p>\n<p>&nbsp;</p>\n<p>Available in 6 NUANCES. <br />Made in Italy</p>",
          attributes: {
            "Made in": "Italy"
          },
          image: "https://fangway.com/users/misc/uploads/86bb18bcfcea19cad51f1cefd62e102a1.jpeg",
          shipping: 5,
          stock: 117,
          discount: 30,
          newDiscount:30,
          authVendorId: "umuMEFMlSpT79kAtRLIVGFN0FZ93",
          gallery: [
            {
              id: "c1IUi0q5ommGoMyRdiWD",
              image: "https://fangway.com/users/misc/uploads/81d44cdd1ae29b72b60c22b6350c3612.webp",
              sort: 1,
              featured: "yes"
            }
          ],
          slug: "sensitive-foundation-nuance-b002-30-ml-735245308",
          review: {
            data: [],
            analiticReview: {
              averageScore: 0,
              fiveStart: 0,
              fourStart: 0,
              threeStart: 0,
              twoStart: 0,
              oneStart: 0
            }
          }
        }
      },
      {
        amount: 1,
        product: {
          id: "tZEXR9VeoWLnmAD7jFSM",
          name: "Romantic Red - Matte Liquid Lipsticks - style 1",
          description: "High pigment combined with a hydrating formula! Romantic Beauty’s long-lasting matte liquid lipsticks deliver an all-day comfortable wear without over-drying the lips. Available in eight scented sultry shades, there’s a perfect red for diverse skin complexions! The elongated applicator allows for a smudge-free application of the weightless formula that immediately sculpts...",
          ranking: 5,
          category: "Make-up and Beauty",
          amount: 7.24,
          longDescription: "<p>High pigment combined with a hydrating formula! Romantic Beauty&rsquo;s long-lasting matte liquid lipsticks deliver an all-day comfortable wear without over-drying the lips. Available in eight scented sultry&nbsp;shades, there&rsquo;s a perfect red for diverse skin complexions! The elongated applicator allows for a smudge-free application of the weightless formula that immediately sculpts your lips while depositing glamorous color.&nbsp;</p>\n<p><strong>Shades:&nbsp;Sable, Cashew, Earth Red, Flamingo, Garnet, Teaberry, Ginger, Super Pink</strong></p>\n<p><strong>Set Quantity: 8&nbsp;</strong></p>\n<p><strong>Display Quantity: 48&nbsp;</strong></p>\n<h6>Ingredients:</h6>\n<h6>ISODODECANE, TRIMETHYLSILOXYSILICATE, POLYPROPYLSILSESQUIOXANE, BEESWAX (CERA ALBA), HYDROGENATED STYRENE/ISOPRENE COPOLYER, SILICA DIMETHYL SILYLATE, MICA, SILICA, SORBITAN OLEATE, TOCOPHERYL ACETATE, PHENOXYETHANOL, FRAGRANCE (PARFUM). MAY CONTAIN: TITAN DIOXIDE (CI 77891), IRON OXIDES (CI 77491, CI 77492, CI 77499), YELLOW 6 LAKE (CI 15985), BLUE 1 LAKE (CI 42090), RED 6 LAKE (CI 15850), RED 7 LAKE (CI 15850), RED 27 LAKE (CI 45410), RED 21 (CI 45380)</h6>",
          attributes: {
            "a": "a"
          },
          image: "https://fangway.com/users/misc/uploads/04fd78b69638ccd2c4b292d8ad58048b.webp",
          shipping: 5,
          stock: 108,
          discount: 30,
          newDiscount:30,
          authVendorId: "umuMEFMlSpT79kAtRLIVGFN0FZ93",
          gallery: [
            {
              id: "nTKizz4XoOvwwCujj9tp",
              featured: "yes",
              image: "https://fangway.com/users/misc/uploads/ec427d3957102c9e13172bdce7eaa1a63.webp",
              sort: 1
            },
            {
              id: "o5aAO4OIzm6sjI2nLxQU",
              image: "https://fangway.com/users/misc/uploads/a101c10c3ae093bdb24e1b4ca420cb00cd.webp",
              sort: 2,
              featured: "yes"
            }
          ],
          slug: "romantic-red---matte-liquid-lipsticks-815470445",
          review: {
            data: [],
            analiticReview: {
              averageScore: 0,
              fiveStart: 0,
              fourStart: 0,
              threeStart: 0,
              twoStart: 0,
              oneStart: 0
            }
          }
        }
      }
    ],
    showProducts: []
  }

  const eleganceCard = {
    discountRate: 70,
    totalPrice: 165,
    noTotalPrice: 265,
    products: [
      {
        amount: 1,
        product: {
          id: "RmRKD8GXwE8HAlOWks8Y",
          name: "Boho Glam Eyeshadow Palette 01 Hippie Chick",
          description: "Free returns on all opening orders\nYou’re eligible for free returns on this order with Dobner Kosmetik.",
          ranking: 5,
          category: "Make-up and Beauty",
          amount: 7.55,
          longDescription: "<p>TO THE PRODUCT:</p>\n<p>* 9 optimally coordinated eye shadow shades</p>\n<p>* For a matte and shimmering finish</p>\n<p>* Highly pigmented</p>\n<p>* maximum color intensity</p>\n<p>* long hold</p>\n<p>* integrated mirror</p>\n<p>&nbsp;</p>\n<p>MORE INFO:</p>\n<p>* vegan</p>\n<p>* cruelty-free</p>\n<p>_All YouStar products are climate-neutral and provide an active Contribute to climate protection. _</p>\n<p>Vegan: X</p>\n<p>Eco-friendly: X</p>\n<p>Climate-neutral goal: X</p>\n<p>Pet friendly: X<br />Made in China</p>",
          attributes: {
            Weight: "0.21 lb"
          },
          image: "https://fangway.com/users/misc/uploads/a02c67f8cd85da164de985b3549e83a1.webp",
          shipping: 5,
          stock: 113,
          discount: 30,
          newDiscount: 30,
          authVendorId: "umuMEFMlSpT79kAtRLIVGFN0FZ93",
          gallery: [
            {
              id: "m0r72UfTl7macy7nRyFO",
              featured: "yes",
              image: "https://fangway.com/users/misc/uploads/ea25c1261017943c32412e8d25d60032e.webp",
              sort: 3
            },
            {
              id: "ovAfxGzvHjlvfZXgvz3n",
              featured: "yes",
              sort: 1,
              image: "https://fangway.com/users/misc/uploads/6893ba38fd0f19be1327b148a686346c.webp"
            }
          ],
          slug: "boho-glam-eyeshadow-palette-01-hippie-chick-921182284",
          review: {
            data: [],
            analiticReview: {
              averageScore: 0,
              fiveStart: 0,
              fourStart: 0,
              threeStart: 0,
              twoStart: 0,
              oneStart: 0
            }
          }
        }
      },
      {
        amount: 1,
        product: {
          id: "ezGflkM0CCsICSZ4rXTm",
          name: "Sensitive Foundation Nuance B002 30 ml",
          description: "Fluid foundation with a natural finish, specific for sensitive skin.\nThe special formula with UVA / UVB protection evens out the complexion, protects sensitive skin or skin prone to discoloration and redness, minimizes imperfections and wrinkles, without weighing it down.",
          ranking: 5,
          category: "Make-up and Beauty",
          amount: 20,
          longDescription: "<p>Fluid foundation with a light and impalpable texture for an easy, smooth application with a silky touch.</p>\n<p>The formula, enriched with active ingredients that give elasticity and nourishment to the skin, evens out and illuminates the complexion without weighing it down. Imperfections and discolorations are minimized thanks to the spherical glass particles that melt on the skin, for a &ldquo;naked skin&rdquo; effect and give a luminous and even complexion.</p>\n<p>&nbsp;</p>\n<p>The result of the make-up is a smooth skin, an ultra-light coverage that enhances the natural features of every woman.</p>\n<p>&nbsp;</p>\n<p>Available in 6 NUANCES. <br />Made in Italy</p>",
          attributes: {
            "Made in": "Italy"
          },
          image: "https://fangway.com/users/misc/uploads/86bb18bcfcea19cad51f1cefd62e102a1.jpeg",
          shipping: 5,
          stock: 117,
          discount: 30,
          newDiscount:30,
          authVendorId: "umuMEFMlSpT79kAtRLIVGFN0FZ93",
          gallery: [
            {
              id: "c1IUi0q5ommGoMyRdiWD",
              image: "https://fangway.com/users/misc/uploads/81d44cdd1ae29b72b60c22b6350c3612.webp",
              sort: 1,
              featured: "yes"
            }
          ],
          slug: "sensitive-foundation-nuance-b002-30-ml-735245308",
          review: {
            data: [],
            analiticReview: {
              averageScore: 0,
              fiveStart: 0,
              fourStart: 0,
              threeStart: 0,
              twoStart: 0,
              oneStart: 0
            }
          }
        }
      },
      {
        amount: 1,
        product: {
          id: "tZEXR9VeoWLnmAD7jFSM",
          name: "Romantic Red - Matte Liquid Lipsticks - style 1",
          description: "High pigment combined with a hydrating formula! Romantic Beauty’s long-lasting matte liquid lipsticks deliver an all-day comfortable wear without over-drying the lips. Available in eight scented sultry shades, there’s a perfect red for diverse skin complexions! The elongated applicator allows for a smudge-free application of the weightless formula that immediately sculpts...",
          ranking: 5,
          category: "Make-up and Beauty",
          amount: 7.24,
          longDescription: "<p>High pigment combined with a hydrating formula! Romantic Beauty&rsquo;s long-lasting matte liquid lipsticks deliver an all-day comfortable wear without over-drying the lips. Available in eight scented sultry&nbsp;shades, there&rsquo;s a perfect red for diverse skin complexions! The elongated applicator allows for a smudge-free application of the weightless formula that immediately sculpts your lips while depositing glamorous color.&nbsp;</p>\n<p><strong>Shades:&nbsp;Sable, Cashew, Earth Red, Flamingo, Garnet, Teaberry, Ginger, Super Pink</strong></p>\n<p><strong>Set Quantity: 8&nbsp;</strong></p>\n<p><strong>Display Quantity: 48&nbsp;</strong></p>\n<h6>Ingredients:</h6>\n<h6>ISODODECANE, TRIMETHYLSILOXYSILICATE, POLYPROPYLSILSESQUIOXANE, BEESWAX (CERA ALBA), HYDROGENATED STYRENE/ISOPRENE COPOLYER, SILICA DIMETHYL SILYLATE, MICA, SILICA, SORBITAN OLEATE, TOCOPHERYL ACETATE, PHENOXYETHANOL, FRAGRANCE (PARFUM). MAY CONTAIN: TITAN DIOXIDE (CI 77891), IRON OXIDES (CI 77491, CI 77492, CI 77499), YELLOW 6 LAKE (CI 15985), BLUE 1 LAKE (CI 42090), RED 6 LAKE (CI 15850), RED 7 LAKE (CI 15850), RED 27 LAKE (CI 45410), RED 21 (CI 45380)</h6>",
          attributes: {
            "a": "a"
          },
          image: "https://fangway.com/users/misc/uploads/04fd78b69638ccd2c4b292d8ad58048b.webp",
          shipping: 5,
          stock: 108,
          discount: 30,
          newDiscount:30,
          authVendorId: "umuMEFMlSpT79kAtRLIVGFN0FZ93",
          gallery: [
            {
              id: "nTKizz4XoOvwwCujj9tp",
              featured: "yes",
              image: "https://fangway.com/users/misc/uploads/ec427d3957102c9e13172bdce7eaa1a63.webp",
              sort: 1
            },
            {
              id: "o5aAO4OIzm6sjI2nLxQU",
              image: "https://fangway.com/users/misc/uploads/a101c10c3ae093bdb24e1b4ca420cb00cd.webp",
              sort: 2,
              featured: "yes"
            }
          ],
          slug: "romantic-red---matte-liquid-lipsticks-815470445",
          review: {
            data: [],
            analiticReview: {
              averageScore: 0,
              fiveStart: 0,
              fourStart: 0,
              threeStart: 0,
              twoStart: 0,
              oneStart: 0
            }
          }
        }
      }
    ],
    showProducts: []
  }

  const buyPack = (pack) => {

    const user = localStorage.getItem("userInfo");
    if (!user) {
      navigate("/signup");
      return;
    }
    setLoading(true);

    console.log(pack)

    switch(pack){
      case 'Basic': 
      localStorage.setItem("order", JSON.stringify(basicCard.products));
      // const data = basicCard.products;
      dispatch(
        CreateOrder("Cart", (data, sk, { error, er }) => {
          if (er) {
            // console.log(error)
            toast.error(error);
          } else {
            navigate(`/checkout?o=${data.data.idOrder}&s=${sk}`);
          }
        })
      );
      break;
      case 'Premium': 
      localStorage.setItem("order", JSON.stringify(premiumCard.products));
      dispatch(
        CreateOrder("Cart", (data, sk, { error, er }) => {
          if (er) {
            // console.log(error)
            toast.error(error);
          } else {
            navigate(`/checkout?o=${data.data.idOrder}&s=${sk}`);
          }
        })
      );
      break;
      case 'Elegance': 
      localStorage.setItem("order", JSON.stringify(eleganceCard.products));
      dispatch(
        CreateOrder("Cart", (data, sk, { error, er }) => {
          if (er) {
            // console.log(error)
            toast.error(error);
          } else {
            navigate(`/checkout?o=${data.data.idOrder}&s=${sk}`);
          }
        })
      );
      break;
    }
  }

  return (
    <div id="section-carousel" className="sooper-main">
      {loading ? <div className="se-pre-con"></div> : ""}
      <section className="supper-sale">
        <div id="demo" className="carousel slide" data-ride="carousel">
          <ul className="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" className="active"></li>
          </ul>
          <div className="carousel-inner">

            <div className="carousel-item no-pack active">
              <img
                className="carousel-img-lg"
                src="images/Banner-final1.jpg"
                alt="banner1"
              />
              <img
                className="carousel-img-xs"
                src="images/Banner-final1-xs.jpg"
                alt="banner1"
              />
              <div className="carousel-caption3">
                <div className="super-sale-inner pl-2">
                  <div className="sale-text-banner">
                    <p>limited time offer</p>
                  </div>
                  <div className="sale-text"> sale</div>
                  <div className="shop-now-banner">
                    <a href="#">shop now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item no-pack">
              <img
                className="carousel-img-lg"
                src="images/Banner-Final2.jpg"
                alt="banner2"
              />
              <img
                className="carousel-img-xs"
                src="images/Banner-Final2-xs.jpg"
                alt="banner2"
              />
              <div className="carousel-caption">
                <div className="super-sale-inner">
                  <h5 className="italic">special offer</h5>
                  <h1 className="italic">super sale</h1>
                  <div className="off-sale italic">
                    <span className="upto">up To</span>{" "}
                    <span className="percent-new">80%</span>
                  </div>
                </div>
              </div>
            </div>
            
            <div id="pack" className="carousel-item pack ">

              <img
                className="carousel-img-lg"
                src={Back}
                alt="banner3"
              />
              <img
                className="carousel-img-xs"
                src={Back}
                alt="banner2"
              />

              <div className="carousel-caption new_welecome_caption">
                <div className="welcome-fangway">
                  <h3>Welcome to Fangway!</h3>
                  <h2>Enjoy these Massive Deals</h2>
                </div>

                <div className="banner_cards_outer">

                  {/* <-------------------- Card Basic ----------------------> */ }
                  <div className="banner_cards">
                    <div className="banner_card_pack_header d-flex justify-content-lg-between">
                      <div className="banner_card_pack_header_image_content d-flex ">
                        <img
                          className="banner_card_pack_header_image mr-2"
                          src={IconCosmetic}
                          alt="start1"
                        />
                        <h3 className="banner_card_pack_header_title_pack">
                          Basic
                        </h3>
                      </div>
                      <div >
                        <h3 className="banner_card_pack_header_title_off_pack">
                          {basicCard.discountRate}% Off
                        </h3>
                      </div>
                    </div>

                    <div className="banner_card_pack_body d-flex justify-content-lg-between">
                      <div className="text-left banner_card_pack_body_text">
                        <p>
                          Eye shadow: $7.55, $3.84  
                        </p>
                        <p>Foundation: $7, $4.8</p>
                        <p>Lip stick combo: $15.21, $5.43</p>
                      </div>
                      <div className="text-right" >
                        <p className="banner_card_pack_body_price_no_final" >${basicCard.noTotalPrice}</p>
                        <p className="banner_card_pack_body_price_final">${basicCard.totalPrice}</p>
                      </div>
                    </div>

                    <div className="banner_card_pack_body-2 d-flex justify-content-lg-between">
                      <div className="text-left banner_card_pack_body_text-2">
                        <div className="text-center" >
                          <p className="banner_card_pack_body_price_no_final-2" >${basicCard.noTotalPrice}</p>
                          <p className="banner_card_pack_body_price_final-2">${basicCard.totalPrice}</p>
                        </div>
                        <p>
                          Eye shadow: $7.55, $3.84  
                        </p>
                        <p>Foundation: $7, $4.8</p>
                        <p>Lip stick combo: $15.21, $5.43</p>
                      </div>
                    </div>

                    <div className="banner_card_pack_footer d-flex flex-row-reverse">
                        {/* <a className="banner_card_pack_footer_read" href="#">Read more...</a> */}
                        <button onClick={() => buyPack("Basic")} className="banner_card_pack_footer_button float-right">
                          Buy now
                        </button>
                    </div>

                    <div className="banner_card_pack_footer-2 d-block mt-4 text-center">
                        <button onClick={() => buyPack("Basic")} className="banner_card_pack_footer_button">
                          Buy now
                        </button>
                        <p className="mt-2">
                          {/* <a className="banner_card_pack_footer_read" href="#">Read more...</a> */}
                        </p>
                    </div>

                  </div>
                  {/* <-------------------- End Card Basic ----------------------> */ }




                  {/* <-------------------- Card Premium ----------------------> */ }
                  <div className="banner_cards">
                    <div className="banner_card_pack_header d-flex justify-content-lg-between">
                      <div className="banner_card_pack_header_image_content mt-1 d-flex">
                        <img
                          className="banner_card_pack_header_image mr-2"
                          src={iconPrem}
                          alt="start1"
                        />
                        <h3 className="banner_card_pack_header_title_pack">
                          Premium
                        </h3>
                      </div>
                      <div className="">
                        <h3 className="banner_card_pack_header_title_off_pack">
                          {premiumCard.discountRate}% Off
                        </h3>
                      </div>
                    </div>

                    <div className="banner_card_pack_body d-flex justify-content-lg-between">
                      <div className="text-left banner_card_pack_body_text">
                        <p>
                          Eye shadow: $7.55, $3.84  
                        </p>
                        <p>Foundation: $7, $4.8</p>
                        <p>Lip stick combo: $15.21, $5.43</p>
                      </div>
                      <div className="text-right" >
                        <p className="banner_card_pack_body_price_no_final" >${premiumCard.noTotalPrice}</p>
                        <p className="banner_card_pack_body_price_final">${premiumCard.totalPrice}</p>
                      </div>
                    </div>

                    <div className="banner_card_pack_body-2 d-flex justify-content-lg-between">
                      <div className="text-left banner_card_pack_body_text-2">
                        <div className="text-center" >
                          <p className="banner_card_pack_body_price_no_final-2" >${premiumCard.noTotalPrice}</p>
                          <p className="banner_card_pack_body_price_final-2">${premiumCard.totalPrice}</p>
                        </div>
                        <p>
                          Eye shadow: $7.55, $3.84  
                        </p>
                        <p>Foundation: $7, $4.8</p>
                        <p>Lip stick combo: $15.21, $5.43</p>
                      </div>
                    </div>

                    <div className="banner_card_pack_footer d-flex flex-row-reverse">
                        {/* <a className="banner_card_pack_footer_read" href="#">Read more...</a> */}
                        <button onClick={() => buyPack("Premium")} className="banner_card_pack_footer_button">
                          Buy now
                        </button>
                    </div>

                    <div className="banner_card_pack_footer-2 d-block mt-4 text-center">
                        <button onClick={() => buyPack("Premium")} className="banner_card_pack_footer_button">
                          Buy now
                        </button>
                        <p className="mt-2">
                          {/* <a className="banner_card_pack_footer_read" href="#">Read more...</a> */}
                        </p>
                        
                    </div>

                  </div>
                  {/* <-------------------- End Card Premium ----------------------> */ }



                  {/* <-------------------- Card Elegance ----------------------> */ }
                  <div className="banner_cards">
                    <div className="banner_card_pack_header d-flex justify-content-lg-between">
                      <div className="banner_card_pack_header_image_content d-flex">
                        <div className="banner_card_pack_header_image mr-2" >
                        <img
                          className="w-100"
                          src={IconElite}
                          alt="start1"
                        />
                        </div>

                        <h3 className="banner_card_pack_header_title_pack ">
                          Elegance
                        </h3>
                      </div>

                      <div className="">
                        <h3 className="banner_card_pack_header_title_off_pack">
                          {eleganceCard.discountRate}% Off
                        </h3>
                      </div>
                    </div>

                    <div className="banner_card_pack_body d-flex justify-content-lg-between">
                      <div className="text-left banner_card_pack_body_text">
                        <p>
                          Eye shadow: $7.55, $3.84  
                        </p>
                        <p>Foundation: $7, $4.8</p>
                        <p>Lip stick combo: $15.21, $5.43</p>
                      </div>
                      <div className="text-right" >
                        <p className="banner_card_pack_body_price_no_final" >${eleganceCard.noTotalPrice}</p>
                        <p className="banner_card_pack_body_price_final">${eleganceCard.totalPrice}</p>
                      </div>
                    </div>

                    <div className="banner_card_pack_body-2 d-flex justify-content-lg-between">
                      <div className="text-left banner_card_pack_body_text-2">
                        <div className="text-center" >
                          <p className="banner_card_pack_body_price_no_final-2" >${eleganceCard.noTotalPrice}</p>
                          <p className="banner_card_pack_body_price_final-2">${eleganceCard.totalPrice}</p>
                        </div>
                        <p>
                          Eye shadow: $7.55, $3.84  
                        </p>
                        <p>Foundation: $7, $4.8</p>
                        <p>Lip stick combo: $15.21, $5.43</p>
                      </div>
                    </div>

                    <div className="banner_card_pack_footer d-flex flex-row-reverse">
                        {/* <a className="banner_card_pack_footer_read" href="#">Read more...</a> */}
                        <button onClick={() => buyPack("Elegance")} className="banner_card_pack_footer_button">
                          Buy now
                        </button>
                    </div>

                    <div className="banner_card_pack_footer-2 d-block mt-4 text-center">
                        <button onClick={() => buyPack("Elegance")} className="banner_card_pack_footer_button">
                          Buy now
                        </button>
                        <p className="mt-2">
                          {/* <a className="banner_card_pack_footer_read" href="#">Read more...</a> */}
                        </p>
                        
                    </div>

                  </div>
                  {/* <-------------------- End Card Elegance ----------------------> */ }



                </div>
              </div>
            </div>

            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <span className="carousel-control-prev-icon"></span>
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <span className="carousel-control-next-icon"></span>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
