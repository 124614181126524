import React, { useEffect, useState } from "react";
import Slider from "react-slick";
// import Zoom from "react-img-zoom";


export default function Slider2({ data }) {
  // console.log("gallery", data);

  const [gallery, setGallery] = useState([]);

  const settings = {
    customPaging: function (i) {
      // console.log("i mini >>", i)
      return (
          <a key={i} className="bottom-indicators">
            <img
                src={gallery[i] && gallery[i].image ? gallery[i].image : ""}
                alt="headphone"
                className="img-fluid p-2"
            />
          </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    // autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },
    ],
  };

  useEffect(() => {
    if (data && data.gallery){
      setGallery([
        {
          id: "woeifmmsssd",
          image: data.image,
          sort: 1,
          featured: "yes"
        },
        ...data.gallery
      ])
    };
  }, [data]);

  return (
      <div>
        <Slider {...settings}>
          {gallery.length &&
          gallery.map((image, i) => {
            return (
                <div className="big-slide-items">
                  <img src={image.image} alt={image.name + i} />
                  {/*<Zoom*/}
                  {/*    img={image.image}*/}
                  {/*    zoomScale={2}*/}
                  {/*    width={550}*/}
                  {/*    height={550}*/}
                  {/*/>*/}
                </div>
            );
          })}
        </Slider>

        {/*
      <div className="big-slide-items">
        <img src="images/img_2.png" alt="whatch" />
      </div>
      <div className="big-slide-items">
        <img src="images/whatch-large.png" alt="whatch" />
      </div>
      <div className="big-slide-items">
        <img src="images/Image 11.png" alt="tv" />
      </div> */}
      </div>
  );
}