import React, { useState, useEffect } from "react";
// import OrderHistory from "../../components/Dashboards/Customer/OrderHistory/CustomerHistory";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductsVendor,
  deleteProduct,
} from "../../Actions/DashboardVendor/DashboarVendor";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

import "./AllProducts.css";
import Traffic from "../traffic/Traffic";

const PAGE = 1;

export default function AllProducts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxDashboardVendor = useSelector((state) => state.dashboardVendor);

  const [allProducts, setAllProducts] = useState([]);
  const [pagesScroll, setPagesScroll] = useState(PAGE);

  const [activetrafics, setActivetrafics] = useState(false);

  // const [dataTempProduct, setDataTempProduct] = useState([])

  const [totalPage, setTotalPage] = useState([]);

  // const [changePage, setChangePage] = useState(false);

  const alert = (product) => {
    // console.log(product);
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(
          deleteProduct(product.id, (result) => {
            // console.log("result callback",result)
            if (result.statusCode === 201) {
              setAllProducts(result.data.docs);
              swal(result.message, {
                icon: "success",
              });
            } else {
              swal("Oops, it seems there was an error, please try again!", {
                icon: "error",
              });
            }
          })
        );
      }
    });
  };

  const editProduct = (product) => {
    // console.log(product)
    localStorage.setItem("edit-product", JSON.stringify(product));
    //Redirect to view add product
    navigate("/product-vendor");
  };
  
  const handleActiveTraffics = (productId) => {
    // if(productId){
    //   navigate(`/product-celebrity-traffics?product=${productId}&index=0`);
    // }
  }

  window.onscroll = function () {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight &&
      totalPage > pagesScroll
    ) {
      setPagesScroll(pagesScroll + 1);
    }
  };

  useEffect(() => {
    dispatch(getAllProductsVendor(pagesScroll));
  }, [pagesScroll]);

  useEffect(() => {
    if (reduxDashboardVendor && reduxDashboardVendor.vendorAllProduct) {
      setTotalPage(reduxDashboardVendor.vendorAllProduct.data.totalPages);
      setAllProducts(reduxDashboardVendor.allProducts);
    }
  }, [reduxDashboardVendor.vendorAllProduct]);

  console.log(allProducts);

  return (
    <div>
      {reduxDashboardVendor.loading ? <div className="se-pre-con"></div> : ""}
      {activetrafics ? (
        <Traffic />
      ) : (
        <div className="customer-history-inner">
          <h2 className="cutomer-section-hed">Product List</h2>
          <div className="customer-history-table-main table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Detail</th>
                  <th>Discont rate</th>
                  <th>Discont</th>
                  <th>Amount</th>
                  <th>Stock</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log(allProducts)} */}
                {allProducts.map((product, i) => {
                  return (
                    <tr
                      className="cursor-pointer"
                      onClick={() => handleActiveTraffics(product.id)}
                      key={product.id}
                    >
                      <td>
                        <span className="ch-table-img">
                          <img src={product.image} alt="whatch-large"></img>
                        </span>
                      </td>
                      <td>{product.name}</td>
                      <td>{product.description}</td>
                      <td className="text-center">{product.discount ? `% ${product.discount}` : "N/A"}</td>
                      <td>$ {product.discount ? (product.amount - ((product.amount / 100) * product.discount)).toFixed(2) : "N/A"}</td>
                      <td>$ {product.amount}</td>
                      <td className="progress-text">{product.stock}</td>
                      <td>
                        <button
                          type="button"
                          onClick={() => editProduct(product)}
                          className="edit-history"
                        >
                          <i className="fa fa-edit"></i>
                        </button>
                        <button
                          onClick={() => alert(product)}
                          className="edit-history ml-3"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
