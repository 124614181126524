import React, { useState, useEffect /* , Fragment */ } from "react";
import "./CheckoutBilling.css";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UpdateOrderBulling } from "../../Actions/Products/ProductsAction";
// import StepTest from "./StepTest";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Auth } from "../../utils/Auth";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["Billing Detail", "Data card"];

export default function CheckoutBilling({ orderId }) {
  const cartStorage = localStorage.getItem("order");
  const userStorage = localStorage.getItem("userInfo");
  const url = `https://fangway.com/thankyou?o=${orderId}`;
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [totalOrder, setTotalOrder] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [shipping, setShipping] = useState(-1);
  const [dataUser, setDataUser] = useState({
    fullName: "",
    phone: "",
    email: "",
    address: "",

    postcode: "",
    buildingName: "",
    city: "",
    provinceCode: "",
    postalCodeExtension: "",
    countryCode: "",
  });
  const [errorFullName, setErrorFullName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  // const [errorCountry, setErrorCountry] = useState("");
  const [errorPostCode, setErrorPostCode] = useState("");

  const [errorBuildingName, setErrorBuildingName] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorProvinceCode, setErrorProvinceCode] = useState("");
  const [errorPostalCodeExtension, setErrorPostalCodeExtension] = useState("");
  const [errorCountryCode, setErrorCountryCode] = useState("");

  const [load, setLoad] = useState(false);
  const [discountOrder, setDiscountOrder] = useState(0);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    try {
      // e.preventDefault();
      if ((await handleClickCheckout()) === false) return;

      setLoad(true);

      await axios.post(
        `https://fangway.com/ecommerce/shipment/streeaddress/validate`,
        {
          ConsigneeName: dataUser.fullName,
          BuildingName: dataUser.buildingName,
          AddressLine: dataUser.address,
          City: dataUser.city,
          State: dataUser.provinceCode,
          PostalCode: dataUser.postcode,
          PostalCodeExtension: dataUser.postalCodeExtension,
          CountryCode: dataUser.countryCode,
        },
        {
          headers: Auth(),
        }
      );

      await axios.put(
        `https://fangway.com/ecommerce/order/${orderId}`,
        {
          ConsigneeName: dataUser.fullName,
          BuildingName: dataUser.buildingName,
          AddressLine: dataUser.address,
          City: dataUser.city,
          State: dataUser.provinceCode,
          PostalCode: dataUser.postcode,
          PostalCodeExtension: dataUser.postalCodeExtension,
          CountryCode: dataUser.countryCode,
          Email: dataUser.email,
        },
        { headers: Auth() }
      );

      const data = await axios.get(
        `https://fangway.com/ecommerce/order/${orderId}/shipping`,
        { headers: Auth() }
      );

      console.log("date shipping >> ", data);

      if (data.data.statusCode === 200) {
        setLoad(false);
        setShipping(5);
        setTotalOrder(totalOrder + 15);
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }

      // setTimeout(function(){

      // },3000)
    } catch (error) {
      setLoad(false);
      toast.error("Billing Detail is incorrect");
    }
  };

  const hableChange = (e) => {
    setDataUser({ ...dataUser, [e.target.name]: e.target.value });
  };

  const handleClickCheckout = async () => {
    if (!dataUser.fullName) {
      setErrorFullName("*Full name is required");
    } else {
      setErrorFullName("");
    }
    if (!dataUser.phone) {
      setErrorPhone("*Phone is required");
    } else {
      setErrorPhone("");
    }
    if (!dataUser.email) {
      setErrorEmail("*Email is required");
    } else {
      setErrorEmail("");
    }
    if (!dataUser.address) {
      setErrorAddress("*Address is required");
    } else {
      setErrorAddress("");
    }

    if (!dataUser.postcode) {
      setErrorPostCode("*Postcode is required");
    } else {
      setErrorPostCode("");
    }

    if (!dataUser.buildingName) {
      setErrorBuildingName("*Building Name is required");
    } else {
      setErrorBuildingName("");
    }

    if (!dataUser.city) {
      setErrorCity("*City is required");
    } else {
      setErrorCity("");
    }

    if (!dataUser.provinceCode) {
      setErrorProvinceCode("*Province code is required");
    } else {
      setErrorProvinceCode("");
    }

    if (!dataUser.postalCodeExtension) {
      setErrorPostalCodeExtension("*Postal code extension is required");
    } else {
      setErrorPostalCodeExtension("");
    }

    if (!dataUser.countryCode) {
      setErrorCountryCode("*Country code is required");
    } else {
      setErrorCountryCode("");
    }

    if (
      dataUser.fullName &&
      dataUser.email &&
      dataUser.phone &&
      dataUser.address &&
      dataUser.buildingName &&
      dataUser.city &&
      dataUser.provinceCode &&
      dataUser.postalCodeExtension &&
      dataUser.countryCode &&
      dataUser.postcode
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoad(true);
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: url,
      },
    });
    if (error) {
      setLoad(false);
      toast.error(error.message);
    } else {
      setLoad(false);
      //Delete cart in storage
      const type = localStorage.getItem("orderType");
      if (type === "Cart") {
        localStorage.removeItem("carUser");
      }
      navigate(`/thankyou?o=${orderId}`);
    }
  };

  useEffect(() => {
    if (cartStorage !== null) {
      const car = JSON.parse(cartStorage);
      const orderType = localStorage.getItem("orderType");
      let subTotalTem = 0;
      // let shippingTemp = 0;
      let disco = 0;
      let discountMoney = 0;
      for (let i = 0; i < car.length; i++) {
        const element = car[i];

        subTotalTem += element.product.promotion
          ? parseFloat(element.amount) *
            parseFloat(element.product.promotion.discountPrice) *
            element.amount
          : orderType === "One"
          ? parseFloat(element.amount) *
            (element.product.amount -
              (element.product.amount / 100) * element.product.discount) *
            element.amount
          : parseFloat(element.amount) *
            (element.product.amount -
              (element.product.amount / 100) * element.product.newDiscount) *
            element.amount;

        // shippingTemp += parseFloat(
        //   element.product.shipping ? element.product.shipping : 5
        // );

        disco += element.product.promotion
          ? parseFloat(element.amount) * element.amount
          : element.product.amount * element.amount;

        discountMoney +=
          orderType === "One"
            ? parseFloat(element.product.amount) *
              parseFloat(`.${element.product.discount}`) *
              element.amount
            : parseFloat(element.product.amount) *
              parseFloat(`.${element.product.newDiscount}`) *
              element.amount;

        // subTotalTem += element.product.promotion
        //   ? parseFloat(element.amount) *
        //     parseFloat(element.product.promotion.discountPrice)
        //   : parseFloat(element.amount) *
        //     (element.product.amount -
        //       (element.product.amount / 100) * element.product.discount);

        // shippingTemp += element.product.shipping;
      }
      const da = parseFloat(disco) - parseFloat(discountMoney);
      setCartData(car);
      setSubtotal(disco);
      // setShipping(shippingTemp);
      setTotalOrder(parseFloat(da));
      setDiscountOrder(discountMoney);
    }
    if (userStorage !== null) {
      const user = JSON.parse(userStorage);
      setDataUser({
        fullName: `${user.profile.firstName} ${user.profile.middleName} ${user.profile.lastName}`,
        phone: user.profile.phone,
        email: user.profile.email,
        address: "",
        country: "",
        postcode: "",
      });
    }
  }, [cartStorage]);

  if (!userStorage) {
    navigate("/");
  }

  return (
    <div>
      {!stripe || load ? <div className="se-pre-con"></div> : ""}
      <section className="shopping-cart-main">
        <div className="container">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-md-8">
                <Stack sx={{ width: "100%" }} spacing={4}>
                  <Box sx={{ width: "100%" }}>
                    <Stepper
                      activeStep={activeStep}
                      alternativeLabel
                      connector={<ColorlibConnector />}
                    >
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel
                              StepIconComponent={ColorlibStepIcon}
                              {...labelProps}
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    <React.Fragment>
                      <form className="billing-form">
                        {activeStep === 0 ? (
                          <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label>
                                  Full name<sup>*</sup>
                                </label>
                                <input
                                  value={dataUser.fullName}
                                  onChange={(e) => hableChange(e)}
                                  name="fullName"
                                  className="form-control"
                                  type="text"
                                ></input>
                                <p className="checkout-error">
                                  {errorFullName}
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label>
                                  Building name<sup>*</sup>
                                </label>
                                <input
                                  value={dataUser.buildingName}
                                  onChange={(e) => hableChange(e)}
                                  name="buildingName"
                                  className="form-control"
                                  type="text"
                                  // onFocus={}
                                ></input>
                                <p className="checkout-error">
                                  {errorBuildingName}
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label>
                                  phone<sup>*</sup>
                                </label>
                                <input
                                  value={dataUser.phone}
                                  onChange={(e) => hableChange(e)}
                                  name="phone"
                                  className="form-control"
                                  type="text"
                                  // onFocus={}
                                ></input>
                                <p className="checkout-error">{errorPhone}</p>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label>
                                  email address<sup>*</sup>
                                </label>
                                <input
                                  value={dataUser.email}
                                  onChange={(e) => hableChange(e)}
                                  name="email"
                                  className="form-control"
                                  type="email"
                                ></input>
                                <p className="checkout-error">{errorEmail}</p>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label>
                                  City<sup>*</sup>
                                </label>
                                <input
                                  value={dataUser.city}
                                  onChange={(e) => hableChange(e)}
                                  name="city"
                                  className="form-control"
                                  type="text"
                                ></input>
                                <p className="checkout-error">{errorCity}</p>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label>
                                  address<sup>*</sup>
                                </label>
                                <input
                                  value={dataUser.address}
                                  onChange={(e) => hableChange(e)}
                                  name="address"
                                  className="form-control"
                                  type="text"
                                ></input>
                                <p className="checkout-error">{errorAddress}</p>
                              </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label>
                                  Postal code<sup>*</sup>
                                </label>
                                <input
                                  value={dataUser.postcode}
                                  onChange={(e) => hableChange(e)}
                                  name="postcode"
                                  className="form-control"
                                  type="text"
                                ></input>
                                <p className="checkout-error">
                                  {errorPostCode}
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label>
                                  Province Code<sup>*</sup>
                                </label>
                                <input
                                  value={dataUser.provinceCode}
                                  onChange={(e) => hableChange(e)}
                                  name="provinceCode"
                                  className="form-control"
                                  type="text"
                                ></input>
                                <p className="checkout-error">
                                  {errorProvinceCode}
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label>
                                  Postal code extension<sup>*</sup>
                                </label>
                                <input
                                  value={dataUser.postalCodeExtension}
                                  onChange={(e) => hableChange(e)}
                                  name="postalCodeExtension"
                                  className="form-control"
                                  type="text"
                                ></input>
                                <p className="checkout-error">
                                  {errorPostalCodeExtension}
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label>
                                  Country code<sup>*</sup>
                                </label>
                                <input
                                  value={dataUser.countryCode}
                                  onChange={(e) => hableChange(e)}
                                  name="countryCode"
                                  className="form-control"
                                  type="text"
                                ></input>
                                <p className="checkout-error">
                                  {errorCountryCode}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="form-group mt-5">
                            <label>Card</label>
                            <PaymentElement />
                          </div>
                        )}
                      </form>

                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        {/* <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button> */}
                        <Box sx={{ flex: "1 1 auto" }} />

                        {activeStep === steps.length - 1 ? null : (
                          <Button
                            style={{
                              border: "0px",
                              padding: "10px 40px",
                              fontSize: "17px",
                              backgroundColor: "rgb(248,199,44)",
                              color: "black",
                            }}
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        )}
                      </Box>
                    </React.Fragment>
                  </Box>
                </Stack>
              </div>
              <div className="col-md-4">
                <section className="shopping-cart-totals your-order-cart">
                  <h1 className="cartTotals">Your Order</h1>

                  <h1 className="cartTotals">
                    Product <span className="float-right">Total</span>
                  </h1>

                  {cartData.length > 1 ? (
                    cartData.map((pro, i) => {
                      return (
                        <div key={i}>
                          <div className="cart-subtotals">
                            <div className="subtotals-left ">
                              <h2>{pro.product.name}</h2>
                            </div>
                            <div className="subtotals-left">
                              <h2>${pro.product.amount.toFixed(2)}</h2>
                            </div>
                          </div>
                          <div key={i} className="cart-subtotals">
                            <div className="subtotals-left ">
                              {/* <h2>{pro.product.name}</h2> */}
                            </div>
                            <div className="subtotals-left">
                              <h2
                                style={{
                                  textDecoration: "line-through",
                                  marginTop: "-30%",
                                }}
                                className="text-danger "
                              >
                                $
                                {
                                  /* pro.product.promotion
                                  ? (
                                      parseFloat(
                                        pro.product.promotion.discountPrice
                                      ) * parseFloat(pro.amount)
                                    ).toFixed(2)
                                  :  */ pro.product.newDiscount
                                    ? (
                                        parseFloat(
                                          pro.product.amount *
                                            parseFloat(
                                              `0.${pro.product.newDiscount}`
                                            )
                                        ) * parseFloat(pro.amount)
                                      ).toFixed(2)
                                    : (
                                        parseFloat(
                                          pro.product.amount *
                                            parseFloat(
                                              `0.${pro.product.discount}`
                                            )
                                        ) * parseFloat(pro.amount)
                                      ).toFixed(2)
                                }
                              </h2>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  {/* <div className="cart-subtotals">
                    <div className="subtotals-left">
                      <h2>
                        Lite 42 mm Smart <br />
                        Watches
                      </h2>
                    </div>
                    <div className="subtotals-left">
                      <h2>$99.0</h2>
                    </div>
                  </div>
                  <div className="cart-subtotals">
                    <div className="subtotals-left">
                      <h2>
                        Lite 42 mm Smart <br />
                        Watches
                      </h2>
                    </div>
                    <div className="subtotals-left">
                      <h2>$99.0</h2>
                    </div>
                  </div> */}
                  <div className="cart-subtotals">
                    <div className="subtotals-left">
                      <h2>Subtotal</h2>
                    </div>
                    <div className="subtotals-left">
                      <h2>${subtotal.toFixed(2)}</h2>
                    </div>
                  </div>
                  <div className="cart-subtotals">
                    <div className="subtotals-left">
                      <h2>Discount</h2>
                    </div>
                    <div className="subtotals-left">
                      <h2
                        style={{ textDecoration: "line-through" }}
                        className="text-danger"
                      >
                        ${discountOrder.toFixed(2)}
                      </h2>
                    </div>
                  </div>

                  <div className="cart-subtotals">
                    <div className="subtotals-left">
                      <h2>Shipping</h2>
                    </div>
                    <div className="subtotals-left">
                      {shipping === -1 ? (
                        <h2 className="text-warning">Pending</h2>
                      ) : (
                        <h2>$ {shipping}</h2>
                      )}
                    </div>
                  </div>
                  <div className="total-last-cart">
                    <h2>Total</h2>
                    <h3>${totalOrder.toFixed(2)}</h3>
                  </div>
                  <ul
                    // onClick={(e) => handleClickCheckout(e)}
                    className="checkout-list"
                  >
                    <li>
                      <a href="#">
                        <button
                          disabled={activeStep === 0 ? true : false}
                          type="submit"
                          className="pay-button"
                        >
                          Proceed to Checkout
                        </button>
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </form>
          <PaymentMethod />
        </div>
      </section>
    </div>
  );
}
