import React/* , { Component }  */from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import FangwayRoute from "./Routes/FangwayRoute";
import store from "./Store/Store";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";


export default function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <FangwayRoute />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Provider>
    </div>
  );
}