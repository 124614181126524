import React from 'react';
import "./PromoteItems.css"

export default function PromoteItems() {
  return <div>
    <section className='promote-items-main'>
      <h1 className="ActiveAudiences-hed">Promote Items</h1>
      <div className='promote-view-list-select'>
        <select className="form-control">
          <option>View / Edit Prodcuts</option>
          <option>Active Audience</option>
          <option>old  Audience</option>
        </select>
        <button type='button' className='btn add-new-pro-btn'><span><img className='add-plus' src='images/add-plus.png' alt='plus-icon'></img></span>Add New Product</button>

      </div>
      <div className='promote-items-box'>
        <div className="form-group mb-4 pb-2">
          <label className="promote-item-label" for="">Product Image (.Jpg, .PNG, .Gif)</label>
          <div className="input-group">
            <input type="text" name="filename1"
              className="form-control file-textbox"
              placeholder="No file selected" readonly />
            <span className="input-group-btn">
              <div className="btn btn-default  custom-file-uploader">
                <input type="file" name="file"
                  onchange="this.form.filename1.value = this.files.length ? this.files[0].name : ''" />
                Browse
              </div>
            </span>
          </div>
        </div>
        <div className='form-group'>
          <label className="promote-item-label" for="">Product Title</label>
          <textarea className='title-textarea form-control' value='Playstation DualSense Wireless Controller'></textarea>
        </div>
        <div className='form-group'>
          <label className="promote-item-label" for="">Product Source</label>
          <textarea className='pro-source-textarea form-control' value='https://www.amazon.com/DualSense-Wireless-Controller-PlayStation-5/dp/B08FC6C75Y/ref=sr_1_1?dchild=1&fst=as%3Aoff&pf_rd_i=16225016011&pf_rd_m=ATVPDKIKX0DER&pf_rd_p=03b28c2c-71e9-4947-aa06-f8b5dc8bf880&pf_rd_r=TGQCM8FM5PDWDGQMPT5J&pf_rd_s=merchandised-search-3&pf_rd_t=101&qid=1618675917&rnid=16225016011&s=videogames-intl-ship&sr=1-1'></textarea>
        </div>
        <div className='form-group'>
          <label className="promote-item-label" for="">Coupon Code</label>
          <span className='Coupon-code'>5363700HJH</span>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 mm-pr-0'>
          <button className='save-chnages btn'>Save Changes</button>
        </div>
        <div className='col-md-6'>
          <button className='save-chnages btn'>Promote Now!</button>
        </div>
      </div>

    </section>

  </div>
}
