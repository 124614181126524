import axios from "axios";
import { authHeader } from "../../utils/Auth";

export const forgotPasswordAction = (email) => async (dispatch) => {
  try {
    dispatch({
      type: "USER_FORGOT_PASSWORD_REQUEST",
    });

    //   const config = {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   };

    const { data } = await axios.post(
      // `${process.env.REACT_APP_API_URL}users/auth/forget-password`,
      // `${process.env.BASE_URL}/users/auth/forgot-password`,
      `https://fangway.com/users/auth/forgot-password`,
      {
        email,
      }
      // config
    );
    dispatch({
      type: "USER_FORGOT_PASSWORD_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "USER_FORGOT_PASSWORD_FAIL",
      payload: error,
      // error.response && error.response.data.message
      //   ? error.response.data.message
      //   : error.message,
    });
  }
};

export const changePasswordAction =
  (password, cpassword) => async (dispatch) => {
    try {
      dispatch({
        type: "CHANGE_PASSWORD_REQUEST",
      });

      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log("userInfo:>", userInfo.data.token);

      const config = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userInfo.data.token}`,
        },
      };

      const { data } = await axios.post(
        "https://fangway.com/users/auth/change-password",
        {
          password,
          cpassword,
        },
        config
      );
      dispatch({
        type: "CHANGE_PASSWORD_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "CHANGE_PASSWORD_FAIL",
        payload: error,
        // error.response && error.response.data.message
        //   ? error.response.data.message
        //   : error.message,
      });
    }
  };
