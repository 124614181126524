import React from "react";
import ProductsCard from "./ProductsCard";

export default function OfferSection({
  productData,
  slug,
  description,
  title,
}) {
  return (
    <React.Fragment>
      <section className="container-fluid product-inner-main">
        <div className=" product_header">
          <h4 className="elctornic-text">{slug}</h4>
        </div>
        <div className="product_view">
          <div className="">
            {title && <h4 className="elctornic-text">{title}</h4>}
            <p className="shp-home">{description}</p>
          </div>
          <div className="text-right">
            <a href="" className="btn btn-warning view-all2">
              View All{" "}
            </a>
          </div>
        </div>
          <section className="container-fluid product-inner-main p-0">
          <div className=" mm-procuct-list">
            {productData &&
              productData.map((el, index) => {
                return <ProductsCard key={index} data={el} />;
              })}
          </div>
        </section>
      </section>
    </React.Fragment>
  );
}
