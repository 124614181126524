import React from "react";
import DetailSection from "./DetailSection";
import Slider2 from "./Slider2";
export default function ProductDetailSlider({data}) {
    return <div>

        <section className='Product-detail-slider'>
            <div className="container">-
                <div className="row">
                    <div className="col-md-6">
                        <Slider2 data={data} />
                    </div>
                    <DetailSection data={data}/>
                </div>
            </div>

        </section >
    </div>
}