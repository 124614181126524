import React from "react";
import "./ResetPassword.css";

import Logo from './assets/FangWay-reset.png';

export default function ResetPassword() {
  return (
    <div className="forget__password-2">
      <div className="forget__password-2-content">
        <img className="forget__password-2-image" src={Logo} alt="logo fangway" />
        <div className="forget__password-2-content-form">
          <h2>Create New password</h2>
          <form>
            {/* <h4>Email address or phone number</h4> */}
            <input placeholder="New Password" />
            <input placeholder="Repeat Password" />
            <button>Save</button>
          </form>
        </div>
      </div>
    </div>
  );
}
