import React from 'react';
import "./MySponsers.css";
export default function MySponsers() {
    return <div>
        <div className='MySponsers-main'>
            <h2 className='my-sponser-text'>My Sponsors</h2>
            <div className="my-sponsers-slider owl-carousel owl-theme">
                <div className='sponser-img'>
                    <img src='images/atica.png' alt='atica'></img>
                </div>
                <div className='sponser-img'>
                    <img src='images/amara.png' alt='atica'></img>
                </div>
                <div className='sponser-img'>
                    <img src='images/asgardia-2.png' alt='atica'></img>
                </div>
                <div className='sponser-img'>
                    <img src='images/aven.png' alt='atica'></img>
                </div>

            </div>
        </div>
    </div>
}
