import React from "react";
import "./Deals.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

export default function Deals({ data }) {
  const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    // speed: 100,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleClickCard = (slug) => {
    localStorage.setItem("product-detail", JSON.stringify(slug));
    navigate(`/product-detail?product=${slug}`);
  };

  return (
    <div>
      <section className="deal-section">
        <div className="container">
          <div className="width-carosal">
            <Slider {...settings}>
              {data &&
                data.map((_,i) => (
                  <div
                  key={i}
                    onClick={(e) => handleClickCard(_.product.slug)}
                    className="product-grid4 deal-carousel-item"
                  >
                    <span className="deal-Off">{_.discount}%OFF</span>
                    <p className="deal-text-para">{_.title}</p>
                    <div className="deal-inner-text">
                      <div className="deal-box-left">
                        <p>{_.product.title}</p>
                        <div className="d-flex" >
                          <h6 style={{textDecoration:"line-through"}} >${_.product.price}</h6>{" "}
                          <h6 className="ml-1">${_.discountPrice}</h6>
                        </div>

                        <div className="text-left">
                          <a className="deal-buy" href="#">
                            Buy Now
                          </a>
                        </div>
                      </div>
                      <span className="deal-box-img1">
                        <img
                          className="deal-of-img"
                          src={_.product.imagePath}
                          alt="new"
                        />
                      </span>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
}
