import axios from "axios";
import { toast } from "react-toastify";

//-----------------------Whislist products  ---------------------//
export const gettingWishlist = (page, limit) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "GET_WISH_LIST_INIT",
    });

    const user = JSON.parse(localStorage.getItem("userInfo"));

    if(!user) return;

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    console.log("config::>", config);

    const { data } = await axios.get(
      `https://fangway.com/products/user/whishlist?page=${page}&limit=${limit}`,
      config
    );
    // toast.success(data.message);
    dispatch({
      type: "GET_WISH_LIST_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "GET_WISH_LIST_FAIL",
      payload: error,
      // error.response && error.response.data.message
      //   ? error.response.data.message
      //   : error.message,
    });
  }
};

//----------------------- Delete Whislist products  ---------------------//
export const deleteWishlist = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "DELETE_WISH_LIST_INIT",
    });

    const user = JSON.parse(localStorage.getItem("userInfo"));

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    console.log("config::>", config);

    const { data } = await axios.delete(
      `https://fangway.com/products/user/whishlist/${id}`,
      config
    );
    // toast.success(data.message);
    dispatch({
      type: "DELETE_WISH_LIST_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "DELETE_WISH_LIST_FAIL",
      payload: error,
      // error.response && error.response.data.message
      //   ? error.response.data.message
      //   : error.message,
    });
  }
};
