import React,{useState, useEffect} from 'react';
import PaymentHistory from '../../../components/Dashboards/Customer/PaymentHistory/PaymentHistory';
import CustomerSidebar from '../../../components/Dashboards/Elements/CustomerSidebar/CustomerSidebar';

import { verifyTokenUser } from '../../../utils/Auth';
import { useNavigate } from 'react-router-dom';


export default function CustomerPaymentHistory() {

  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  
  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])

  return (<div>
      {loadingSesion ? <div className="se-pre-con"></div> : ""}
      <PaymentHistory/>
       <CustomerSidebar/>
  </div>)
}
