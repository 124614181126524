import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // getCustomerSetting,
  // updateCustomerDisplaySetting,
  updateCustomerSetting,
} from "../../../../Actions/CustomerActions/customerAction";
import "./ProfileSettings.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { changePasswordAction } from "../../../../Actions/ForgetPasswordAction/ForgetPasswordAction";
import { Avatar/* , MenuItem, Select */ } from "@mui/material";
// import axios from "axios";
// import { authHeader } from "../../../../utils/Auth";
// import { toast } from "react-toastify";
import { uploadFile } from "../../../../Actions/UploadFileAction/UploadFileAction";
import { gettingAuthProfile } from "../../../../Actions/GetAuthProfileAction/GetAuthProfileAction";
import { useNavigate } from "react-router-dom";

export default function ProfileSettings(props) {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    cpassword: "",
    gender: "",
    role: "",
    dob: "",
    nickName: "",
    middleName: "",
  });
  let uploadFileReducer = useSelector(
    (state) => state.uploadFileReducer.imagePath
  );

  let imagePath = uploadFileReducer && uploadFileReducer.data.data[0].filepath;

  // console.log("imagePath::>", imagePath);

  let getAuth = JSON.parse(localStorage.getItem("getAuth"));
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  let profile = (getAuth && getAuth.imagePath) || "";

  const [profileImage, setProfileImage] = useState(profile);

  const gettingProfile = useSelector((state) => state.gettingAuthProfile);

  let uploadImage = userInfo && userInfo.profile && userInfo.profile.imagePath;

  // console.log("uploadFile::>", uploadImage);

  // const { getAuthProfile: { celebrity: { authId = "" } = {} } = {} } =
  //   gettingProfile;

  // let getAuthProfile = gettingProfile && gettingProfile.getAuthProfile;
  let authProfile = JSON.parse(localStorage.getItem("getAuth"));

  // console.log("gettingAuthProfile::>", gettingProfile);
  const userLoginInfo = useSelector((state) => state.gettingAuthProfile);

  const { getAuthProfile: { authId = "" } = {} } = userLoginInfo;

  useEffect(() => {
    if (imagePath) {
      setProfileImage(imagePath);
    }
  }, [imagePath]);

  useEffect(() => {
    // debugger;
    if (authId) {
      localStorage.setItem(
        "getAuth",
        JSON.stringify(userLoginInfo && userLoginInfo.getAuthProfile)
      );
    }
  }, [authId]);

  const uploadFileImg = useSelector((state) => state.uploadFileReducer);
  console.log("uploadFileImg::>", uploadFileImg);
  // let imagePath = uploadFileReducer && uploadFileReducer.data

  useEffect(async () => {
    setIsLoading(true);
    await dispatch(gettingAuthProfile());

    setIsLoading(false);
  }, []);

  // useEffect(() => {
  //   if (userInfo) {
  //     // setProfileData(uploadImage);
  //   }
  // }, [userInfo]);

  const file = useSelector((state) => state.uploadFileReducer.imagePath);
  let image = file && file.data && file.data.data[0].filepath;
  console.log("profileImage::>", image);

  useEffect(() => {
    if (file) {
      // console.log("profileImage::>", image);

      setProfileImage(image);
    } else {
      setProfileData(uploadImage);
    }
  }, [file]);

  const handleChange = async (e) => {
    let file = e.target.files[0];
    // alert("if");
    // let formData = new FormData();
    // formData.append("files", file);
    setIsLoading(true);
    await dispatch(uploadFile(file));

    setIsLoading(false);

    // setProfileImage(file);
  };
  return (
    <div>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <section className="profile-setting-main">
        <h1 className="ActiveAudiences-hed">Profile Settings</h1>
        <div className="profile-settings-inner">
          {/* <form>
            <div className="row">
              <div className="col-md-3">
                <div className="edit-profile-img">
                  <img src="images/Elipse 1.png" alt="profile"></img>
                  <span>
                    <img
                      src="images/Icon material-mode-edit.png"
                      alt="edit"
                    ></img>
                  </span>
                </div>
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6">
                    <label className="promote-item-label">First Name</label>
                    <div className="single-input">
                      <span>
                        <i className="fas fa-user"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control file-textbox"
                        placeholder="Benjamin"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="promote-item-label">Last Name</label>
                    <div className="single-input">
                      <input
                        type="text"
                        className="form-control file-textbox"
                        placeholder="Guerrero"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="promote-item-label">Email Address</label>
                    <div className="single-input">
                      <span>
                        <i className="fas fa-at"></i>
                      </span>
                      <input
                        disabled
                        type="email"
                        className="form-control file-textbox"
                        placeholder="Benjamin@mail.com"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="promote-item-label">nickName Number</label>
                    <div className="single-input">
                      <input
                        type="text"
                        className="form-control file-textbox"
                        placeholder="+92"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="promote-item-label">Nick Name</label>
                    <div className="single-input">
                      <input
                        type="text"
                        className="form-control file-textbox"
                        placeholder="Guerrero"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="promote-item-label">Middle Name</label>
                    <div className="single-input">
                      <input
                        type="text"
                        className="form-control file-textbox"
                        placeholder="Guerrero"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="promote-item-label">Date of Birth</label>
                    <div className="single-input">
                      <input
                        type="date"
                        className="form-control file-textbox"
                        placeholder="Guerrero"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="type">Select Role</label>
                    <Field
                      name="type"
                      as="select"
                      className="form-control file-textbox"

                     
                    >
                      <option value=""></option>
                      <option value="Diving">Diving</option>
                      <option value="Permanent">Permanent</option>
                      <option value="Expert">Expert</option>
                      <option value="Interny">Interny</option>
                    </Field>

                    
                  </div>
                  <div className="col-md-6">
                   x
                  </div>
                  <div className="col-md-6">
                    <button className="btn update-profile-btn">
                      Update Profile Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form> */}
          {authProfile ? (
            <>
              <div className="row ">
                <div className="col-md-3">
                  <div className="edit-profile-img">
                    {/* <img src="images/Elipse 1.png" alt="profile"></img> */}
                    {/* <img src={imagePath} alt="profile"></img> */}
                    {profileImage || props.imagePath ? (
                      <img
                        src={profileImage || props.imagePath}
                        alt="Profile image"
                      />
                    ) : (
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 100, height: 100 }}
                      />
                    )}
                    <div className="image-upload">
                      <label for="file-input">
                        <img
                          src="images/Icon material-mode-edit.png"
                          alt="edit"
                        />
                      </label>

                      <input
                        id="file-input"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    firstName: (authProfile && authProfile.firstName) || "",
                    lastName: (authProfile && authProfile.lastName) || "",
                    email: (authProfile && authProfile.email) || "",
                    phone: (authProfile && authProfile.phone) || "",

                    gender: (authProfile && authProfile.gender) || "",
                    role: (authProfile && authProfile.role) || "",
                    dob: (authProfile && authProfile.dob) || "",
                    nickName: (authProfile && authProfile.nickName) || "",
                    middleName: (authProfile && authProfile.middleName) || "",
                  }}
                  // initialValues={{
                  //   firstName: (authProfile && authProfile.firstName) || "",
                  //   lastName: (authProfile && authProfile.lastName) || "",
                  //   email: (authProfile && authProfile.email) || "",
                  //   phone: (authProfile && authProfile.phone) || "",

                  //   gender: (authProfile && authProfile.gender) || "",
                  //   role: (authProfile && authProfile.role) || "",
                  //   dob: (authProfile && authProfile.dob) || "",
                  //   nickName: (authProfile && authProfile.nickName) || "",
                  //   middleName: (authProfile && authProfile.middleName) || "",
                  // }}
                  // initialValues={profileData}
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().required("First Name is required"),
                    lastName: Yup.string().required("Last Name is required"),
                    middleName: Yup.string().required(
                      "Middle Name is required"
                    ),
                    dob: Yup.string().required("Date of birth is required"),
                    // role: Yup.string().required("Role is required"),
                    nickName: Yup.string().required("NickName is required"),
                    email: Yup.string()
                      .email("Email is invalid")
                      .required("Email is required"),
                    phone: Yup.string("Enter a valid Phone Number")
                      .required("Phone number required")
                      .length(13, "Phone number is not valid"),
                    // password: Yup.string()
                    //   .min(6, "Password must be at least 6 characters")
                    //   .required("Password is required"),
                    // cpassword: Yup.string()
                    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
                    //   .required("Confirm Password is required"),
                  })}
                  onSubmit={async (fields) => {
                    // debugger;
                    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(fields, null, 7));
                    console.log("filds::>", fields);
                    setIsLoading(true);

                    await dispatch(
                      updateCustomerSetting(
                        fields.firstName,
                        fields.lastName,
                        fields.middleName,
                        fields.email,
                        fields.phone,
                        fields.password,
                        fields.cpassword,
                        fields.gender,
                        fields.role,
                        fields.dob,
                        fields.nickName,
                        imagePath
                      )
                    );
                    await dispatch(gettingAuthProfile());
                    // setProfileImage("");
                    setIsLoading(false);

                    // navigate("/customer-dashboard");
                  }}
                  render={({
                    errors,
                    status,
                    touched,
                    values,
                    setFieldValue,
                    handleBlur,
                    handleChange,
                    isValid,
                    dirty,
                    // handleSubmit
                  }) => (
                    <Form>
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6">
                            <label
                              htmlFor="firstName"
                              className="promote-item-label"
                            >
                              First Name
                            </label>
                            <div className="single-input">
                              <span>
                                <i className="fas fa-user"></i>
                              </span>
                              {console.log("firstName::>", profileData)}
                              {console.log("firstName::>", values)}
                              <Field
                                name="firstName"
                                type="text"
                                value={values.firstName}
                                className={
                                  "form-control file-textbox" +
                                  (errors.firstName && touched.firstName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="First name"
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="lastName"
                              className="promote-item-label"
                            >
                              Last Name
                            </label>
                            <div className="single-input">
                              <span>
                                <i className="fas fa-user"></i>
                              </span>
                              <Field
                                name="lastName"
                                type="text"
                                className={
                                  "form-control file-textbox" +
                                  (errors.lastName && touched.lastName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Last name"
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="email"
                              className="promote-item-label"
                            >
                              Email
                            </label>
                            <div className="single-input">
                              <span>
                                <i className="fas fa-at"></i>
                              </span>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control file-textbox" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Email"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="phone"
                              className="promote-item-label"
                            >
                              Phone Number
                            </label>
                            <div className="single-input">
                              <span>
                                <i className="fas fa-phone"></i>
                              </span>
                              <Field
                                name="phone"
                                type="text"
                                className={
                                  "form-control file-textbox" +
                                  (errors.phone && touched.phone
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="phone"
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="phone"
                              className="promote-item-label"
                            >
                              Nick Name
                            </label>
                            <div className="single-input">
                              <Field
                                name="nickName"
                                type="text"
                                className={
                                  "form-control file-textbox" +
                                  (errors.nickName && touched.nickName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Nick Name"
                              />
                              <ErrorMessage
                                name="nickName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="phone"
                              className="promote-item-label"
                            >
                              Middle Name
                            </label>
                            <div className="single-input">
                              <Field
                                name="middleName"
                                type="text"
                                className={
                                  "form-control file-textbox" +
                                  (errors.middleName && touched.middleName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Middle Name"
                              />
                              <ErrorMessage
                                name="middleName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="phone"
                              className="promote-item-label"
                            >
                              Date of Birth
                            </label>
                            <div className="single-input">
                              <Field
                                name="dob"
                                type="date"
                                className={
                                  "form-control file-textbox" +
                                  (errors.dob && touched.dob
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Date of Birth"
                              />
                              <ErrorMessage
                                name="dob"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                        <label htmlFor="role" className="promote-item-label">
                          Select Role
                        </label>
                        <Field
                          as="select"
                          name="role"
                          className={
                            "form-control" +
                            (errors.role && touched.role ? " is-invalid" : "")
                          }
                          onChange={handleChange}
                        >
                          <option value=""></option>
                          <option value="user">User</option>
                          <option value="celebrity">Celebrity</option>
                          <option value="vender">Vender</option>
                        </Field>
                        <ErrorMessage
                          name="role"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div> */}
                          {/* <div className="col-md-6">x</div> */}
                          <div className="col-md-6">
                            <button className="btn update-profile-btn">
                              Update Profile Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {/* <div className="profile-settings-inner2">
          <div className="row">
            <div className="col-md-4">
              <h1 className="ActiveAudiences-hed">Dispaly Settings</h1>
            </div>
            <div className="col-md-4">
              <div className="display-setting-box">
                <h3 className="select-font-size">Select Fonts Size</h3>
                <button
                  className="btn select-size-btn"
                  onClick={() => handleCustomerDisplaySetting("small")}
                >
                  Small
                </button>
                <button
                  className="btn select-size-btn active"
                  onClick={() => handleCustomerDisplaySetting("medium")}
                >
                  Medium
                </button>
                <button
                  className="btn select-size-btn"
                  onClick={() => handleCustomerDisplaySetting("large")}
                >
                  Large
                </button>
              </div>
            </div>
            <div className="col-md-4">
              <div className="display-setting-box">
                <h3 className="select-font-size">Scale Window</h3>
                <button
                  className="btn select-size-btn"
                  onClick={() => handleCustomerDisplaySetting("", "100%")}
                >
                  100%
                </button>
                <button
                  className="btn select-size-btn active"
                  onClick={() => handleCustomerDisplaySetting("", "200%")}
                >
                  200%
                </button>
                <button
                  className="btn select-size-btn"
                  onClick={() => handleCustomerDisplaySetting("", "300%")}
                >
                  300%
                </button>
              </div>
            </div>
          </div>
        </div> */}

        {/* pdw setting  */}
        <div className="profile-settings-inner change-psd-inner3 mt-5">
          <Formik
            initialValues={{
              cpassword: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              // type: Yup.string().required("Type is required"),
              // email: Yup.string()
              //   .email("Email is invalid")
              //   .required("Email is required"),

              password: Yup.string()
                .min(6, "Password must be at least 6 characters")
                .required("Password is required"),
              cpassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Confirm Password is required"),
            })}
            onSubmit={async (fields) => {
              // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 2))
              setIsLoading(true);
              await dispatch(
                changePasswordAction(fields.cpassword, fields.password)
              );
              setIsLoading(false);
            }}
            render={({
              errors,
              status,
              touched,
              values,
              setFieldValue,
              handleBlur,
              handleChange,
              isValid,
              dirty,
              // handleSubmit
            }) => (
              <Form>
                {/* <div className="name-field">
                  <div className="form-group">
                    
                    <Field
                      name="email"
                      type="text"
                      className={
                        "form-control" +
                        (errors.email && touched.email ? " is-invalid" : "")
                      }
                      placeholder="Email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-3">
                    <h1 className="ActiveAudiences-hed">Change Password</h1>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      {/* <div className="col-md-6">
                        <label className="promote-item-label">
                          Old Password
                        </label>
                        <div className="single-input">
                          <span>
                            <i className="fas fa-unlock"></i>
                          </span>
                          <input
                            type="password"
                            className="form-control file-textbox"
                            placeholder="••••••••"
                          />
                          <i className="fa fa-eye"></i>
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <label className="promote-item-label">
                          New Password
                        </label>
                        <div className="single-input">
                          <span>
                            <i className="fas fa-unlock"></i>
                          </span>
                          <Field
                            type="password"
                            name="password"
                            // className="form-control "
                            className={
                              "form-control file-textbox" +
                              (errors.password && touched.password
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="password"
                          />
                          <i className="fa fa-eye"></i>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="promote-item-label">
                          Confirm Password
                        </label>
                        <div className="single-input">
                          <span>
                            <i className="fas fa-unlock"></i>
                          </span>
                          <Field
                            type="password"
                            name="cpassword"
                            // className="form-control "
                            className={
                              "form-control file-textbox" +
                              (errors.cpassword && touched.cpassword
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="confirm password"
                          />
                          <i className="fa fa-eye"></i>
                        </div>

                        <ErrorMessage
                          name="cpassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6">
                        <button className="btn update-profile-btn chnagepswd">
                          Update Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          />
        </div>
      </section>
    </div>
  );
}
