export const getAuthProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_AUTH_PROFILE_INIT":
      return { loading: true };
    case "GET_AUTH_PROFILE_SUCCESS":
      return { loading: false, getAuthProfile: action.payload };
    case "GET_AUTH_PROFILE_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
