import axios from "axios";

//-----------------------GET Celebrity Setting ---------------------//
export const getingSearchHistory = (authId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SEARCH_HISTORY_INIT",
    });

    const user = JSON.parse(localStorage.getItem("userInfo"));

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    const { data } = await axios.get(
      `https://fangway.com/users/search-history`,
      config
    );
    dispatch({
      type: "GET_SEARCH_HISTORY_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_SEARCH_HISTORY_FAIL",
      payload: error,
      // error.response && error.response.data.message
      //   ? error.response.data.message
      //   : error.message,
    });
  }
};
