import React from "react";
import { Link } from "react-router-dom";
import "./SignupLink.css";
export default function SignupLink() {
  return (
    <div>
      <div className="signup-link-section">
        <div className="container">
          <p>
            Are you ready to join us? Click here to{" "}
            <Link to="/signup">sign up now!</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
