import React from "react";
import { Link } from "react-router-dom";
import "../DbCustomer/DbCustomer.css";
import ReturnIcon from "../../../../Assets/return.png";
import CartIcon from "../../../../Assets/cart-icon.png";
// import AwesomeHeart from "../../../../Assets/Icon awesome-heart.svg";
import path5719 from "../../../../Assets/Path 5719.svg";
import group115 from "../../../../Assets/Group 1145.png";
import search from "../../../../Assets/search-results.png";

export default function DbCustomer() {
  return (
    <div>
      <div className="main_container dashboard_container2">
        <span className="menuIcon"></span>
        <div className="dashboard_main">
          <div className="row no-gutters">
            <div className="col-md-4 custom-box-padding">
              <Link to="/customer-search-history">
                <div className="db-content-links">
                  <span className="">
                    <img src={search} alt="offer" className="img-fluid"></img>
                  </span>
                  <p>Search History</p>
                </div>
              </Link>
              <div className="db-box-shade"></div>
            </div>
            <div className="col-md-4 custom-box-padding">
              <Link to="/customer-order-history">
                <div className="db-content-links">
                  <span className="">
                    <img src={CartIcon} alt="usres" className="img-fluid"></img>
                  </span>
                  <p>Order History</p>
                </div>
              </Link>
              <div className="db-box-shade"></div>
            </div>
            <div className="col-md-4 custom-box-padding">
              <Link to="/customer-payment-history">
                <div className="db-content-links">
                  <span className="">
                    <img
                      src={ReturnIcon}
                      alt="money"
                      className="img-fluid"
                    ></img>
                  </span>
                  <p>Payment History</p>
                </div>
              </Link>

              <div className="db-box-shade"></div>
            </div>
            {/* <div className="col-md-6 custom-box-padding">
              <Link to="/customer-favorite-product">
                <div className="db-content-links">
                  <span className="">
                    <img
                      src={AwesomeHeart}
                      alt="offer"
                      className="img-fluid"
                    ></img>
                  </span>
                  <p>My Favorite Product</p>
                </div>
              </Link>
              <div className="db-box-shade active"></div>
            </div> */}

            <div className="col-md-6 custom-box-padding">
              <Link to="/customer-settings">
                <div className="db-content-links">
                  <span className="">
                    <img src={path5719} alt="offer" className="img-fluid"></img>
                  </span>
                  <p>settings</p>
                </div>
              </Link>
              <div className="db-box-shade"></div>
            </div>
          </div>
          <span className="db-bottom-logo">
            <img src={group115} alt="logo" className="img-fluid"></img>
          </span>
        </div>
      </div>
    </div>
  );
}
