import React, { useState } from "react";
import "./ForgetPassword.css";

import Logo from "./assets/FangWay-reset.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { forgotPasswordAction } from "../../Actions/ForgetPasswordAction/ForgetPasswordAction";

export default function ForgetPassowrd() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const forgetPass = useSelector((state) => state.forgetPassword);
  console.log("userLogin::>", forgetPass);

  // const { loading, error, forgotPasswordInfo } = forgetPass;
  return (
    <div className="forget__password-1">
      {isLoading ? <div className="se-pre-con"></div> : ""}

      <div className="forget__password-1-content">
        <img
          className="forget__password-1-image"
          src={Logo}
          alt="logo fangway"
        />
        <div className="forget__password-1-content-form">
          <h2>Reset your password</h2>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Email is invalid")
                .required("Email is required"),
            })}
            onSubmit={async (fields) => {
              // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 2))
              setIsLoading(true);
              await dispatch(forgotPasswordAction(fields.email));
              setIsLoading(false);
            }}
            render={({
              errors,
              status,
              touched,
              values,
              setFieldValue,
              handleBlur,
              handleChange,
              isValid,
              dirty,
              // handleSubmit
            }) => (
              <Form>
                {/* <div className="name-field"> */}
                {/* <div className="form-group"> */}
                <Field
                  name="email"
                  type="text"
                  className={
                    "form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                  placeholder="Enter your Email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback-forget"
                />
                {/* </div> */}
                {/* </div> */}

                {/* <div className="form-group"> */}
                <button className="login__signInButton" type="submit">
                  Send
                </button>
                {/* </div> */}
              </Form>
            )}
          />
          <div>
            <h3 className="mt-1 forget__password-1-content-form-title">
              Did you change your email address?
            </h3>
            <p className="mt-2 forget__password-1-content-form-subtitle">
              If you no longer use the email address associated with your
              Fangway account, you can contact Customer Service for help
              restoring access to your account.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
