import React, { useEffect, useState } from "react";
import "./ResentProduct.css";
import { useNavigate } from "react-router-dom";
import {
  CreateOrder,
  ProductDetailsCount,
} from "../../Actions/Products/ProductsAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export default function ResentProduct({ data }) {
  const [loadingOrder, setLoadingOrder] = useState(false);

  return (
    <div>
      {loadingOrder ? <div className="se-pre-con"></div> : ""}
      <section className="recent-products-sec">
        <div className="container">
          <h1>Recent Products</h1>
          <div className="row recent-products-list">
            {data ? (
              data.map((element, i) => {
                return (
                  <CardProductRe
                    setLoadingOrder={setLoadingOrder}
                    element={element}
                    i={i}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

function CardProductRe({ setLoadingOrder, element, i }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickBuy = (e, slug) => {
    e.stopPropagation();
    const userLocal = localStorage.getItem("userInfo");
    if (!userLocal) {
      localStorage.setItem("check","One");
      setLoadingOrder(true);
      dispatch(
        ProductDetailsCount(slug, (product) => {
          localStorage.setItem(
            "order",
            JSON.stringify([
              {
                product: product,
                amount: 1,
              },
            ])
          );
          setLoadingOrder(false);
          navigate("/signin");
        })
      );
    }
    setLoadingOrder(true);
    dispatch(
      ProductDetailsCount(slug, (product) => {
        localStorage.setItem(
          "order",
          JSON.stringify([
            {
              product: product,
              amount: 1,
            },
          ])
        );
        dispatch(
          CreateOrder("One", (data, sk, err) => {
            console.log({
              data,
              sk,
              err,
            });
            if (err) {
              toast.error(err);
            } else {
              navigate(`/checkout?o=${data.data.idOrder}&s=${sk}`);
            }
            setLoadingOrder(false);
          })
        );
      })
    );
  };

  return (
    <div className="product-grid4 text-left col-lg-3 col-md-6">
      <div>
        <div
          onClick={(e) => {
            navigate(`/product-detail?product=${element.slug}`);
          }}
          style={{ cursor: "pointer" }}
        >
          <div className="product-image4">
            <a>
              <img
                src={element.image}
                alt={`${element.name}`}
                className="img-fluid"
              />
            </a>
          </div>
          <div className="product-content">
            <h3 className="title">
              <a>{element.name}</a>
            </h3>
            <ul className="rating">
              {/* {Array(5)
              .fill(5)
              .map(() => {
                return <li className="fa fa-star"></li>;
              })} */}
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star disable"></li>
            </ul>

            <div className="price">
              <span>$ {element.amount}</span>${" "}
              {element.discount
                ? (
                    element.amount -
                    (element.amount / 100) * element.discount
                  ).toFixed(2)
                : (element.amount - (element.amount / 100) * 30).toFixed(2)}
            </div>

            <div className="discount-off">
              <span>
                {" "}
                <img
                  src="images/fangwaysmall-logo.png"
                  alt="logo"
                  className="img-fluid"
                />
              </span>
              <div>
                <h4>
                  {element.discount ? `${element.discount}% OFF` : `30% OFF`}
                </h4>
                <p>
                  with <strong>Fangway</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        // onClick={(e) => handleClickBuy(e, element.slug)}
        className="product_btn"
      >
        <button
          onClick={(e) => handleClickBuy(e, element.slug)}
          className="btn btn-warning"
        >
          Buy Now
        </button>
      </div>
    </div>
  );
}
