import React from "react";
import OfferSection from "./OfferSection";


import "./Products.css";


export default function Products({ data, desc, title, slug, featured }) {
  
  
  return (
    <div>
      <section className="products-main">
        <div className="container">
            {featured &&
            featured.map((_,i) => {
                return (
                    <OfferSection
                    key={i}
                        slug=''
                        title={_.title}
                        description={_.description}
                        productData={_.data}
                    />
                );
            })}


          <hr />
            <OfferSection
                slug='Recommended Products'
                description={desc}
                title={title}
                productData={data}
            />

          <hr />


        </div>


      </section>
    </div>
  );
  // );
}
