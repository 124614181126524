let initialState = {
  loading: true,
  signUpInfo: "",
  error: "",
};

export const signupReducer = (state = {}, action) => {
  switch (action.type) {
    //--------- Customer/user Sign up --------------//

    case "SIGN_UP_INIT":
      return { loading: true };
    case "SIGN_UP_SUCCESS":
      return { loading: false, signUpInfo: action.payload };
    case "SIGN_UP_FAIL":
      return { loading: false, error: action.payload };

    //--------- Celebrity Sign up --------------//
    case "CELEBRITY_SIGN_UP_INIT":
      return { loading: true };
    case "CELEBRITY_SIGN_UP_SUCCESS":
      return { loading: false, signUpInfo: action.payload };
    case "CELEBRITY_SIGN_UP_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
