import React, { useState } from "react";

import "./Acordeon.css";
import Arrow from "./assets/arrow.png";

export default function Acordeon({index,question,response}) {
  const [isOpenCollapsep, setIsOpenCollapsep] = useState(90);

  const handleClickCollapsep = () => {
      if(isOpenCollapsep === 90){
        setIsOpenCollapsep(0);
      }else{
        setIsOpenCollapsep(90);
      }
    
  };

  return (
    <div className="panel-group">
      <div className="panel panel-default">
        <div
          className="panel-heading accordeon-faq-page-question accordeon-faq-quiestion-content"
          data-toggle="collapse"
          href={`#collapse${index + 1}`}
          onClick={() => handleClickCollapsep()}
        >
          <div className="accordeon-faq-quiestion-content">
            <p className="accordeon-faq-q">Q.</p>
            <p className="accordeon-faq-r">
              {question}
            </p>
          </div>
          <img
            className="rotate-image"
            style={{ 
                width: "24.09px", 
                height: "14.24px",
                transform: `rotate(${isOpenCollapsep}deg)`,
                msTransform: `rotate(${isOpenCollapsep}deg)`,
                WebkitTransform: `rotate(${isOpenCollapsep}deg)`,
                transition: '0.1s ease-out'
            }}
            src={Arrow}
            alt="element"
          />
        </div>
        <div
          id={`collapse${index + 1}`}
          className="panel-collapse collapse border-collapsep-faq"
        >
          <div className="panel-body accordeon-faq-quiestion-content">
            <p className="accordeon-faq-a">A.</p>
            <p className="accordeon-faq-a-r">
              {response}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
