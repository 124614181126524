import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getingCelebrityEarning } from "../../../../Actions/CelebrityActions/celebrityActions";
import "../DbContent/DbContent.css";
export default function DbContent() {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [total, setTotal] = useState(0);

  // console.log("userInfo::>>", userInfo);

  // const { profile: { authId = "" } = {} } = userInfo;


  const totalEarning = useSelector(
    (state) => state.celebrityReducer
  );

  // console.log("totalEarning", totalEarning);

  useEffect(async () => {
    let authId = userInfo && userInfo.profile && userInfo.profile.authId;
    dispatch(getingCelebrityEarning(authId));
    setIsLoading(true);
  }, []);


  useEffect(() => {
    if(totalEarning && totalEarning.celebrityEarning){
      setTotal(totalEarning.celebrityEarning);
      setIsLoading(false);
    }
  }, [totalEarning.celebrityEarning])
  

  return (
    <div>
      {isLoading ? <div className="se-pre-con"></div> : ""}

      <div className="main_container dashboard_container2">
        <span className="menuIcon"></span>
        <div className="dashboard_main">
          <div className="row no-gutters">
            <div className="col-md-12 custom-box-padding">
              {/* <Link to="/celebrity-earning"> */}
              <div className="db-content-links">
                <span className="">
                  <img
                    src="images/Icon metro-dollar.svg"
                    alt="money"
                    className="img-fluid"
                  ></img>
                </span>
                <p>My Earnings</p>
                <p>{total && total.total_earnings}$</p>
              </div>
              {/* </Link> */}
              <div className="db-box-shade"></div>
            </div>

            <div className="col-md-12 custom-box-padding">
              <Link to="/celebrity-settings">
                <div className="db-content-links">
                  <span className="">
                    <img
                      src="images/Path 5719.svg"
                      alt="offer"
                      className="img-fluid"
                    ></img>
                  </span>
                  <p>settings</p>
                  <p> </p>
                </div>
              </Link>
              <div className="db-box-shade"></div>
            </div>
          </div>
          <span className="db-bottom-logo">
            <img
              src="images/Group 1145.png"
              alt="logo"
              className="img-fluid"
            ></img>
          </span>
        </div>
      </div>
    </div>
  );
}
