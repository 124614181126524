import { Avatar, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userLogoutAction } from "../../Actions/LoginActions/LoginAction";
import "./Header.css";
import { getCart } from "../../Actions/Products/ProductsAction";
import { getCategories } from "../../Actions/DashboardVendor/DashboarVendor";

export default function Header() {
  const reduxCart = useSelector((state) => state.productDetail);
  const reduxDashVen = useSelector((state) => state.dashboardVendor);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [inputSerch, setInputSerch] = useState("");
  const [selectCategori, setSelectCategori] = useState("");
  const searchPage = () => {
    if (inputSerch === "") return;
    navigate(`/product-search?category=${selectCategori}&search=${inputSerch}`);
  };

  useEffect(() => {
    dispatch(getCart());
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (reduxCart.productsCart) {
      setCartData(reduxCart.productsCart);
    }
  }, [reduxCart.productsCart]);

  useEffect(() => {
    if (reduxDashVen.categoryInfo) {
      console.log(reduxDashVen.categoryInfo.data);
      setCategories(reduxDashVen.categoryInfo.data);
    }
  }, [reduxDashVen.categoryInfo]);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { profile: { imagePath = "", celebrity = {} } = {} } = userInfo || {};

  // console.log("celebrity::>", celebrity);
  // console.log("user", userInfo);
  const handleLogout = async () => {
    setIsLoading(true);
    dispatch(userLogoutAction());
    navigate("/signin");
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading ? <div className="se-pre-con"></div> : ""}

      <div className="header-wrapper">
        <div className="container mm-custom-header">
          <div className="header-flex-box">
            <div className="logo-flex-box">
              <div className="message-box">
                <Link to={"/"}>
                  {" "}
                  <img
                    src="../images/headre-logo.png"
                    alt="logo"
                    className="img-fluid"
                  />
                </Link>
              </div>
              <div className="location-box">
                <Link
                  style={{
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                  to={"/"}
                >
                  <div className="icon-box pl-3">
                    {" "}
                    <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar alt="user" src="https://firebasestorage.googleapis.com/v0/b/fangway-demo.appspot.com/o/celebrity%2F273669016_501537558046412_7730494752331014335_n.jpeg?alt=media&token=31ac0383-fdf2-4b8a-939a-8213853f4173"/>
                      {/* {celebrity.imagePath ? (
                        <Avatar alt="user" src={celebrity.imagePath}/>
                      ) : (
                        ""
                      )} */}

                      {/* <Typography>{celebrity.firstName}</Typography> */}
                      <Typography>Nick and Carrie</Typography>
                    </Stack>
                  </div>
                </Link>
              </div>
              <span className="header-menuIcon"></span>
            </div>
            <div className="new-mobile-serach">
              <div className="NavSearch ">
                <div className="">
                  <select
                    value={selectCategori}
                    onChange={(e) => setSelectCategori(e.target.value)}
                    className="form-control"
                  >
                    <option key={-1} value={""}>
                      All Categories
                    </option>
                    {categories &&
                      categories.map((cate, i) => {
                        return (
                          <option key={i} value={cate.title}>
                            {cate.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="input-group">
                  <input
                    value={inputSerch}
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => setInputSerch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      onClick={() => searchPage()}
                      className="btn btn-search"
                      type="button"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-right-sect">
              <span className="header-closeNav"></span>
              <div className="message-box hide-on-desktop">
                {/* <p>Settings</p> */}
                <a href="#">
                  {" "}
                  <img
                    src="../images/headre-logo.png"
                    alt="logo"
                    className="img-fluid"
                  />
                </a>
              </div>
              {/* {!userInfo ? (
                <span className="login-sidebar">
                  <Link to="/login">
                    Login <i className="fa fa-user"></i>
                  </Link>
                </span>
              ) : (
                ""
              )} */}
              <div className="NavSearch hide-serach-resp ml-5">
                <div className="">
                  <select
                    value={selectCategori}
                    onChange={(e) => setSelectCategori(e.target.value)}
                    className="form-control"
                  >
                    <option key={-1} value={""}>
                      All Categories
                    </option>
                    {categories &&
                      categories.map((cate, i) => {
                        return (
                          <option key={i} value={cate.title}>
                            {cate.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="input-group">
                  <input
                    value={inputSerch}
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => setInputSerch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      onClick={() => searchPage()}
                      className="btn btn-search"
                      type="button"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="header-nav-div-main">
                <ul className="header-nav">
                  {/* <li>
                    <span id="translator-wrapper">
                      <select id="translate-language">
                        <option value="en" selected="selected">
                          English
                        </option>
                        <option value="id">Indonesian</option>
                        <option value="af">Afrikaans</option>
                        <option value="sq">Albanian</option>
                        <option value="ar">Arabic</option>
                        <option value="hy">Armenian</option>
                        <option value="az">Azerbaijani</option>
                        <option value="eu">Basque</option>
                        <option value="be">Belarusian</option>
                        <option value="bn">Bengali</option>
                        <option value="bg">Bulgarian</option>
                        <option value="ca">Catalan</option>
                        <option value="zh-CN">Chinese</option>
                        <option value="hr">Croatian</option>
                        <option value="cs">Czech</option>
                        <option value="da">Danish</option>
                        <option value="nl">Dutch</option>
                        <option value="en">English</option>
                        <option value="eo">Esperanto</option>
                        <option value="et">Estonian</option>
                        <option value="tl">Filipino</option>
                        <option value="fi">Finnish</option>
                        <option value="fr">French</option>
                        <option value="gl">Galician</option>
                        <option value="ka">Georgian</option>
                        <option value="de">German</option>
                        <option value="el">Greek</option>
                        <option value="gu">Gujarati</option>
                        <option value="ht">Haitian Creole</option>
                        <option value="iw">Hebrew</option>
                        <option value="hi">Hindi</option>
                        <option value="hu">Hungarian</option>
                        <option value="is">Icelandic</option>
                        <option value="id">Indonesian</option>
                        <option value="ga">Irish</option>
                        <option value="it">Italian</option>
                        <option value="ja">Japanese</option>
                        <option value="kn">Kannada</option>
                        <option value="ko">Korean</option>
                        <option value="la">Latin</option>
                        <option value="lv">Latvian</option>
                        <option value="lt">Lithuanian</option>
                        <option value="mk">Macedonian</option>
                        <option value="ms">Malay</option>
                        <option value="mt">Maltese</option>
                        <option value="no">Norwegian</option>
                        <option value="fa">Persian</option>
                        <option value="pl">Polish</option>
                        <option value="pt">Portuguese</option>
                        <option value="ro">Romanian</option>
                        <option value="ru">Russian</option>
                        <option value="sr">Serbian</option>
                        <option value="sk">Slovak</option>
                        <option value="sl">Slovenian</option>
                        <option value="es">Spanish</option>
                        <option value="sw">Swahili</option>
                        <option value="sv">Swedish</option>
                        <option value="ta">Tamil</option>
                        <option value="te">Telugu</option>
                        <option value="th">Thai</option>
                        <option value="tr">Turkish</option>
                        <option value="uk">Ukrainian</option>
                        <option value="ur">Urdu</option>
                        <option value="vi">Vietnamese</option>
                        <option value="cy">Welsh</option>
                        <option value="yi">Yiddish</option>
                      </select>
                    </span>
                  </li> */}

                  <div className="dropdown responsive-class-header-icons-nav">
                    <a
                      className="btn dropdown-toggle pt-0"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      // style={{paddingTop: "-10px!important"}}
                    >
                      <Avatar alt="user" src={imagePath}/>
                    </a>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      {userInfo &&
                      userInfo.profile &&
                      userInfo.profile.role === "user" ? (
                        <>
                          {/* <p className="dropdown-item pr-2 responsive-class-header">Settings</p> */}
                          <Link
                            className="dropdown-item"
                            to="/customer-dashboard"
                          >
                            Dashboard
                          </Link>
                          {/* <a  href="customer-dashboard">
                            
                          </a> */}
                          <div className="dropdown-item" onClick={handleLogout}>
                            Logout
                          </div>
                        </>
                      ) : userInfo &&
                        userInfo.profile &&
                        userInfo.profile.role === "celebrity" ? (
                        <>
                          <Link
                            className="dropdown-item"
                            to="/celebrity-dashboard"
                          >
                            Dashboard
                          </Link>
                          <div className="dropdown-item" onClick={handleLogout}>
                            Logout
                          </div>
                        </>
                      ) : (
                        <a className="dropdown-item" href="/signin">
                          Signin
                        </a>
                      )}
                    </div>
                  </div>

                  <Link
                    className="responsive-class-header-icons-nav"
                    to={"/cart"}
                  >
                    <li className="cart-count-li">
                      <img
                        src="images/cart-icon-new.png"
                        alt="cart"
                        className="img-fluid for-desktop"
                      />
                      <img
                        src="images/cart-icon-new-white.png"
                        alt="cart"
                        className="img-fluid for-mobile"
                      />
                      <span className="count-text">
                        {cartData !== null &&
                        cartData !== undefined &&
                        cartData.length > 0
                          ? cartData.length
                          : 0}
                      </span>
                    </li>
                  </Link>
                </ul>
              </div>
              <div className="new-header-side-list">
                <div className="border-bottom-list">
                  <h4>Fangway Home</h4>
                </div>
                <div className="border-bottom-list">
                  <h4>Trending</h4>
                  <ul className="side-fangway-list">
                    <li>
                      <Link to="">Best Sellers</Link>
                    </li>
                  </ul>
                </div>
                <div className="border-bottom-list">
                  <h4>Top Departments</h4>
                  <ul className="side-fangway-list">
                    <li>
                      <Link to="">Clothes and Accessories</Link>{" "}
                    </li>
                    <li>
                      <Link to="">Books</Link>
                    </li>
                    <li>
                      <Link to="">Electronics</Link>
                    </li>
                    <li>
                      <Link to="">Beauty and Personal care</Link>
                    </li>
                    <li>
                      <Link to="">Health & Household</Link>
                    </li>
                    <li>
                      <Link to="">
                        See All Departments{" "}
                        <i className="fa fa-arrow-right float-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="headre-nav-links">
          <div className=" new-nav-header mm-custom-header">
            <ul>
              <li>
                <a href="#">best sellers</a>
              </li>
              <li>
                <a href="#">fashion</a>
              </li>
              <li>
                <a href="#">audio books</a>
              </li>
              <li>
                <a href="#">books</a>
              </li>
              <li>
                <a href="#">jewelry & watches</a>
              </li>
              <li>
                <a href="#">celebrities’ deals</a>
              </li>
              <li>
                <a href="#">electronics</a>
              </li>
              <li>
                <a href="#">pet supplies</a>
              </li>
              <li>
                <a href="#">health & household</a>
              </li>
              <li>
                <a href="#">beauty & personal care</a>
              </li>
              <li>
                <a href="#">return & orders</a>
              </li>
              <li>
                <a href="#">
                  <img src="images/icons8-location-50.png" alt="location"></img>
                  delivery address
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
