import { combineReducers } from "redux";
import { celebrityReducer } from "./CelebrityReducer/celebrityReducer";
import { customerRedcuer } from "./CustomerReducers/CustomerReducers";
import { forgotPasswordReducer } from "./ForgetPassword/ForgetPasswordReducer";
import { LoginReducer } from "./LoginReducer/LoginReducer";
import { signupReducer } from "./SignupReducer/SignupReducers";
import { dashboardVendor } from './DashboardVendor/DashboardVendor';
import { homePageReducer } from "./Users/HomePageReducer";
import { ProductReducer } from "./Products/ProductReducer";
import { ProductSearchReducer } from "./Products/ProductSearchReducer";
import { uploadFileReducer } from "./UploadFileReducer/UploadFileReducer";
import { getAuthProfileReducer } from "./GetAuthProfileReducer/GetAuthProfileReducer";
import { WishListReducer } from "./WishListReducer/WishListReducer";
import { OrderListReducer } from "./OrderListReducer/OrderListReducer";
import { searchHistoryReducer } from "./SearchHistoryReducer/SearchHistoryReducer";

const reducers = combineReducers({
  userSignUp: signupReducer,
  userLoginInfo: LoginReducer,
  forgetPassword: forgotPasswordReducer,
  dashboardVendor: dashboardVendor,
  homePage: homePageReducer,
  productDetail: ProductReducer,
  celebrityReducer: celebrityReducer,
  customerRedcuer: customerRedcuer,
  uploadFileReducer: uploadFileReducer,
  gettingAuthProfile: getAuthProfileReducer,
  getWishListReducer: WishListReducer,
  getOrderListReducer: OrderListReducer,
  searchHistoryReducer: searchHistoryReducer,
  ProductSearch: ProductSearchReducer,
});

export default reducers;
