import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getingSearchHistory } from "../../../../Actions/SearchHistoryAction/SearchHistoryAction";
import ".//PaymentHistory.css";


export default function PaymentHistory() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const searchData = useSelector((state) => state.searchHistoryReducer);
  const { userInfo = [] } = searchData;
  console.log({ userInfo });

  useEffect(async () => {
    setIsLoading(true);
    await dispatch(getingSearchHistory());
    setIsLoading(false);
  }, []);
  return (
    <div>
      {isLoading ? <div className="se-pre-con"></div> : ""}

      <section className="dashboard_container2">
        <span className="menuIcon"></span>
        <div className="customer-history-main">
          <div className="customer-history-inner">
            <h2 className="cutomer-section-hed">Search History</h2>
            <div className="customer-history-table-main table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr/No</th>
                    <th>Search Query</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userInfo.map((item, index) => (
                    <tr>
                      <td>{index}</td>
                      <td>{item}</td>
                      <td>
                        <button className="edit-history">
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
