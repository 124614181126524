let initialState = {
   deals:[],
   featured:[],
   recommended:{}
  };
  
  export const homePageReducer = (state = initialState, action) => {
    switch (action.type) {
      //--------- Customer/user Sign up --------------//
  
      case "DATA_FETCHING":
        return { loading: true };
      case "DATA_SUCCESS":
        return { loading: false, state: action.payload };
      case "DATA_FAIL":
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  