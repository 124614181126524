import React from 'react';
import './ContentCalebrities.css';

import { Formik, Form, Field, ErrorMessage } from "formik";

export default function ContentCalebrities() {
  return (
    <div className="container">
    <p className="content__celebrities-title">Celebritie Account Verification</p>
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        password: '',
        confirm_password: "",
        celebrity_nick_name: "",
        phone_number: ''
      }}
      validate={(valores) => {
        let errors = {};
        if (!valores.first_name) {
          errors.first_name = "*Please enter a first name";
        }

        if (!valores.last_name) {
          errors.last_name = "*Please enter a last name";
        }

        if (!valores.email) {
          errors.email = "*Please enter a email";
        } else if (
          !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
            valores.email
          )
        ) {
          errors.email =
            "*The email can only contain letters, numbers, periods, hyphens and underscores";
        }

        if(!valores.password){
          errors.password = "*Please enter a password";
        }

        if(!valores.confirm_password){
          errors.confirm_password = "*Please enter a confirm password";
        }

        if(valores.password !== valores.confirm_password){
          errors.confirm_password = "*Passwords do not match";
        }

        if(!valores.celebrity_nick_name){
          errors.celebrity_nick_name = "*Please enter a celebrity nickname";
        }

        if(!valores.phone_number){
          errors.phone_number = "*Please enter a phone number";
        }

        return errors;
      }}
      onSubmit={(valores) => {
        console.log(valores);
        console.log("llegaron los datos");
      }}
    >
      {({
        errors,
      }) => (
        <Form>
          <div className="content__celebrities-form-content">

            <div className="d-block">
              <p className="content__celebrities-content-form-p-style">
                Your first name
              </p>
              <Field
                name="first_name"
                className="content__celebrities-content-form-input-style"
                placeholder="First name"
              />
              <ErrorMessage
                name="first_name"
                component={() => (
                  <div className="content__celebrities-content-form-input-error-style">
                    {errors.first_name}
                  </div>
                )}
              />
            </div>

            <div>
              <p className="content__celebrities-content-form-p-style">
              Your last name
              </p>
              <Field
                name="last_name"
                className="content__celebrities-content-form-input-style"
                placeholder="Last name"
              />
              <ErrorMessage
                name="last_name"
                component={() => (
                  <div className="content__celebrities-content-form-input-error-style">
                    {errors.last_name}
                  </div>
                )}
              />
            </div>

            <div>
              <p className="content__celebrities-content-form-p-style">
                Contact email
              </p>
              <Field
                name="email"
                className="content__celebrities-content-form-input-style"
                placeholder="Your email"
              />
              <ErrorMessage
                name="email"
                component={() => (
                  <div className="content__celebrities-content-form-input-error-style">
                    {errors.email}
                  </div>
                )}
              />
            </div>

            <div>
              <p className="content__celebrities-content-form-p-style">
                Your password
              </p>
              <Field
                type="password"
                name="password"
                className="content__celebrities-content-form-input-style"
                placeholder="Password"
              />
              <ErrorMessage
                name="password"
                component={() => (
                  <div className="content__celebrities-content-form-input-error-style">
                    {errors.password}
                  </div>
                )}
              />
            </div>

            <div>
              <p className="content__celebrities-content-form-p-style">
              Confirm your password
              </p>
              <Field
                type="password"
                name="confirm_password"
                className="content__celebrities-content-form-input-style"
                placeholder="Confirm password"
              />
              <ErrorMessage
                name="confirm_password"
                component={() => (
                  <div className="content__celebrities-content-form-input-error-style">
                    {errors.confirm_password}
                  </div>
                )}
              />
            </div>
            
            <div>
              <p className="content__celebrities-content-form-p-style">
                Celebrity nickname
              </p>
              <Field
                name="celebrity_nick_name"
                className="content__celebrities-content-form-input-style"
                placeholder="Nickname"
              />
              <ErrorMessage
                name="celebrity_nick_name"
                component={() => (
                  <div className="content__celebrities-content-form-input-error-style">
                    {errors.celebrity_nick_name}
                  </div>
                )}
              />
            </div>

            <div>
              <p className="content__celebrities-content-form-p-style">
              Your phone number / agent phone number
              </p>
              <Field
              type="number"
                name="phone_number"
                className="content__celebrities-content-form-input-style"
                placeholder="Phone number"
              />
              <ErrorMessage
                name="phone_number"
                component={() => (
                  <div className="content__celebrities-content-form-input-error-style">
                    {errors.phone_number}
                  </div>
                )}
              />
            </div>

          </div>
          <div className="content__celebrities-form-content-button">
            <button className="content__celebrities-form-button">Submit</button>
          </div>
        </Form>
      )}
    </Formik>
  </div>
  )
}
