import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputUnstyled from "@mui/base/InputUnstyled";
import { padding, styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
// import moment from "moment";

import { createPromotionSuggerens } from "../Actions/DashboardVendor/DashboarVendor";

import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { gettingOrderlist } from "../Actions/OrderListAction/OrderListAction";

const style = {
  width: 400,
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  height: "90%",
  overflow: "auto",
};

const CreatePromotionModal = ({
  open,
  handleActionModal,
  data,
  createPromotion,
  productId,
}) => {
  console.log("Data modal >>>> ", data);
  console.log("createPromotion modal >>>> ", createPromotion);


  const [dataPromotion, setDataPromotion] = useState({});

  const dispatch = useDispatch();
  const reduxSelectedVendorDash = useSelector((state) => state.dashboardVendor);

  const handleClickPromotion = () => {
    if (
      !dataPromotion.discount ||
      !dataPromotion.startDate ||
      !dataPromotion.finishDate
    ) {
      return toast.error("The data is incomplete.");
    }

    dispatch(
      createPromotionSuggerens(
        {
          discountRate: parseFloat(dataPromotion.discount),
          starDate: dataPromotion.startDate,
          finishDate: dataPromotion.finishDate,
        },
        productId,
        createPromotion.authId,
        (data, error) => {
          if (error) {
            console.log(error.response);
            toast.error(error.message);
          } else {
            console.log(data);
            toast.success("Promotion sent.");
            handleActionModal(false);
          }
        }
      )
    );
  };

  useEffect(() => {
    // const today = moment().format("YYYY-MM-DD");
    // console.log(typeof today)
    setDataPromotion({
      discount: data && data.discount ? data.discount : 30,
      startDate: "",
      finishDate: "",
    });
  }, [data, createPromotion]);

  return (
    <>
      {reduxSelectedVendorDash.loading ? (
        <div className="se-pre-con"></div>
      ) : (
        ""
      )}
      <div>
        <Modal
          open={open}
          onClose={() => handleActionModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <h2 className="text-center">Product info</h2> */}
            {data ? (
              <div>
                <Divider>
                  <Typography variant="h5" gutterBottom component="div">
                    Information Product
                  </Typography>
                </Divider>
                <div className="text-center">
                  <div
                    style={{
                      width: "100%",
                      //   padding: "5px"
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                      }}
                      src={data && data.infoProduct && data.infoProduct.image}
                    />
                  </div>
                  <p>
                    Name: {data && data.infoProduct && data.infoProduct.name}
                  </p>
                </div>
              </div>
            ) : null}

            <Divider>
              <Typography variant="h5" gutterBottom component="div">
                Information Celebrity
              </Typography>
            </Divider>
            <div className="text-center">
              <p>
                Name:{" "}
                {`${createPromotion && createPromotion.firstName} ${
                  createPromotion && createPromotion.lastName
                }`}{" "}
              </p>
              <p>Supports: {createPromotion && createPromotion.supports}</p>
              <p>Gender: {createPromotion && createPromotion.gender}</p>
            </div>

            <div>
              <label>Discount</label> <br />
              <TextField
                className="w-100"
                value={dataPromotion.discount}
                onChange={3}
                id="outlined-basic"
                label="Discount"
                variant="outlined"
              />
              <br />
              <label>Start date</label>
              <br />
              <TextField
                style={{ width: "100%" }}
                value={dataPromotion.startDate}
                onChange={(e) =>
                  setDataPromotion({
                    ...dataPromotion,
                    startDate: e.target.value,
                  })
                }
                type="date"
                id="outlined-basic"
                variant="outlined"
              />
              <label>Finish date</label>
              <br />
              <TextField
                onChange={(e) =>
                  setDataPromotion({
                    ...dataPromotion,
                    finishDate: e.target.value,
                  })
                }
                style={{ width: "100%" }}
                type="date"
                id="outlined-basic"
                variant="outlined"
              />
            </div>

            <button
              onClick={() => handleClickPromotion()}
              style={{
                width: "100%",
                marginTop: "10px",
                height: "60px",
                borderRadius: "5px",
                fontSize: "20px",
                fontWeight: "bold",
                backgroundColor: "rgb(83,77,127)",
                color: "white",
              }}
            >
              Send Promotion
            </button>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default CreatePromotionModal;
