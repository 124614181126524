import React, { useState, useEffect } from "react";
import SidebarTraffics from "./SidebarTraffics";
import Traffic from "./Traffic";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getCelebrityFromProduct, addIdPromotionReduxIndex } from "../../Actions/DashboardVendor/DashboarVendor";

import { MdReply } from "react-icons/md";
import MoreCelebritie from "./components/MoreCelebritie";

import { verifyTokenUser } from '../../utils/Auth';
import CreatePromotionModal from "../../Modals/CreatePromotionModal";


export default function ContentTraffics() {
  let [searchParams, _] = useSearchParams();
  let productId = searchParams.get("product");
//   let index = searchParams.get("index");


  const [dataDealsProduct, setDataDealsProduct] = useState({});
//   const [index, setIndex] = useState(0);

  const navigate = useNavigate();

  const [loadingSesion, setLoadingSesion] = useState(false);
  const [open, setOpen] = useState(false);
  const handleActionModal = (action) => setOpen(action);
  const [createPromotion, setCreatePromotion] = useState({});

  const dispatch = useDispatch();
  const reduxSelectedVendorDash = useSelector((state) => state.dashboardVendor);

  useEffect(() => {
    if (productId) {
      dispatch(getCelebrityFromProduct(productId));
    }
  }, [productId]);

  useEffect(() => {
    if (
      reduxSelectedVendorDash &&
      reduxSelectedVendorDash.allCelebrityesForProduct
    ) {
    //   console.log(reduxSelectedVendorDash.allCelebrityesForProduct);
      setDataDealsProduct(reduxSelectedVendorDash.allCelebrityesForProduct);
    }
  }, [reduxSelectedVendorDash.allCelebrityesForProduct]);

//   useEffect(() => {
//     if (
//       reduxSelectedVendorDash &&
//       reduxSelectedVendorDash.index
//     ) {
//         setIndex(reduxSelectedVendorDash.index);
//     }
//   }, [reduxSelectedVendorDash.index]);

  useEffect(() => {
    const very =  async () => {
      const data = await verifyTokenUser();
      if(!data.token) return navigate('/');
      if(!data.sesion) return navigate('/signin');
      setLoadingSesion(false);
    };
    setLoadingSesion(true)
    very();
  }, [])

//   if (reduxSelectedVendorDash.loading || loadingSesion) {
//     return <div className="se-pre-con"></div>;
//   }

  return (
    <div>
        {reduxSelectedVendorDash.loading || loadingSesion ? <div className="se-pre-con"></div> : ""}
      <div className="dashboard__navegacion">
        <div className="dashboard__navegacion__user">
          <SidebarTraffics dataDealsProduct={dataDealsProduct} />
        </div>
        <div className="dashboard__navegacion-conten-nav">
          <section className="myearning-tabs-main">
            <div>
              {/* <!-- Nav tabs --> */}
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <Link to="/vendor-dashboard-info" className="nav-link">
                    <MdReply className="mr-2 mb-1" />
                    Back
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#all-products"
                  >
                    <span>
                      <img
                        className="myearning-active"
                        src="images/active-trafic.png"
                        alt="dollor"
                      ></img>
                      {/* <img
                          className="myearning-inactive"
                          src="images/audience.png"
                          alt="dollor"
                        ></img> */}
                    </span>{" "}
                    Traffics
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <div>
        {dataDealsProduct && dataDealsProduct.type === "CELEBRITYS" ? (
          <div className="container">
            <div className="content__traffic-more-celebrities">
              <p className="mt-3">Celebrities for promotion</p>
              <div className="content__traffic-more-celebrities-content">
                {dataDealsProduct &&
                  dataDealsProduct.data.map((celebrity, i) => {
                    return <MoreCelebritie key={i} celebrity={celebrity} setCreatePromotion={setCreatePromotion} handleActionModal={handleActionModal}  />;
                  })}
              </div>
            </div>
          </div>
        ) : (
          <Traffic /* index={parseInt(index)} */ dataDealsProduct={dataDealsProduct} productId={productId}  />
        )}
      </div>
      <CreatePromotionModal open={open} handleActionModal={handleActionModal} data={null} createPromotion={createPromotion} productId={productId} />
    </div>
  );
}
