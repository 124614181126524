import axios from "axios";
import { toast } from "react-toastify";

//-----------------------Whislist products  ---------------------//
export const gettingOrderlist = (page = 1) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "GET_ORDER_LIST_INIT",
    });

    const user = JSON.parse(localStorage.getItem("userInfo"));

    if(!user) return

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    console.log("config::>", config);

    const { data } = await axios.get(
      // `https://fangway.com/products/order/order/whishlist?page=${page}&limit=${limit}`,
      // `https://fangway.com/ecommerce/order/user?page=${page}&limit=15`,
      `https://fangway.com/ecommerce/order/user?page=${page}&limit=15`,
      config
    );

    console.log("Order request >>>> ",data)
    // toast.success(data.message);
    dispatch({
      type: "GET_ORDER_LIST_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "GET_ORDER_LIST_FAIL",
      payload: error,
      // error.response && error.response.data.message
      //   ? error.response.data.message
      //   : error.message,
    });
  }
};

//-----------------------Product history list  ---------------------//
export const productHistory = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: "GET_PRODUCT_HISTORY_INIT",
    });

    const user = JSON.parse(localStorage.getItem("userInfo"));

    if(!user) return

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    console.log("config::>", config);

    const { data } = await axios.get(
      // `https://fangway.com/products/order/order/whishlist?page=${page}&limit=${limit}`,
      `https://fangway.com/ecommerce/payment/history`,
      config
    );
    // toast.success(data.message);
    if (data.statusCode === 200 || data.statusCode === 201) {
      // callBack();
    }
    dispatch({
      type: "GET_PRODUCT_HISTORY_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "GET_PRODUCT_HISTORY_FAIL",
      payload: error,
      // error.response && error.response.data.message
      //   ? error.response.data.message
      //   : error.message,
    });
  }
};


export const getOrderDetail = (idGeneral,idOrder) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_DETAIL_ORDER_INIT"
    });

    const user = JSON.parse(localStorage.getItem("userInfo"));

    if(!user) return

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    //peticion
    const { data } = await axios.get(`https://fangway.com/ecommerce/order/${idGeneral}/detail/${idOrder}`,config);

    dispatch({
      type: "GET_DETAIL_ORDER_SUCCESS",
      payload: data
    })

  } catch (error) {
    dispatch({
      type: "GET_DETAIL_ORDER_FAIL",
      payload: error
    });
  }
}