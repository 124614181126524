import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userLogoutAction } from "../../../../Actions/LoginActions/LoginAction";
import "../Sidebar/Sidebar.css";
export default function CustomerSidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let uploadImage = userInfo && userInfo.profile && userInfo.profile.imagePath;
  let userName = userInfo && userInfo.profile && userInfo.profile.firstName;

  let getAuth = JSON.parse(localStorage.getItem("getAuth"));

  let profile = (getAuth && getAuth.imagePath) || "";
  let name = (getAuth && getAuth.firstName) || "";

  // console.log("profileeeeeeee::>", profile, name);

  const [profileImage, setProfileImage] = useState(profile);
  const [firstName, setFirstName] = useState(name);

  useEffect(() => {
    if (getAuth) {
      setProfileImage(profile);
      setFirstName(name);
    } else {
      setProfileImage(uploadImage);
      setFirstName(userName);
    }
  }, [userInfo]);

  console.log("uploadFileReducer::>", profile);

  const handleLogout = async () => {
    setIsLoading(true);
    await dispatch(userLogoutAction());
    navigate("/signin");
    setIsLoading(true);
  };
  return (
    <div>
      {isLoading ? <div className="se-pre-con"></div> : ""}

      <div className="sidebar-main">
        <span className="closeNav"></span>
        <div className="sidebar_main-inner">
          <div className="db-profile">
            <span>
              {/* <img src="images/Elipse 1.png" alt="profiledb"></img> */}
              {profileImage ? (
                <img src={profileImage} alt="Profile image" />
              ) : (
                <Avatar alt="Remy Sharp" sx={{ width: 100, height: 100 }} />
              )}
            </span>
            <div className="db-proname-btn">
              <h6>{firstName}</h6>
              {/* <p>Member science Jan 2022</p> */}
              <button className="btn logout" onClick={handleLogout}>
                Logout{" "}
                <i>
                  <img src="images/eye.svg" alt="eyelogouticon"></img>
                </i>
              </button>
            </div>
          </div>
          <button className="btn side-db-home">
            <Link to="/customer-dashboard">Dashboard</Link>
          </button>
          <button className="btn side-db-home">
            <Link to="/">Home</Link>
          </button>
        </div>
      </div>
    </div>
  );
}
