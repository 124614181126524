import axios from "axios";

export const homePage = () => async (dispatch) => {
  console.log("called");
  dispatch({
    type: "DATA_FETCHING",
  });

  try {
    const user = JSON.parse(localStorage.getItem("userInfo"));

    let config = {};

    if(user){
      config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
    }

    
    const { data } = await axios.get(
      "https://fangway.com/users/homepage",
      config
    );
    console.log(data);
    dispatch({
      type: "DATA_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "DATA_FAIL",
      payload: error,
    });
  }
};
