let initialState = {
  state: {},
  productsRecent: [],
  productsCart: [],
  searchProducts: [],
};

export const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    //--------- Customer/user Sign up --------------//

    case "PRODUCT_DATA_FETCHING":
      return { loading: true };
    case "PRODUCT_DATA_SUCCESS":
      return { loading: false, state: action.payload };
    case "PRODUCT_DATA_FAIL":
      return { loading: false, error: action.payload };

    case "PRODUCT_RECENT":
      return { loading: true };
    case "PRODUCT_RECENT_SUCCESS":
      return { loading: false, productsRecent: action.payload };
    case "PRODUCT_RECENT_FAIL":
      return { loading: false, error: action.payload };

    case "CART_GET":
      return { loading: false };
    case "CART_SUCCESS":
      return { loading: false, productsCart: action.payload, ...state };
    case "CART_FAIL":
      return { loading: false, error: action.payload };

    case "SEARCH_PRODUCT_GET":
      return { loading: true };
    case "SEARCH_PRODUCT_SUCCESS":
      return { loading: false, searchProducts: action.payload, ...state };
    case "SEARCH_PRODUCT_FAIL":
      return { loading: false, error: action.payload };

    case "CREATE_ORDER_GET":
      return { loading: true };
    case "CREATE_ORDER_SUCCESS":
      return { loading: false, secretKeyClient: action.payload, ...state };
    case "CREATE_ORDER_FAIL":
      return { loading: false, error: action.payload };

    case "VALIDATE_ADDRESS_CUSTOMER_GET":
      return { loading: true };
    case "VALIDATE_ADDRESS_CUSTOMER_SUCCESS":
      return { loading: false, addressValidate: action.payload, ...state };
    case "VALIDATE_ADDRESS_CUSTOMER_FAIL":
      return { loading: false, error: action.payload };

    case "AGGREGATE_DISCOUNT_ORDER_SUCCESS":
      return { loading: false, discountCart: action.payload, ...state };
    case "AGGREGATE_DISCOUNT_ORDER_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
