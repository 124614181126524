import React, { useEffect, useState } from "react";
import "./SignIn.css";

import Logo from "../../Assets/FangWaylogo.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import LogoApple from "./assets/apple-black-logo.png";
import LogoGoogle from "./assets/google.png";
import LogoFace from "./assets/facebook (1).png";
import LogoInsta from "./assets/instagram (1).png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { MenuItem, Select } from "@mui/material";
import { userLogin } from "../../Actions/LoginActions/LoginAction";
import { toast } from "react-toastify";
import { Auth } from "../../utils/Auth";
import { CreateOrder } from "../../Actions/Products/ProductsAction";

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [token /* , setToken */] = useState(null);

  const userLoginInfo = useSelector((state) => state.userLoginInfo);
  // const userInfoData = JSON.parse(localStorage.getItem("userInfo"));
  // console.log("userLoginInfo::::>", userLoginInfo);

  const { userInfo: { profile: { role = "", celebrityId = "" } = {} } = {} } = userLoginInfo;

  // console.log("userInfoData:>", role);

  const { Authorization = "" } = Auth();
  if (Authorization) {
    // window.location = "/";
  }

  useEffect(() => {
    if (role) {
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      const check = localStorage.getItem("check");
      if(role === "user" && !celebrityId ){
        return navigate("/celebrity-selecting");
      }
      if(check){
        setIsLoading(true);
        dispatch(
          CreateOrder(`${check}`, (data, sk, err) => {
            if (err) {
              toast.error(err);
            } else {
              navigate(`/checkout?o=${data.data.idOrder}&s=${sk}`);
            }
            setIsLoading(false);
            localStorage.removeItem("check")
          })
        );
      }else{
        if (role === "user" || role === "celebrity") {
          navigate("/");
        }
        if (
          role === "vendor" &&
          (!userInfo.profile.businessInfo.businessRegistration ||
            !userInfo.profile.businessInfo.operationalHistory ||
            !userInfo.profile.businessInfo.sellingLicense ||
            !userInfo.profile.businessInfo.trademarkRegistration ||
            !userInfo.profile.businessInfo.trackRecord)
        ) {
          navigate("/verification-vendor");
        } else if (role === "vendor") {
          navigate("/vendor-dashboard");
        }
      }
    }
  }, [role]);

  const { loading, _, userInfo } = userLoginInfo;

  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <div className="body-login-1 sign_in-main">
        <div className="login-body"></div>
        <div className="login">
          <div className="login__container sign_in_container">
            <img
              className="login__container-logo-image sign-in-logo img-fluid"
              src={Logo}
              alt="logo fangway"
            />
            <p className="login__container-p-text">Sign in to FangWay</p>
            {/* <div className="login__container-sign-social-network">
              <div className="login__container-sign-social-network-unitari">
                <img
                  className="login__container-sign-social-network-unitari-image-network"
                  src={LogoApple}
                  alt="logo apple"
                />
              </div>
              <div className="login__container-sign-social-network-unitari">
                <img
                  className="login__container-sign-social-network-unitari-image-network"
                  src={LogoInsta}
                  alt="logo apple"
                />
              </div>
              <div className="login__container-sign-social-network-unitari">
                <img
                  className="login__container-sign-social-network-unitari-image-network"
                  src={LogoFace}
                  alt="logo apple"
                />
              </div>
              <div className="login__container-sign-social-network-unitari">
                <img
                  className="login__container-sign-social-network-unitari-image-network"
                  src={LogoGoogle}
                  alt="logo apple"
                />
              </div>
            </div>
            <p className="login__container-email-addres">
              Or Sign in using email address
            </p> */}
          </div>
          <div className="login__container-form">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Email is invalid")
                  .required("Email is required"),

                password: Yup.string()
                  .min(6, "Password must be at least 6 characters")
                  .required("Password is required"),
              })}
              onSubmit={async (fields) => {
                setIsLoading(true);
                dispatch(userLogin(fields.email, fields.password, fields.type));
                setIsLoading(false);
                if (userLoginInfo.userInfo.data.profile.role === "user") {
                  navigate("/customer-dashboard");
                }
              }}
              render={({ errors, touched }) => (
                <Form>
                  <div className="name-field">
                    <div className="form-group">
                      {/* <label htmlFor="email">Email</label> */}
                      <Field
                        name="email"
                        type="text"
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                        placeholder="Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="name-field">
                    <div className="form-group">
                      {/* <label htmlFor="password">Password</label> */}
                      <Field
                        name="password"
                        type="password"
                        className={
                          "form-control" +
                          (errors.password && touched.password
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      className="login__signInButton"
                      // type="submit"
                      // onClick={handleClick}
                      // disabled={!(isValid && dirty)}
                      // className={
                      //   !(isValid && dirty)
                      //     ? "main-button disable "
                      //     : "main-button"
                      // }
                      type="submit"
                      // onClick={handleClick}
                      // disabled={!(isValid && dirty)}
                    >
                      SIGN IN
                      {loading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginLeft: "12px" }}
                        ></span>
                      )}
                    </button>
                    {/*  */}
                    <p className="login__new-user-text">
                      News user?
                      <a href="#">
                        <span className="login__new-user-text-span">
                          <Link to="/signup" style={{ textDecoration: "none" }}>
                            Sign Up
                          </Link>
                        </span>
                      </a>
                    </p>
                    {/* <button type='reset' className='btn btn-secondary'>
                        Reset
                      </button> */}
                  </div>
                </Form>
              )}
            />
            <p className="login__forgot-text">
              <Link to="/forget-password" style={{ textDecoration: "none" }}>
                Forgot password?
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
