export const OrderListReducer = (state = {}, action) => {
  switch (action.type) {
    //--------- Get Order List product --------------//
    case "GET_ORDER_LIST_INIT":
      return { loading: true };
    case "GET_ORDER_LIST_SUCCESS":
      return { loading: false, orderList: action.payload };
    case "GET_ORDER_LIST_FAIL":
      return { loading: false, error: action.payload };

    //--------- MOre Detail of product --------------//
    case "GET_PRODUCT_HISTORY_INIT":
      return { loading: true };
    case "GET_PRODUCT_HISTORY_SUCCESS":
      return { loading: false, history: action.payload };
    case "GET_PRODUCT_HISTORY_FAIL":
      return { loading: false, error: action.payload };

    //--------- Get detail order --------------//
    case "GET_DETAIL_ORDER_INIT":
      return { loading: true };
    case "GET_DETAIL_ORDER_SUCCESS":
      return { loading: false, orderDetail: action.payload };
    case "GET_DETAIL_ORDER_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
