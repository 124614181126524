import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { CreateOrder, ProductDetailsCount } from '../../Actions/Products/ProductsAction';
import { useDispatch } from "react-redux";
import "./styleCard.css";

export default function CardProductSearch({ data, index }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loadingOrder, setLoadingOrder] = useState(false);

  const handleClick = (e, slug) => {
    navigate(`/product-detail?product=${slug}`);
  };

  const handleClickBuy = (slug) => {
    const userLocal = localStorage.getItem("userInfo");
    if (!userLocal) {
      localStorage.setItem("check","One");
      setLoadingOrder(true);
      dispatch(
        ProductDetailsCount(slug, (product) => {
          localStorage.setItem(
            "order",
            JSON.stringify([
              {
                product: product,
                amount: 1,
              },
            ])
          );
          setLoadingOrder(false);
          navigate("/signin");
        })
      );
    }
    setLoadingOrder(true);
    dispatch(
      ProductDetailsCount(slug, (product) => {
        localStorage.setItem(
          "order",
          JSON.stringify([
            {
              product: product,
              amount: 1,
            },
          ])
        );
        dispatch(
          CreateOrder("One", (data, sk, err) => {
            console.log({
              data,
              sk,
              err,
            });
            if (err) {
              toast.error(err);
            } else {
              navigate(`/checkout?o=${data.data.idOrder}&s=${sk}`);
            }
            setLoadingOrder(false);
          })
        );
      })
    );
  };

  return (
    <React.Fragment>
      {loadingOrder ? <div className="se-pre-con"></div> : ""}
      <div key={index} className="product-grid4 text-left">
        <div
          onClick={(e) => {
            handleClick(e, data.slug);
          }}
        >
          <div className="product-image-search">
            <img
              src={data.imagen}
              alt="hellow"
              className="img-fluid w-100"
            />
          </div>
          <div className="product-content pt-5">
            <h3 className="title">
              <a href="#">{data.name}</a>
            </h3>
            <ul className="rating">
              {Array(5)
                .fill()
                .map((_, i) => {
                  return (
                    <MdOutlineStarPurple500 key={i} className="payment__banner-success-content-rating" />
                  );
                })}
            </ul>

            {/* <div className="price">
              <span
                style={{
                  marginLeft: "-20px",
                  color: "rgb(64,59,107)",
                  fontSize: "25px",
                  textDecoration: "line-through",
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                ${data.amount}
              </span>
              <span
                style={{
                  color: "rgb(254,125,10)",
                  fontWeight: "bold",
                }}
              >
                ${data.discountPrice}
              </span>
            </div> */}

            <div className="section__product_info_product d-flex ml-4">
              <p
                style={{
                  marginLeft: "-20px",
                  color: "rgb(64,59,107)",
                  fontSize: "25px",
                  textDecoration: "line-through",
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
                className="h4"
              >
                ${parseFloat(data.amount).toFixed(2)}
              </p>
              <p
                style={{
                  color: "rgb(254,125,10)",
                  fontWeight: "bold",
                }}
                className="h4 ml-4"
              >
                ${" "}
                {data.discount
                  ? (
                      data.amount -
                      (data.amount / 100) *
                        data.discount
                    ).toFixed(2)
                  : (
                    data.amount -
                    (data.amount / 100) *
                      30
                  ).toFixed(2)
                  
                  }
              </p>
            </div>

            <div className="discount-off">
              <span>
                {" "}
                <img
                  src="images/fangwaysmall-logo.png"
                  alt="logo"
                  className="img-fluid"
                />
              </span>
              <div>
                <h4>{data.discount ? data.discount : "30"}% OFF</h4>
                <p>
                  with <strong>Fangway</strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ borderRadius: "50%!important" }}
          onClick={() => handleClickBuy(data.slug)}
          className="productdatabtn mb-3 ml-3 mt-3 float-left "
        >
          <a className="btn btn-warning">Buy Now</a>
        </div>
      </div>
    </React.Fragment>
  );
}
