import React from 'react';
import CartBanner from '../../components/CartBanner/CartBanner';
import ShoppingCart from '../../components/ShoppingCart/ShoppingCart';
import Footer from '../../components/Footer/Footer';
import SignupLink from '../../components/SignupLink/SignupLink';
import Header from '../../components/Header/Header';

export default function Cart() {
  return (<div className='cart-main'>
        <Header />
        <CartBanner text={"Shopping Cart"} />
        <ShoppingCart/>
        <SignupLink/>
        <Footer />
  </div>)
}
