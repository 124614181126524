import axios from "axios";
import { toast } from "react-toastify";

export const userLogin =
  (email, password, type, callback) => async (dispatch) => {
    // try {
    dispatch({
      type: "USER_LOGIN_REQUEST",
    });

    await axios
      .post(
        `https://fangway.com/users/auth/login
      `,
        { email, password, type }
      )
      .then((res) => {
        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: res.data.data,
        });
      })
      .catch((error) => {
        // console.log("error::>", error);
        toast.error(error.message);

        dispatch({
          type: "USER_LOGIN_FAIL",
          payload: error,
        });
      });
  };

export const userLogoutAction = (callback) => async (dispatch, getState) => {
  try {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("getAuth");

    // const { data } = await axios.get(`http://fangway.com/users/auth/login`);
    dispatch({
      type: "USER_LOGOUT",
      // payload: data,
    });
    toast.success("User Logout Successfully");
  } catch (error) {}
};
